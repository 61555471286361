import React from 'react';
import StockLocation from '../../components/StockLocation/StockLocation';
import { StockLocationContainer } from './StockLocation.style';
// import module

export const StockLocationContent: React.FC = (): JSX.Element => (
    <StockLocationContainer data-testid='StockLocationContent'>
        <StockLocation />
    </StockLocationContainer>
);
