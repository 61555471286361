import AppBar from '@mui/material/AppBar/AppBar';
import styled from 'styled-components';
import { ThemeType } from '../../styled/theme.type';

export const BoxItemsContent = styled.div``;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }: ThemeType) => theme.colors.topBar.background} !important;
`;

export const ContainerBtnBoxItems = styled.div`
    width: 90%;
    text-align: right;
    display: inline-block;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 65%;
    }
`;
