import React from 'react';
import { DatePickerFilterColumnProps } from './DatePickerFilterColumn.type';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Translator from '../../../../../states/global/Translator';
import { useRecoilValue } from 'recoil';
import { User } from '../../../../../states/global/User';
import { FullDataUser } from '../../../../User/User.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { TextField } from '@mui/material';
// import module

const DatePickerFilterColumn: React.FC<DatePickerFilterColumnProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const userState = useRecoilValue<FullDataUser | null>(User);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                disableFuture
                label={props.label}
                value={props.state.filters.filter((filter) => filter.id === props.filterName)[0]?.value.value || null}
                inputFormat={userState ? userState?.userSetting.dateFormat : 'YYYY-MM-DD'}
                mask={props.getDateMask ?? '____-__-__'}
                onChange={(newValue, extra) => {
                    const userDateFormat = (userState && userState?.userSetting.dateFormat) || 'YYYY-MM-DD';

                    const value = !newValue
                        ? undefined
                        : {
                              name: props.label,
                              type: 'date',
                              humanValue: extra ? extra : newValue?.format(userDateFormat),
                              value: extra
                                  ? moment(extra, userDateFormat).format('YYYY-MM-DD')
                                  : newValue.format('YYYY-MM-DD')
                          };

                    props.setFilter(props.filterName, value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        helperText={params.error ? Trans('messages.t.wrong_date_format') : ''}
                        size='small'
                        className='customStyleDatePickerFilter'
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default DatePickerFilterColumn;
