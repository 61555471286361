import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    TextField
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './Device.type';
import * as DeviceState from '../../states/component/Device';
import Translator from '../../states/global/Translator';
import UiTable from '../Ui/UiTable/UiTable';
// import module

export const DeviceContent: React.FC<type.DeviceViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='device'
                    data={useRecoilValue(DeviceState.Devices)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    extraFiltering={props.extraFiltering}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter, extraFiltering }) => {
                        props.fetchData(page + 1, limit, order, filter, extraFiltering);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'createdAt',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                />
            </Paper>

            <Dialog
                fullScreen={props.fullScreenModal}
                fullWidth={true}
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
            >
                <Formik
                    initialValues={props.valuesInitForm}
                    onSubmit={(values: type.ISignUpForm, actions) =>
                        props.handleSubmit(values, actions.resetForm, actions.setSubmitting)
                    }
                    validationSchema={props.formschema}
                >
                    {(propsF: FormikProps<type.ISignUpForm>) => {
                        const { values, touched, errors, handleBlur, handleChange, isSubmitting } = propsF;

                        return (
                            <Form>
                                <DialogTitle id='responsive-dialog-title' className='text-center'>
                                    {props.dataModal?.title}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <div className='containerFormik'>
                                            <Grid container justifyContent='space-around' direction='row'>
                                                <Grid item lg={6} md={6} sm={6} xs={12} className='textField'>
                                                    <TextField
                                                        name='serialNumber'
                                                        id='serialNumber'
                                                        label={Trans('messages.t.serial_number')}
                                                        value={values.serialNumber}
                                                        disabled
                                                        type='text'
                                                        helperText={
                                                            errors.serialNumber && touched.serialNumber
                                                                ? errors.serialNumber
                                                                : ''
                                                        }
                                                        error={
                                                            errors.serialNumber && touched.serialNumber ? true : false
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        variant='outlined'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus variant='contained' onClick={props.closeModal} size='small'>
                                        {Trans('messages.t.cancel')}
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size='small'
                                        autoFocus
                                        type='submit'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : Trans('messages.t.save')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};
