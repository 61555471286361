import { AxiosResponse } from 'axios';
import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { ModelTrentShipment, TrentShipmentResponse } from '../models/ApiTrentShipment.type';

export default class ApiTrentShipment extends PortalApi<ModelTrentShipment> {
    route = 'stock/sst-sensor-testing';

    getAll = async (page, limit, order, filter): Promise<TrentShipmentResponse> => {
        return await this.getTable<ModelTrentShipment>(
            '/shipments',
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    create = async <T, B, R = AxiosResponse<T>>(data: B, version = 'v1'): Promise<R> => {
        return await this.CREATE(`${version}/${this.route}/shipments`, data);
    };

    getWithPaginationDropDown = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<TrentShipmentResponse> => {
        return await this.getTable<ModelTrentShipment>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };
}
