import React, { useState } from 'react';
import * as style from './CustomerAddress.style';
import { CustomerAddressViewProps } from './CustomerAddress.type';
import { Button, CircularProgress } from '@mui/material';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
import { CustomerAddressFormik } from './CustomerAddress.formik';
import { Paper } from '@mui/material';
import UiTable from '../Ui/UiTable/UiTable';
import * as CustomerAddressState from '../../states/component/CustomerAddress';
// import module

export const CustomerAddressContent: React.FC<CustomerAddressViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <style.CustomerAddressContainer data-testid='CustomerAddressContent'>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    title='shipper'
                    data={useRecoilValue(CustomerAddressState.CustomerAddresses)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    onAdd={() => props.openModal}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter }) => {
                        props.fetchData(page + 1, limit, order, filter);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'id',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                />
            </Paper>

            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                title={props.dataModal?.title}
                maxWidthModal='md'
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <CustomerAddressFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                            customers={props.customers}
                            isValid={props.isValid}
                            setIsValid={props.setIsValid}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {Trans('messages.t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : Trans('messages.t.save')}
                        </Button>
                    </>
                }
            />
        </style.CustomerAddressContainer>
    );
};
