import React from 'react';
import * as view from './TrentShipment.view';
import * as type from './TrentShipment.type';
import Wrapper from '../../helpers/wrapper';
// import module

const TrentShipment: React.FC<type.TrentShipmentProps> = () => {
    return <view.TrentShipmentContent data-testid='TrentShipment-testid' />;
};

export default Wrapper(TrentShipment);
