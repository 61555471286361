import styled from 'styled-components';

export const LayoutContent = styled.div`
    display: flex;
`;

export const mainBodyApp = styled.div`
    margin-left: 220px;
    width: 100%;
    padding: 10px 15px;
    margin-top: 60px;

    @media (min-width: 1000px) and (max-width: 1450px) {
        margin-top: 60px;
        margin-left: 220px;
        width: 100%;
        padding: 10px 15px;
    }

    @media (min-width: 1500px) and (max-width: 3500px) {
        margin-top: 60px;
        margin-left: 220px;
        width: 100%;
        padding: 10px;
    }

    @media (min-width: 300px) and (max-width: 960px) {
        margin-top: 60px;
        margin-left: 0px;
        width: 100%;
        padding: 10px;
    }
`;
