import { Dialog, DialogContent, IconButton } from '@mui/material';
import React from 'react';
import * as style from './ImagePopup.style';
import * as type from './ImagePopup.type';
import { Close } from '@mui/icons-material';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
// import module

export const ImagePopupContent: React.FC<type.ImagePopupViewProps> = (props): JSX.Element => {
    return (
        <style.ImagePopupContent data-testid='ImagePopupContent' style={{ height: props.height || '30px' }}>
            <img className='thumbnail' src={props.thumbnailUrl} alt='Thumbnail' onClick={props.togglePopup} />

            <Dialog
                fullScreen={getFullScreenModal()}
                fullWidth={false}
                maxWidth='xl'
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
            >
                <style.AppBarContainer style={{ position: 'relative', textAlign: 'center' }}>
                    <style.UiToolbar>
                        <style.ContainerBtnBoxItems>
                            <IconButton
                                edge='end'
                                color='inherit'
                                onClick={() => {
                                    props.closeModal();
                                }}
                                aria-label='close'
                            >
                                <Close />
                            </IconButton>
                        </style.ContainerBtnBoxItems>
                    </style.UiToolbar>
                </style.AppBarContainer>

                <DialogContent dividers={true} style={{ padding: 0 }}>
                    <img className='popupImage' src={props.imageUrl} alt='Full size image' />
                </DialogContent>
            </Dialog>

            <style.ImagePopupBody />
        </style.ImagePopupContent>
    );
};
