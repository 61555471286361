import React from 'react';
import * as view from './ActionsButtonsDataTable.view';
import * as type from './ActionsButtonsDataTable.type';
import Wrapper from '../../helpers/wrapper';
// import module

const ActionsButtonsDataTable: React.FC<type.ActionsButtonsDataTableProps> = ({ actionsButtons }): JSX.Element => {
    return (
        <view.ActionsButtonsDataTableContent
            data-testid={'ActionsButtonsDataTable-testid'}
            actionsButtons={actionsButtons}
        />
    );
};

export default Wrapper(ActionsButtonsDataTable);
