import React from 'react';
import OrderHistoryInbound from '../../components/OrderHistoryInbound/OrderHistoryInbound';
import { OrderHistoryInboundContainer } from './OrderHistoryInbound.style';
// import module

export const OrderHistoryInboundContent: React.FC = (): JSX.Element => (
    <OrderHistoryInboundContainer data-testid='OrderHistoryInboundContent'>
        <OrderHistoryInbound />
    </OrderHistoryInboundContainer>
);
