import { Paper } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './ItemsReport.type';
import * as ItemsState from '../../states/component/Items';
import UiTable from '../../components/Ui/UiTable/UiTable';
// import module

export const ItemsReportContent: React.FC<type.ItemsReportsViewProps> = (props): JSX.Element => {
    return (
        <>
            <Paper className='cardPageContainer' elevation={1}>
                <UiTable
                    classesTable='containerDataTable'
                    data={useRecoilValue(ItemsState.Items)}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total}
                    extraFiltering={props.extraFiltering}
                    pageCount={props.pageCount}
                    fetchData={({ page, limit, order, filter, extraFiltering }) => {
                        props.fetchData(page + 1, limit, order, filter, extraFiltering);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'createdAt',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    filterOutsideToolbarElement={props.elementFilter}
                    title='itemsReport'
                    //globalFilter={true}
                />
            </Paper>
        </>
    );
};
