import styled from 'styled-components';
import { TextField } from '@mui/material';

export const ColumnFilterContent = styled.div``;

export const FilterTextField = styled(TextField)`
    &.MuiTextField-root {
        margin: 4px 0 8px 0;
        margin-left: 0px;
        && label {
            padding: 0 5px 0 0;
        }
        & .MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.9);
        }
        & svg {
            fill: #999999;
        }
    }
`;
