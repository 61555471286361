import React from 'react';
import { ItemWithSerialNumberContent } from './ItemWithSerialNumber.view';
import { ItemWithSerialNumberProps } from './ItemWithSerialNumber.type';
import Wrapper from '../../helpers/wrapper';
// import module

const ItemWithSerialNumber: React.FC<ItemWithSerialNumberProps> = () => {
    return <ItemWithSerialNumberContent data-testid='ItemWithSerialNumber-testid' />;
};

export default Wrapper(ItemWithSerialNumber);
