import {
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Paper,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './User.type';
import { SelectAsyncPaginate } from '../FormElements';
import Translator from '../../states/global/Translator';
import { Close } from '@mui/icons-material';
import * as style from './User.style';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import UiTable from '../Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import { IUAlert } from '../../styled/global.style';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
// import module

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<Record<string, unknown>, string>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const User: React.FC<type.UserStockLocationAccessViewProps> = (props: type.UserStockLocationAccessViewProps) => {
    const Trans = useRecoilValue(Translator);
    const [openInfo, setOpenInfo] = React.useState(true);

    return (
        <>
            <Paper className='cardPageContainer containerTextFieldFormikFilterHeader' elevation={1}>
                <Collapse in={openInfo}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='textFieldFormikAlert'>
                        <IUAlert
                            className='alertUserPageHeaderTable'
                            severity='info'
                            action={
                                <IconButton
                                    aria-label='close'
                                    color='inherit'
                                    size='small'
                                    onClick={() => {
                                        setOpenInfo(false);
                                    }}
                                >
                                    <Close fontSize='inherit' />
                                </IconButton>
                            }
                        >
                            {Trans('messages.p.assign_at_least_1_stock_location')}
                        </IUAlert>
                    </Grid>
                </Collapse>
                <UiTable
                    title='users'
                    data={props.listUsers}
                    loading={props.isLoading}
                    columns={props.columns}
                    total={props.total?.users}
                    pageCount={props.pageCount?.users}
                    extraFiltering={props.extraFiltering}
                    customButtonAdd={props.filtersOutSide}
                    fetchData={({ page, limit, order, filter, extraFiltering }) => {
                        props.fetchData(page + 1, limit, order, filter, extraFiltering);
                    }}
                    fetchDataExport={props.fetchDataExport}
                    defaultSortBy={{
                        column: 'stockRole.name',
                        desc: true
                    }}
                    hiddenColumns={[]}
                    forceRefresh={props.refresh}
                />
            </Paper>

            <Dialog
                fullScreen={getFullScreenModal()}
                fullWidth={true}
                maxWidth='xl'
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
                TransitionComponent={Transition}
                className='drawer_full_page'
            >
                <style.AppBarContainer style={{ position: 'relative', textAlign: 'center' }}>
                    <style.UiToolbar>
                        <style.TitlePage>{Trans('messages.t.stock_location_assignment')}</style.TitlePage>
                        <style.TitlePage>
                            {Trans('messages.t.user') + ' ID: '} {props.dataUser?.id}
                        </style.TitlePage>
                        <style.ContainerBtnBoxItems>
                            <Typography style={{ fontSize: '17' }} component='div'>
                                {Trans('messages.t.username')}:{' '}
                                {props.dataUser && (
                                    <>
                                        <span style={{ marginRight: '6px' }}>{props.dataUser?.firstname}</span>
                                        {props.dataUser?.lastname}
                                    </>
                                )}
                            </Typography>
                        </style.ContainerBtnBoxItems>
                        <style.ContainerBtnBoxItems>
                            <IconButton edge='end' color='inherit' onClick={props.closeModal} aria-label='close'>
                                <Close />
                            </IconButton>
                        </style.ContainerBtnBoxItems>
                    </style.UiToolbar>
                </style.AppBarContainer>

                <DialogContent style={{ padding: '10px 15px 0px 15px' }}>
                    <div>
                        <Grid container justify-content='flex-start' direction='row'>
                            {props.loadinModalStockLocations ? (
                                <Loading />
                            ) : (
                                <Formik
                                    initialValues={props.valuesInitForm}
                                    onSubmit={(values: type.IFormAssigned, actions) =>
                                        props.handleSubmit(values, actions.resetForm, actions.setSubmitting)
                                    }
                                    validationSchema={props.formschema}
                                >
                                    {(propsF: FormikProps<type.IFormAssigned>) => {
                                        const { values, errors, setFieldValue, isSubmitting, submitForm } = propsF;
                                        return (
                                            <fieldset style={{ borderRadius: '5px', width: '-webkit-fill-available' }}>
                                                <legend className='modalLegend'>
                                                    {Trans('messages.t.choose_stock_location')}
                                                </legend>
                                                <style.UiScrollbarsUser
                                                    autoHeight
                                                    autoHide
                                                    renderThumbVertical={({ style, ...props }) => (
                                                        <div
                                                            {...props}
                                                            className='scrollYcustom'
                                                            style={{ ...style }}
                                                        />
                                                    )}
                                                >
                                                    <Form style={{ display: 'contents' }} onSubmit={() => false}>
                                                        <SelectAsyncPaginate
                                                            name='stockRole'
                                                            label={Trans('messages.t.role')}
                                                            value={
                                                                props.dataModal?.isAdd
                                                                    ? values.stockRole[0]
                                                                    : values.stockRole
                                                            }
                                                            error={!!errors.stockRole}
                                                            onChange={(value) => {
                                                                setFieldValue('stockRole', value ? value : '');
                                                            }}
                                                            required={true}
                                                            loadOptions={props.loadMoreRoles}
                                                            textRequired={Trans('messages.p.this_field_is_required')}
                                                            lg={4}
                                                            md={4}
                                                            sm={10}
                                                        />

                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Grid
                                                                container
                                                                justify-content='flex-start'
                                                                direction='row'
                                                            >
                                                                <Grid
                                                                    container
                                                                    justify-content='flex-start'
                                                                    direction='row'
                                                                ></Grid>
                                                            </Grid>
                                                            <div className='bodyRightItems' style={{ display: 'flex' }}>
                                                                <Grid
                                                                    item
                                                                    lg={6}
                                                                    md={6}
                                                                    sm={12}
                                                                    xs={12}
                                                                    style={{ marginRight: '3px' }}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        justify-content='space-around'
                                                                        direction='row'
                                                                    ></Grid>
                                                                    <UiTable
                                                                        classesTable='containerDataTable'
                                                                        data={props.availableStockLocations}
                                                                        loading={props.isLoading}
                                                                        columns={props.columnsAvailableStockLocations}
                                                                        total={props.total?.availableStockLocations}
                                                                        pageCount={
                                                                            props.pageCount?.availableStockLocations
                                                                        }
                                                                        minHeight='310px'
                                                                        extraFiltering={props.extraFiltering}
                                                                        fetchData={({
                                                                            page,
                                                                            limit,
                                                                            order,
                                                                            filter,
                                                                            extraFiltering
                                                                        }) => {
                                                                            props.fetchDataStockLocationsAvailable(
                                                                                page + 1,
                                                                                limit,
                                                                                order,
                                                                                filter,
                                                                                extraFiltering
                                                                            );
                                                                        }}
                                                                        hiddenColumns={[]}
                                                                        // globalFilter={true}
                                                                        hideForceRefresh={true}
                                                                        customButtonAdd={
                                                                            <b>
                                                                                <span>
                                                                                    {Trans(
                                                                                        'messages.t.available_stock_location'
                                                                                    )}{' '}
                                                                                </span>
                                                                            </b>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    <UiTable
                                                                        classesTable='containerDataTable'
                                                                        data={props.assignedStockLocations}
                                                                        loading={false}
                                                                        hidePagination={true}
                                                                        hideForceRefresh={true}
                                                                        noPagination={true}
                                                                        columns={props.columnsAssignedStockLocations}
                                                                        hiddenColumns={[]}
                                                                        globalFilter={true}
                                                                        customButtonAdd={
                                                                            <b>
                                                                                <span>
                                                                                    {Trans(
                                                                                        'messages.t.assigned_stock_location'
                                                                                    )}{' '}
                                                                                </span>
                                                                            </b>
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </Form>
                                                </style.UiScrollbarsUser>
                                                <DialogActions>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        size='small'
                                                        onClick={() => {
                                                            props.closeModal();
                                                        }}
                                                    >
                                                        {Trans('messages.t.cancel')}{' '}
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                        color='primary'
                                                        type='button'
                                                        onClick={() => {
                                                            submitForm();
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress size={25} />
                                                        ) : (
                                                            Trans('messages.t.save')
                                                        )}
                                                    </Button>
                                                </DialogActions>
                                            </fieldset>
                                        );
                                    }}
                                </Formik>
                            )}
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
