import { Checkbox, FormControl, IconButton, MenuItem, Select } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';
import * as style from './ShipmentAcceptancePublic.style';
import { ApiItems as IApiItems, ItemModel, ShipmentAcceptancePublicProps } from './ShipmentAcceptancePublic.type';
import * as view from './ShipmentAcceptancePublic.view';
import ApiItems from '../../api/ApiItems';
import ApiShipmentAcceptance from '../../api/ApiShipmentAcceptance';
import BoxItems from '../../components/BoxItems/BoxItems';
import { Success } from '../../components/Popup/Popup';
import ImagePopup from '../../components/ImagePopup/ImagePopup';
import { ColumnsTable } from '../../types/common';
import { getCookie } from '../../helpers/cookie';
import Wrapper from '../../helpers/wrapper';
import Translator from '../../states/global/Translator';
import { toCamelCaseAndRemove_ } from '../../helpers/converter';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { ItemsType } from '../../components/Box/Box.type';
import { TitleItemWithIcon } from '../../styled/global.style';
// import logo from '../../assets/images/no-image.png';
import ApiSku from '../../api/ApiSku';
// import module

const api = new ApiShipmentAcceptance();
const apiItems = new ApiItems();
const apiSku = new ApiSku();

const ShipmentAcceptancePublic: React.FC<ShipmentAcceptancePublicProps> = (): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const classes = style.useStyles();
    const [itemsList, setItemsList] = useState<ItemModel[]>([] as ItemModel[]);
    const [shippingOrder, setshippingOrder] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [loadingPage, setLoadingPage] = useState<boolean>(true);
    const [showListItems, setShowListItems] = useState<boolean>(false);
    const [boxItemId, setBoxItemId] = useState<number>(0);
    const loggedIn = getCookie('authToken');
    const history = useHistory();

    const { id_order, hash }: any = useParams();

    const handleSubmitForm = async (): Promise<void> => {
        const items: any = {};
        items.items = itemsList.length
            ? itemsList.map((item) => {
                  if (item.comment) {
                      return { id: item.id, quantity: item.quantityTem, comment: item.comment };
                  }
                  return { id: item.id, quantity: item.quantityTem };
              })
            : [];

        try {
            setLoadingButton(true);

            const shipmentAcceptance: any = await api.createGeneral(
                `stock/public/shipping-order/${id_order}/acceptance/${hash}`,
                items
            );

            if (!shipmentAcceptance) {
                throw shipmentAcceptance;
            }

            setLoadingButton(false);
            Success({ text: Trans('messages.t.the_order_has_been_accepted') + ` [ID ${shippingOrder.id}]` });

            setTimeout(() => {
                if (loggedIn) {
                    history.push('/app/history-order/inbound');
                } else {
                    window.location.reload();
                }
            }, 300);
        } catch (error) {
            setLoadingButton(false);
            console.warn('Error to sending data ', error);
        }
    };

    const updateDataItems = (type, value, row): void => {
        let newList: ItemModel[] = itemsList;

        newList = newList.map((item) => {
            if (item.id === row.id) {
                item.disabled = type === ItemsType.GENERIC_ITEM ? row.quantity === value : !item.disabled;
                item.quantityTem = type === ItemsType.GENERIC_ITEM ? value : value ? 1 : 0;

                if (type === ItemsType.GENERIC_ITEM) {
                    if (row.quantity === value) item.comment = '';
                } else {
                    if (row.quantityTem === 1) item.comment = '';
                }
            }
            return item;
        });
        setItemsList(newList);
    };

    const getItemsBox = async (id): Promise<void> => {
        const {
            data: { item }
        }: IApiItems = await apiItems.getGeneral(`stock/public/item/${id}/${hash}`);

        if (!item) {
            throw item;
        }
        setBoxItemId(id);
        setShowListItems(!showListItems);
    };

    const forOption = (num): JSX.Element[] => {
        const elem: JSX.Element[] = [];
        for (let i = 0; i <= num; i++) {
            elem.push(<MenuItem value={i}>{i}</MenuItem>);
        }
        return elem;
    };

    const elementSRowEdit = (row): JSX.Element => {
        if (row.item.sku.skuType.type === ItemsType.GENERIC_ITEM) {
            return (
                <FormControl className={classes.formControl} style={{ margin: 0 }}>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        size='small'
                        value={row.quantityTem}
                        onChange={(e) => updateDataItems(row.item.sku.skuType.type, e.target.value, row)}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                        {forOption(row.quantity)}
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <Checkbox
                    id={`check_${row.id}`}
                    color='primary'
                    inputProps={{ 'aria-label': 'primary' }}
                    checked={row.quantityTem >= 1 ? true : false}
                    onChange={(e) => {
                        updateDataItems(row.item_type, e.target.checked, row);
                    }}
                />
            );
        }
    };

    const ElementSRowEditMemo = React.memo((props: any) => elementSRowEdit(props.row));
    ElementSRowEditMemo.displayName = 'ElementSRowEditMemo';

    const getOrder = useCallback(async () => {
        setLoadingPage(true);
        let newData: ItemModel[] = [];
        const loggedIn = getCookie('authToken');

        try {
            const {
                data: { shippingOrder }
            }: any = await api.getGeneral(`stock/public/shipping-order/${id_order}/${hash}`);

            if (!Object.keys(shippingOrder).length) return 'error';

            if (
                shippingOrder.shippingOrderStatus.status === 'Delivered' ||
                shippingOrder.shippingOrderStatus.id === 6
            ) {
                if (loggedIn) history.push('/app/current-order/inbound');
                else history.push('/shipment-acceptance/done');
            }
            setLoading(false);

            !!Object.keys(shippingOrder).length && setshippingOrder(shippingOrder);

            newData = shippingOrder.shippingOrderItem
                ? shippingOrder.shippingOrderItem.map((item) => {
                      item.quantityTem = item.quantity;
                      item.comment = '';
                      item.disabled = true;
                      return item;
                  })
                : newData;

            !!shippingOrder.shippingOrderItem.length && setItemsList(newData);
            setLoadingPage(false);
        } catch (error) {
            setLoadingPage(false);
            console.warn(error);
            if (loggedIn) history.push('/app/current-order/inbound');
            else history.push('/login');
        }
    }, []);

    const setValueFrom = (row: ItemModel): ItemModel[] => {
        const quantityinput: any = document.getElementById('comment_' + row.id);

        itemsList.map((item) => {
            if (item.id === row.id) {
                item.comment = quantityinput.value;
            }
            return item;
        });
        quantityinput.focus();
        return itemsList;
    };

    const currentValueFrom = (row: ItemModel, disabled = false): string | boolean | undefined => {
        const valueItem: ItemModel | undefined =
            itemsList && !!itemsList.length ? itemsList.find((item) => item.id === row.id) : undefined;

        if (disabled && valueItem) {
            return valueItem?.disabled;
        }
        return valueItem ? valueItem && valueItem?.comment : '';
    };

    const elementEdit = (row) => {
        let defaultValue = '';

        if (typeof currentValueFrom(row) == 'string') {
            defaultValue = currentValueFrom(row)?.toString() ?? '';
        }

        return (
            <textarea
                style={{ marginLeft: '1px', width: '100%' }}
                title={Trans('messages.p.wrong_quantity_of_item')}
                key={`comment_${row.id}`}
                id={`comment_${row.id}`}
                defaultValue={defaultValue}
                onChange={() => {
                    setValueFrom(row);
                }}
            />
        );
    };

    const formatItem = (row): string => {
        switch (row.item.sku.skuType.type.toLowerCase()) {
            case ItemsType.GENERIC_ITEM:
                return row.item.sku?.name ? row.item.sku.name : '---';
            case ItemsType.BOX:
                return row.item.sku?.customerLabel ? row.item.sku.customerLabel : 'Box';
            case ItemsType.SENSOR:
                return row.item.sku?.name ? row.item.sku.name : 'Sensor';
            case ItemsType.TABLET:
                return row.item.sku?.name ? row.item.sku.name : 'Tablet';
            case ItemsType.HUB:
                return row.item.sku?.customerLabel ? row.item.sku.customerLabel : 'Hub';
            default:
                return row.item.sku.skuType.type;
        }
    };

    const ElementEditMemo = React.memo((props: any) => elementEdit(props.row));
    ElementEditMemo.displayName = 'ElementEditMemo';

    const columns: ColumnsTable[] = [
        // {
        //     Header: 'ID',
        //     accessor: 'id',
        //     disableGroupBy: true,
        //     aggregate: 'count',
        //     canFilters: true,
        //     width: 50
        // },
        {
            Header: Trans('messages.t.image'),
            accessor: 'sku.image',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            exportType: 'image',
            Export: ({ row }: CellProps) =>
                row.original?.item?.sku?.itemImageS3Key ? apiSku.getImageUrl(row.original.item.sku) : '',
            Cell: ({ row: { original } }: CellProps) =>
                original?.item?.sku.skuType.type !== ItemsType.BOX ? (
                    <ImagePopup
                        imageUrl={apiSku.getImageUrl(original?.item?.sku)}
                        thumbnailUrl={apiSku.getImageUrl(original?.item?.sku)}
                        height='20px'
                    />
                ) : (
                    ''
                ),
            minWidth: 85,
            maxWidth: 90
        },
        {
            Header: Trans('messages.t.item'),
            accessor: 'item.sku.name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 360,
            Cell: ({ row: { original } }: CellProps) =>
                original.item.sku.skuType.type === ItemsType.BOX ? (
                    <TitleItemWithIcon>
                        {toCamelCaseAndRemove_(formatItem(original))}
                        <IconButton
                            size='small'
                            className='info'
                            style={{ minWidth: 'auto' }}
                            title={Trans('messages.t.items')}
                            id={`showItems${original.item.id}`}
                            onClick={() => getItemsBox(original.item.id)}
                        >
                            <Search />
                        </IconButton>
                    </TitleItemWithIcon>
                ) : (
                    toCamelCaseAndRemove_(formatItem(original))
                )
        },
        {
            Header: Trans('messages.t.type'),
            accessor: 'item.sku.skuType',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps) => toCamelCaseAndRemove_(original.item.sku.skuType.type),
            Export: ({ row: { original } }: CellProps) => toCamelCaseAndRemove_(original.item.sku.skuType.type)
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'item.serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) =>
                row.original.item.sku.skuType.type !== ItemsType.BOX
                    ? row.original.item?.serialNumber
                    : row.original.item?.label
                    ? row.original.item.label
                    : '---'
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 70
        },
        {
            Header: Trans('messages.t.received'),
            accessor: 'received',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 70,
            Cell: ({ row }: CellProps) => <ElementSRowEditMemo row={row.original} />
        },
        {
            Header: Trans('messages.t.comment'),
            accessor: 'comment',
            disableGroupBy: true,
            width: 350,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => <ElementEditMemo row={row.original} />
        }
    ];

    useEffect(() => {
        !itemsList.length && getOrder();
    }, [getOrder, itemsList.length]);

    return (
        <>
            <view.ShipmentAcceptancePublicContent
                data-testid={'ShipmentAcceptancePublic-testid'}
                fullScreenModal={getFullScreenModal()}
                columns={columns}
                handleSubmit={() => false}
                handleAcceptance={handleSubmitForm}
                formschema={{}}
                itemsLists={itemsList}
                shippingOrderId={id_order}
                shippingOrder={shippingOrder}
                open={true}
                closeModal={() => true}
                isLoading={loading}
                loadingPage={loadingPage}
                loadingButton={loadingButton}
            />

            <BoxItems
                BoxId={boxItemId}
                modalItems={showListItems}
                setModalItems={setShowListItems}
                setBoxId={setBoxItemId}
                typeModal='drawer'
                showAction={true}
                hash={hash}
                public={true}
            />
        </>
    );
};

export default Wrapper(ShipmentAcceptancePublic);
