import { atom } from 'recoil';
import { IStockLocation, IStockLocationRegion } from '../../models/ApiStockLocation.type';

export const StockLocation = atom<IStockLocation | null>({
    key: 'StockLocation-atom',
    default: null
});

export const StockLocationRegion = atom<IStockLocationRegion | null>({
    key: 'StockLocationRegion-atom',
    default: null
});
