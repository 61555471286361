import React from 'react';
import { toast } from 'react-toastify';
import ErrorPopup from './Error';
import * as type from './Popup.type';
import * as view from './Popup.view';

export const Popup = (content: JSX.Element, props?: never): void => {
    const settings = Object.assign(
        {
            position: 'top-right',
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        },
        props
    );
    toast(content, settings);
};

export const Error = (props: type.ErrorProps): void => {
    Popup(<ErrorPopup {...props} />);
};

export const Success = (props: type.SuccessProps): void => {
    Popup(<view.SuccessContent {...props} />);
};

export const Errors = (props: type.ErrorsProps): void => {
    Popup(<view.ErrorContent {...props} />);
};

export const Warning = (props: type.WarningProps): void => {
    Popup(<view.WarningContent {...props} />);
};
