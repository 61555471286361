import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeType } from '../../styled/theme.type';
import styled from 'styled-components';

export const MainMenuContent = styled(Drawer)`
    .MuiPaper-root {
        width: auto;
        background: ${({ theme }: ThemeType) => theme.colors.body};
    }
    .MuiList-padding {
        padding-top: 0px !important;
    }
    .active_link > .MuiButtonBase-root {
        background: ${({ theme }: ThemeType) => theme.colors.colorActive};
        color: ${({ theme }: ThemeType) => theme.colors.button.background} !important;
        border-right: 2px solid ${({ theme }: ThemeType) => theme.colors.button.background};

        svg {
            color: ${({ theme }: ThemeType) => theme.colors.button.background};
        }
    }
    .iconSubMenuSidebar {
        min-width: 25px !important;
        svg {
            font-size: 0.9rem;
        }
    }

    .iconMenuSidebar {
        svg {
            font-size: 19px;
        }
    }

    .MuiListItemIcon-root {
        min-width: 40px;
    }
`;

export const useStyles = makeStyles({
    list: {
        width: 250,
        maxWidth: '244px'
    },
    fullList: {
        width: 'auto'
    }
});
