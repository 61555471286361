import React from 'react';
import GenericItems from '../../components/GenericItems/GenericItems';
import { GenericItemsContainer } from './GenericItems.style';
// import module

export const GenericItemsContent: React.FC = (): JSX.Element => (
    <GenericItemsContainer data-testid='GenericItemsContent'>
        <GenericItems />
    </GenericItemsContainer>
);
