import styled, { createGlobalStyle } from 'styled-components';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { FontSize } from '../Ui/UiTheme';

export const ImagePopupContent = styled.div``;

export const ImagePopupBody = createGlobalStyle`
  .customModalBody {
    width: 90%;
    max-width: 100%;
  }
  .contentModeal-main{
    display: flex;
    padding: 15px 0px;
  }
  .contentModeal-left, .contentModeal-right{
    padding: 0px 10px;
  }

  .contentModeal-left{
    width: 20%;
  }
  .modalLegend{
    width: auto;
    margin: 20px 0 10px;
    font-size: ${FontSize};
  }

  .contentModeal-right{
    width: 80%;
  }
  .sub_content_contentModeal-right{
    display: flex;
  }

  .sub_content_list_right, .sub_content_list_left{
    padding: 0px 10px;
  }

  .sub_content_list_right{
    width: 50%;
  }
  .sub_content_list_left{
    width: 50%;
  }

  .customModalBodyCont .modal-body{
    padding: 0px;
  }

  .valueShippingorderDetails{
    font-size: ${FontSize};
    color: #7d7d7d;
    border-bottom: thin solid #7d7d7d;
    display: block;
  }
   
  #div_form_new_status {
    opacity: 0;
    height: 0px;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    overflow: hidden;
  }

  #div_form_new_status.showForm{
    opacity:1;
    height: auto;
  }
  .containerDTable{
    min-height: 350px;
  }

  @media (max-width: 768px) {
    .customModalBody {
      width: 100%;
    }
  }

  @media (min-width: 768px){
    .customModalBodyCont .modal-dialog {
        width: 100%;
        margin: 0px auto;
    }

  }

  .thumbnail{
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    &:hover: {
        opacity: 0.8,
    };
  }

  .popupImage{
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
`;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }) => theme.colors.topBar.background} !important;
`;

export const ContainerBtnBoxItems = styled.div`
    width: 100%;
    text-align: right;
    display: inline-block;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 65%;
    }
`;

export const TitlePage = styled(Typography)`
    width: 500px;
`;

export const UiToolbar = styled(Toolbar)`
    min-height: 40px !important;
`;
