import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { Model } from '../models/ApiShippingOrders.type';

export default class ApiShippingOrders extends PortalApi<Model> {
    route = 'stock/shipping-order';

    getAll = async (route: string, page: any, limit: any, order: any, filter: any): Promise<any> => {
        return await this.getGeneralTable<any>(
            route,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getUsedLocations = async (
        fromLocationId: string,
        toLocationId: string,
        customerId: string
    ): Promise<{ response: Blob; fileName: string }> => {
        const params: { [key: string]: string | number } = {
            fromLocationId,
            toLocationId,
            customerId
        };

        return await this.getGeneral('stock/shipping-order/used-locations', params);
    };

    getXlsx = async (route: string): Promise<{ response: Blob; fileName: string }> => {
        const params: { [key: string]: string | number } = {};
        params.responseType = 'blob';
        return await this.getDowloadFile(`/${route}`, params);
    };
}
