import React from 'react';
import { RecoilRoot } from 'recoil';
import MainApp from '../MainApp/App';
import './App.css';
import './P-loading.css';

const App: React.FC = () => (
    <RecoilRoot>
        <MainApp />
    </RecoilRoot>
);

export default App;
