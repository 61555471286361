import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { ModelRole, StockRoleResponseAllData } from '../models/ApiRoles.type';

export default class ApiRoles extends PortalApi<ModelRole> {
    route = 'stock/role';

    getAll = async (page, limit, order, filter): Promise<StockRoleResponseAllData> => {
        return await this.getTable<ModelRole>('', '/v1', formatParametersToGetPagination(page, limit, order, filter));
    };
}
