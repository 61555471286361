import styled, { createGlobalStyle } from 'styled-components';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { FontSize } from '../../Ui/UiTheme';
import Scrollbars from 'react-custom-scrollbars-2';

export const ShippingOrderDetailsContent = styled.div``;

export const ShippingOrderDetailsbody = createGlobalStyle`
  .customModalBody {
    width: 90%;
    max-width: 100%;
  }
  .contentModeal-main{
    display: flex;
    padding: 15px 0px;
  }
  .contentModeal-left, .contentModeal-right{
    padding: 0px 10px;
  }

  .contentModeal-left{
    width: 20%;
  }
  .modalLegend{
    width: auto;
    margin: 20px 0 10px;
    font-size: ${FontSize};
  }

  .contentModeal-right{
    width: 80%;
  }
  .sub_content_contentModeal-right{
    display: flex;
  }

  .sub_content_list_right, .sub_content_list_left{
    padding: 0px 10px;
  }

  .sub_content_list_right{
    width: 50%;
  }
  .sub_content_list_left{
    width: 50%;
  }

  .customModalBodyCont .modal-body{
    padding: 0px;
  }

  .containerValueShippingOrder {
    width: 100%;
    overflow: hidden;

    @media only screen and (max-width: 780px) {
      max-width: auto;
    }
    
    @media only screen and (min-width: 781px) and (max-width: 1028px) {
      max-width: 135px !important;
    }

    @media only screen and (min-width: 1029px) and (max-width: 1240px) {
      max-width: 135px !important;
    }
    
    @media only screen and (min-width: 1241px) and (max-width: 2530px) {
      max-width: 195px !important;
    }
    
    @media only screen and (min-width: 2530px) {
      max-width: 235px !important;
    }
  }

  .valueShippingorderDetails{
    font-size: ${FontSize};
    color: #7d7d7d;
    border-bottom: thin solid #7d7d7d;
    border-top: none;
    border-left: none;
    border-right: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
  }

  .textClickToCopy {
    cursor: pointer;
    &:hover {
      background-color: rgb(163 170 177 / 17%);
    }
  }
   
  #div_form_new_status {
    opacity: 0;
    height: 0px;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    overflow: hidden;
  }

  #div_form_new_status.showForm{
    opacity:1;
    height: auto;
  }
  .containerDTable{
    min-height: 350px;
  }

  .clickToCopyContainer{
    display: flex;
  }

  @media (max-width: 768px) {
    .customModalBody {
      width: 100%;
    }
  }

  @media (min-width: 768px){
    .customModalBodyCont .modal-dialog {
        width: 100%;
        margin: 0px auto;
    }

  }
`;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }) => theme.colors.topBar.background} !important;
`;

export const ContainerBtnBoxItems = styled.div`
    width: 90%;
    text-align: right;
    display: inline-block;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 65%;
    }
`;

export const TitlePage = styled(Typography)`
    width: 500px;
`;

export const FieldsetContainer = styled.fieldset`
    min-height: 530px;
    height: auto;
    border-radius: 5px;

    @media (max-width: 1915px) {
        min-height: 530px;
    }
`;

export const FieldsetLeftContainer = styled.fieldset`
    min-height: 530px;
    height: auto;
    border-radius: 5px;
    padding-left: 0px;
`;

export const UiToolbar = styled(Toolbar)`
    min-height: 40px !important;
`;

export const UiScrollbarsDetail = styled(Scrollbars)`
    height: auto !important;
    border-bottom: thin solid #c0c0c0;
    max-height: calc(100vh - 60px) !important;
    min-height: 300px !important;

    & > div:first-child {
        min-height: 300px !important;
        max-height: calc(100vh - 60px) !important;
    }
`;
