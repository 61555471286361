import React from 'react';
import { ImportOrderContent } from './ImportOrder.view';
import { ImportOrderProps } from './ImportOrder.type';
import Wrapper from '../../helpers/wrapper';
// import module

const ImportOrder: React.FC<ImportOrderProps> = () => {
    return <ImportOrderContent data-testid='ImportOrder-testid' />;
};

export default Wrapper(ImportOrder);
