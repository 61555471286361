import React from 'react';
import CreateOrder from '../../components/CreateOrder/CreateOrder';
import { CreateOrderContainer } from './CreateOrder.style';
// import module

export const CreateOrderContent: React.FC = (): JSX.Element => (
    <CreateOrderContainer data-testid='CreateOrderContent'>
        <CreateOrder />
    </CreateOrderContainer>
);
