import React from 'react';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import { ResetPasswordContainer } from './ResetPassword.style';
// import module

export const ResetPasswordContent: React.FC = (): JSX.Element => (
    <ResetPasswordContainer data-testid='ResetPasswordContent'>
        <ResetPassword />
    </ResetPasswordContainer>
);
