import styled from 'styled-components';

export const ReportsContent = styled.div``;

export const QuicksightReportsContentEl = styled.div`
    width: 100%;
    height: calc(100vh - 155px);
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const ReportEl = styled.div`
    width: 100%;
    height: 100%;
`;

export const InitMsg = styled.p`
    position: absolute;
    left: 50%;
    top: 50%;
`;
