import styled from 'styled-components';
import { StyledComponent } from './Profile.type';

export const ProfileContent = styled.div``;
export const ContainerImage = styled.div`
    width: 120px;
    height: 120px;
    border: thin solid #c0c0c0;
    margin: auto;
    border-radius: 50%;

    .MuiIconButton-root {
        padding: 2px;
    }
`;

export const ContainerBtnImage = styled.div`
    position: absolute;
    margin-top: 85px;
    margin-left: 86px;
    background: #2d863d;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    padding: 3px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
`;

export const GridDiv = styled.div<StyledComponent>`
    float: ${(props) => props.float};
    text-align: ${(props) => props.textAlign && props.textAlign};
`;
