import React from 'react';
import { OrderHistoryInboundContent } from './OrderHistoryInbound.view';
import { OrderHistoryInboundProps } from './OrderHistoryInbound.type';
import Wrapper from '../../helpers/wrapper';
// import module

const OrderHistoryInbound: React.FC<OrderHistoryInboundProps> = () => {
    return <OrderHistoryInboundContent data-testid='OrderHistoryInbound-testid' />;
};

export default Wrapper(OrderHistoryInbound);
