import React from 'react';
import OrderList from '../../components/OrderList/OrderList';
import { OrderListContainer } from './OrderList.style';
// import module

export const OrderListContent: React.FC = (): JSX.Element => (
    <OrderListContainer data-testid='OrderListContent'>
        <OrderList />
    </OrderListContainer>
);
