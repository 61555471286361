import {
    AccessTime,
    Article,
    Assignment,
    BrandingWatermark,
    Brightness1,
    CloudUpload,
    ListAlt,
    LocalShipping,
    Security,
    Send,
    Widgets,
    Work,
    PieChart
    // AddToHomeScreen,
} from '@mui/icons-material';
import React from 'react';
import { IMenu } from '../components/MainMenu/MainMenu.type';
import { FullDataUser } from '../components/User/User.type';

export const Menu = (rol: Array<string>, dataUser: FullDataUser | null) => {
    const menus: IMenu[] = [
        {
            key: 1,
            subMenu: false,
            open: false,
            path: '/app/import-order',
            title: 't.import_order',
            icon: <CloudUpload />,
            allowedroles: ['access_trent_sensor_import']
        },
        {
            key: 2,
            subMenu: false,
            open: false,
            path: '/app/create-order',
            title: 't.create_order',
            icon: <Assignment />,
            allowedroles: ['access_create_order_page']
        },
        {
            key: 3,
            subMenu: false,
            open: false,
            path: '/app/order/list',
            title: 't.orders',
            icon: <Brightness1 />,
            allowedroles: ['ID_5']
        },
        {
            key: 4,
            subMenu: true,
            open: false,
            title: 't.current_orders',
            path: '',
            icon: <Send />,
            _children: [
                {
                    title: 't.inbound',
                    path: '/app/current-order/inbound',
                    icon: <Brightness1 />
                },
                {
                    title: 't.outbound',
                    path: '/app/current-order/outbound',
                    icon: <Brightness1 />
                }
            ]
        },
        {
            key: 5,
            subMenu: true,
            open: false,
            title: 't.orders_history',
            path: '',
            icon: <AccessTime />,
            _children: [
                {
                    title: 't.inbound',
                    path: '/app/history-order/inbound',
                    icon: <Brightness1 />
                },
                {
                    title: 't.outbound',
                    path: '/app/history-order/outbound',
                    icon: <Brightness1 />
                }
            ]
        },
        {
            key: 6,
            subMenu: false,
            open: false,
            path: '/app/stock-location',
            title: 't.stock_location',
            icon: <BrandingWatermark />,
            allowedroles: ['access_stock_location_page']
        },
        // {
        //     key: 7,
        //     subMenu: false,
        //     open: false,
        //     path: '/app/location-group',
        //     title: 't.location_groups',
        //     icon: <AddToHomeScreen />,
        //     allowedroles: ['access_stock_location_page']
        // },
        {
            key: 8,
            subMenu: true,
            open: false,
            title: 't.items',
            path: '',
            icon: <ListAlt />,
            allowedroles: ['access_items'],
            _children: [
                {
                    title: 't.generic_items',
                    path: '/app/items/generic-items',
                    icon: <Brightness1 />,
                    allowedroles: ['access_create_stock_page']
                },
                {
                    title: 't.hubs',
                    path: '/app/items/hubs',
                    icon: <Brightness1 />,
                    allowedroles: ['access_hubs_page']
                },
                {
                    title: 't.sensors',
                    path: '/app/items/sensors',
                    icon: <Brightness1 />,
                    allowedroles: ['access_sensors_page']
                },
                {
                    title: 't.items_with_serial_no',
                    path: '/app/items/with-serial-number',
                    icon: <Brightness1 />,
                    allowedroles: ['access_items']
                },
                {
                    title: 't.tablets',
                    path: '/app/items/tablets',
                    icon: <Brightness1 />,
                    allowedroles: ['access_tablets_page']
                }
            ]
        },
        {
            key: 9,
            subMenu: false,
            open: false,
            path: '/app/boxes',
            title: 't.boxes',
            icon: <Work />,
            allowedroles: ['access_box_page']
        },
        {
            key: 10,
            subMenu: false,
            open: false,
            path: '/app/sku',
            title: 't.sku',
            icon: <Widgets />,
            allowedroles: ['access_sku_page']
        },
        {
            key: 11,
            subMenu: false,
            open: false,
            path: '/app/shipper',
            title: 't.shipper',
            icon: <LocalShipping />,
            allowedroles: ['access_shipper_page']
        },
        {
            key: 12,
            subMenu: true,
            open: false,
            title: 't.reports',
            allowedroles: ['access_reports_items'], // <access_reports> permission must be created
            path: '',
            icon: <Article />,
            _children: [
                {
                    title: 't.items',
                    path: '/app/reports/items',
                    icon: <Brightness1 />,
                    allowedroles: ['access_reports_items']
                },
                {
                    path: '/app/quicksight-reports',
                    title: 't.qs_reports',
                    icon: <PieChart />,
                    allowedroles: ['access_quicksight_reports']
                }
            ]
        },
        {
            key: 13,
            subMenu: true,
            open: false,
            title: 't.settings',
            path: '',
            icon: <Security />,
            allowedroles: ['access_security_page'],
            _children: [
                {
                    title: 't.roles',
                    path: '/app/settings/roles',
                    icon: <Brightness1 />,
                    allowedroles: ['access_roles_page']
                },
                {
                    title: 't.users',
                    path: '/app/settings/users',
                    icon: <Brightness1 />,
                    allowedroles: ['access_users_page']
                },
                {
                    title: 't.customer_addresses',
                    path: '/app/settings/customer-addresses',
                    icon: <Brightness1 />,
                    allowedroles: ['create_and_edit_customer_address']
                }
            ]
        },
        // {
        //     key: 12,
        //     subMenu: false,
        //     open: false,
        //     path: '/app/trent-shipments',
        //     title: 't.trent_shipments',
        //     icon: <LocalShipping />,
        //     allowedroles: ['access_trent_sensor_import']
        // }
    ];
    let menusNew: IMenu[] = [];
    if (rol) {
        menusNew = menus.filter((menu) => {
            if (menu.allowedroles) {
                if (menu.subMenu && !!menu._children?.length) {
                    menu._children = menu._children.filter(
                        (Smenu) =>
                            Smenu.allowedroles &&
                            Smenu.allowedroles.find(
                                (r) => rol.find((el) => el === r) || 'ID_' + dataUser?.stockRole?.id == r
                            )
                    );
                }

                return menu.allowedroles.find(
                    (role) => rol.find((el) => el === role) || 'ID_' + dataUser?.stockRole?.id == role
                );
            }
            return [false];
        });
    } else {
        menusNew = [];
    }

    return menusNew;
};
