import React from 'react';
import * as style from './ActionsButtonsDataTable.style';
import * as type from './ActionsButtonsDataTable.type';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { MoreVert } from '@material-ui/icons';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
// import module

export const ActionsButtonsDataTableContent: React.FC<type.ActionsButtonsDataTableViewProps> = ({
    actionsButtons
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <style.ActionsButtonsDataTableContent data-testid='ActionsButtonsDataTableContent'>
            <PopupState variant='popover' popupId='demo-popup-popover'>
                {(popupState) => (
                    <div>
                        <Tooltip title={Trans('messages.t.options')} aria-label={Trans('messages.t.options')}>
                            <IconButton
                                size='small'
                                style={{ minWidth: 'auto' }}
                                color='primary'
                                id='optionsActions'
                                {...bindTrigger(popupState)}
                            >
                                <MoreVert />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            className='containerDataTableActionsBtn'
                        >
                            <Box p={2}>{actionsButtons}</Box>
                        </Popover>
                    </div>
                )}
            </PopupState>
            <style.GlobalStyleAcTionsButtons />
        </style.ActionsButtonsDataTableContent>
    );
};
