import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import * as type from './Login.type';
import * as view from './Login.view';
import ApiLogin from '../../api/ApiLogin';
import Account from '../../api/Account';
import ApiRecoverPassword from '../../api/ApiRecoverPassword';
import { Errors, Success } from '../../components/Popup';
import { getCookie, setCookie } from '../../helpers/cookie';
import { SendMailModel } from '../../models/ApiRecoverPassword.type';
import Translator, { Lang } from '../../states/global/Translator';
import * as UserState from '../../states/global/User';
import { useTheme } from '../../styled/useTheme';
import { FullDataUser } from '../../components/User/User.type';

const api = new ApiLogin();
const apiAccount = new Account();
const apiRecoverPassword = new ApiRecoverPassword();

const Login: React.FC<type.LoginProps | RouteComponentProps> = () => {
    const Trans = useRecoilValue(Translator);
    const setLangSelect = useSetRecoilState<any>(Lang);
    const setUser = useSetRecoilState(UserState.User);
    const [viewForgotPassword, setViewForgotPassword] = useState<boolean>(false);
    const setPermissions = useSetRecoilState(UserState.Permissions);
    const [valuesInitFormChangePassword, setvaluesInitFormChangePassword] = useState({ email: '' });
    const [loading, setLoading] = useState<boolean>(true);
    const { themes, setMode } = useTheme();

    const valuesInitForm: object = {
        username: '',
        password: '',
        stay_signed_in: true
    };

    const Formschema = Yup.object().shape({
        username: Yup.string().required(Trans('messages.p.this_field_is_required')),
        password: Yup.string().required(Trans('messages.p.this_field_is_required'))
    });

    const FormChangePasswordschema = Yup.object().shape({
        email: Yup.string().required(Trans('messages.p.this_field_is_required'))
    });

    const changePassLoad = () => {
        setViewForgotPassword(!viewForgotPassword);
        setLoading(true);
        setvaluesInitFormChangePassword({ email: '' });

        setTimeout(() => {
            setLoading(false);
        }, 200);
    };

    const handleSubmit = async (e: any, setSubmitting) => {
        try {
            const d: object = { username: e.username, password: e.password };
            const { data } = await api.create(d, '');
            setCookie('authToken', data.token, { path: '/' });

            const user: FullDataUser = await apiAccount.getAccountParsed();

            if (user) {
                setUser(user);
                window.localStorage.setItem('userId', JSON.stringify(user.id));
                setPermissions(user.userPermissions);
                if (!user.userLocationAccess.length) {
                    return (window.location.href = '/unauthorized');
                }
            }
            setCookie(
                'language',
                user.userSetting.language && user.userSetting.language !== 'null' ? user.userSetting.language : 'en_GB',
                { path: '/' }
            );
            user.userSetting.theme && setMode(themes.data[user.userSetting.theme.toLowerCase()]);

            setSubmitting(false);
            if (user.userSetting.theme && user.userSetting.theme.toLowerCase() === 'light') window.location.href = '/';
        } catch (error) {
            Errors({ text: Trans('messages.p.bad_credentials') });
            setSubmitting(false);
        }
    };

    const handleChangePassword = async (e: { email: string }, setSubmitting, resetForm) => {
        try {
            const d: object = { email: e.email };
            const data: SendMailModel = await apiRecoverPassword.createGeneral('stock/public/recover-password', d);

            setSubmitting(false);

            if (!data.data || !data.data.user) {
                Errors({ text: Trans('messages.p.email_does_not_exist') });
                throw !data.data;
            }

            Success({ text: Trans('messages.p.instructions_to_recover_password') });
            resetForm({});
            changePassLoad();
        } catch (error) {
            console.warn('Error to sending data ', error);
        }
    };

    useEffect(() => {
        getCookie('language') && setLangSelect(getCookie('language'));
    }, [setLangSelect]);

    return (
        <view.LoginContent
            data-testid={'Login-testid'}
            handleSubmit={handleSubmit}
            handleChangePassword={handleChangePassword}
            formschema={Formschema}
            valuesInitForm={valuesInitForm}
            valuesInitFormChangePassword={valuesInitFormChangePassword}
            FormChangePasswordschema={FormChangePasswordschema}
            changePassLoad={changePassLoad}
            viewForgotPassword={viewForgotPassword}
            loading={loading}
        />
    );
};

export default Login;
