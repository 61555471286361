import React from 'react';
import { CustomerAddressContent } from './CustomerAddress.view';
import { CustomerAddressProps } from './CustomerAddress.type';
import Wrapper from '../../helpers/wrapper';
// import module

const CustomerAddress: React.FC<CustomerAddressProps> = () => {
    return <CustomerAddressContent data-testid='CustomerAddress-testid' />;
};

export default Wrapper(CustomerAddress);
