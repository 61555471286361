import React from 'react';
import * as view from './Reports.view';
import * as type from './Reports.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Reports: React.FC<type.ReportsProps> = (): JSX.Element => {
    return <view.ReportsContent data-testid='Reports-testid' />;
};

export default Wrapper(Reports);
