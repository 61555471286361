import React from 'react';
import * as view from './ItemsReport.view';
import * as type from './ItemsReport.type';
import Wrapper from '../../helpers/wrapper';
// import module

const ItemsReport: React.FC<type.ItemsReportProps> = () => {
    return <view.ItemsReportContent data-testid={'ItemsReport-testid'} />;
};

export default Wrapper(ItemsReport);
