import React from 'react';
import { CurrentOrdersInboundContent } from './CurrentOrdersInbound.view';
import { CurrentOrdersInboundProps } from './CurrentOrdersInbound.type';
import Wrapper from '../../helpers/wrapper';
// import module

const CurrentOrdersInbound: React.FC<CurrentOrdersInboundProps> = () => {
    return <CurrentOrdersInboundContent data-testid='CurrentOrdersInbound-testid' />;
};

export default Wrapper(CurrentOrdersInbound);
