import React from 'react';
import { SensorContent } from './Sensor.view';
import { SensorProps } from './Sensor.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Sensor: React.FC<SensorProps> = () => {
    return <SensorContent data-testid='Sensor-testid' />;
};

export default Wrapper(Sensor);
