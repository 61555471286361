import React, { useState } from 'react';
import ApiSku from '../../api/ApiSku';
import { OrderAlphabetically } from '../../helpers/converter';
import { ModelSku, SkuResponse } from '../../models/ApiSku.type';
import { SelectAsyncPaginate } from '../FormElements';
import { LoadMoreSku } from '../Tablet/Tablet.type';
import { SkusDropDownProps } from './SkusDropDown.type';
// import module

const SkusDropDown: React.FC<SkusDropDownProps> = (props): JSX.Element => {
    const apiSku = new ApiSku();
    const [skus, setSkus] = useState<ModelSku[]>([]);

    const getSkus = async (search, _, { page }): Promise<LoadMoreSku> => {
        const { items }: SkuResponse = await apiSku.getWithPaginationDropDown(
            page,
            10,
            '',
            '',
            search,
            props.paramsUrl,
            props.url + '?order=name:asc'
        );

        if (items && !!items.length) {
            setSkus(OrderAlphabetically<ModelSku>(items, 'name'));
        }

        return {
            options: items,
            hasMore: items.length >= 1,
            additional: { page: page + 1 }
        };
    };

    return (
        <SelectAsyncPaginate
            name={props.name}
            label={props.label}
            value={props.value}
            error={props.error}
            options={skus}
            onChange={props.onChange}
            required={props.required}
            loadOptions={getSkus}
            textRequired={props.textRequired}
            lg={props.lg ?? 6}
            md={props.md ?? 6}
            sm={props.sm ?? 6}
            xs={props.xs ?? 12}
        />
    );
};

export default SkusDropDown;
