import { Collapse, IconButton } from '@mui/material';
import { ExpandMore, Error, ReportProblem, CheckCircle, PriorityHigh } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import * as type from './Popup.type';
import Translator from '../../states/global/Translator';
import { useRecoilValue } from 'recoil';

const PopupContent = styled.div`
    padding: 10px;
    border-left: 5px solid rgba(0, 0, 0, 0.2);
    height: 100%;
`;

const PopupError = styled(PopupContent)`
    background: #b92e3b;
    color: #fff;
`;

const PopupSuccess = styled(PopupContent)`
    background: #659265;
    color: #fff;
`;

const PopupWarning = styled(PopupContent)`
    background: #c79121;
    color: #fff;
`;

// const PopupLevel1 = styled(PopupContent)`
//     background: #ECEFB3;
//     color: #675F23;
// `;

// const PopupLevel2 = styled(PopupContent)`
//     background: #efe1b3;
//     color: #785C2B;
// `;

// const PopupLevel3 = styled(PopupContent)`
//     background: #c26565;
//     color: #fff;
// `;

const ErrorExclamation = styled.i`
    font-size: 1.8em;
    margin-right: 8px;
    display: block;
    float: left;
`;

const TitleNotify = styled.span`
    display: block;
    padding: 5px 0px;
`;
const ErrorCollapse = styled(Collapse)`
    padding-top: 10px;
    border-top: solid thin rgba(255, 255, 255, 0.4);
`;

const ErrorButton = styled(IconButton)<{ expanded: string }>`
    position: absolute !important;
    left: 12px;
    top: 36px;
    color: #fff !important;
    padding: 8px !important;

    transform: rotate(0deg) !important;
    transition: all 0.3s ease-out !important;
    transform: ${(props) => (props.expanded === 'true' ? 'rotate(180deg) !important' : '')};
`;

export const ErrContent: React.FC<type.ErrorContent & type.ErrorProps> = (props): JSX.Element => (
    <PopupError>
        <p>
            <div style={{ paddingLeft: '25px' }}>
                <ErrorExclamation>
                    <ReportProblem />
                </ErrorExclamation>
                Sorry something went wrong. If problem persist please contact the support team or report a bug in the
                upper right corner.
            </div>
        </p>
        <div>
            <ErrorButton
                expanded={props.expanded.toString()}
                onClick={props.updateExpand}
                aria-expanded={props.expanded}
            >
                <ExpandMore />
            </ErrorButton>
        </div>
        <ErrorCollapse in={props.expanded} timeout='auto' unmountOnExit>
            <table>
                <tbody>
                    <tr>
                        <td>Error: </td>
                        <td>
                            {props.status} {props.message}
                        </td>
                    </tr>
                    <tr>
                        <td>URL: </td>
                        <td>{props.url}</td>
                    </tr>
                </tbody>
            </table>
        </ErrorCollapse>
    </PopupError>
);

export const SuccessContent: React.FC<type.SuccessProps> = (props): JSX.Element => (
    <PopupSuccess>
        <p>
            <div style={{ display: 'flex' }}>
                <ErrorExclamation>
                    <CheckCircle />
                </ErrorExclamation>
                {props.text}
            </div>
        </p>
    </PopupSuccess>
);

export const ErrorContent: React.FC<type.ErrorsProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <PopupError>
            <p>
                {props.multipleMessage ? (
                    <>
                        <div style={{ display: 'flex' }}>
                            <ErrorExclamation>
                                <Error />
                            </ErrorExclamation>
                            <TitleNotify>
                                <b>{Trans('messages.t.the_following_errors_were_found')}</b>:{' '}
                            </TitleNotify>
                        </div>
                        {typeof props.text !== 'string' &&
                            props.text.map((title) => (
                                <>
                                    {title.property}

                                    <ul>
                                        {title.messages.map((message, i) => (
                                            <li key={i}>{message}</li>
                                        ))}
                                    </ul>
                                </>
                            ))}
                    </>
                ) : (
                    <div style={{ display: 'flex' }}>
                        <ErrorExclamation>
                            <Error />
                        </ErrorExclamation>
                        <TitleNotify> {props.text} </TitleNotify>
                    </div>
                )}
            </p>
        </PopupError>
    );
};

export const WarningContent: React.FC<type.WarningProps> = (props): JSX.Element => (
    <PopupWarning>
        <p>
            <div style={{ display: 'flex' }}>
                <ErrorExclamation>
                    <PriorityHigh />
                </ErrorExclamation>
                <TitleNotify>{props.text}</TitleNotify>
            </div>
        </p>
    </PopupWarning>
);
