import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import * as type from '../models/ApiUser.type';

export default class ApiUsers extends PortalApi<type.Model> {
    route = 'users';

    getAll = async (route, page, limit, order, filter): Promise<any> => {
        return await this.getGeneralTable<any>(
            route,
            '/v2',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };
}
