import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as themesAll from './themes.json';
import * as Settings from '../states/global/Settings';
import { getFromLocalStorage, setToLocalStorage } from '../helpers/localStorage';

export const useTheme = () => {
    const themes = themesAll.default;
    const [theme, setTheme] = useRecoilState(Settings.Theme);
    const [themeLoaded, setThemeLoaded] = useState(false);
    const setMyThme = useSetRecoilState(Settings.Theme);

    const setMode = (mode) => {
        setToLocalStorage('theme', mode);
        setTheme(mode);
        setMyThme(mode);
    };

    useEffect(() => {
        const localTheme = getFromLocalStorage('theme');
        localTheme ? setTheme(localTheme) : setTheme(themes.data.light);
        setThemeLoaded(true);
    }, []);

    return { theme, themeLoaded, setMode, themes };
};
