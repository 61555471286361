import Grid from '@mui/material/Grid';
import { Button, CircularProgress, Paper } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import * as style from './ImportOrder.style';
import * as type from './ImportOrder.type';
import Translator from '../../states/global/Translator';
import { Loading } from '../../helpers/Loading';
// import module

export const ImportOrderContent: React.FC<type.ImportOrderViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <style.ImportOrderContent data-testid='ImportOrderContent'>
            <Paper className='cardPageContainer' elevation={1} style={{ minHeight: '460px' }}>
                <Grid container justifyContent='space-around' direction='row'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h4>{Trans('messages.t.import_order')}</h4>

                        {props.statusImport === 1 && (
                            <Formik
                                initialValues={props.valuesInitForm}
                                onSubmit={(values: type.IImportOrderForm, actions) =>
                                    props.handleSubmit(values, actions.resetForm, actions.setSubmitting)
                                }
                                validationSchema={Yup.object().shape({
                                    order: Yup.array()
                                })}
                            >
                                {(propsF: FormikProps<type.IImportOrderForm>) => {
                                    const { setFieldValue, isSubmitting } = propsF;

                                    return (
                                        <Form>
                                            <p>
                                                {Trans(
                                                    'messages.p.to_import_an_order_please_upload_the_generated_json_file'
                                                )}
                                            </p>

                                            <DropzoneArea
                                                dropzoneClass='textColor'
                                                acceptedFiles={['application/JSON']}
                                                dropzoneText={Trans('messages.p.drag_and_drop_file')}
                                                onChange={(files) => {
                                                    setFieldValue('order', files);
                                                }}
                                                filesLimit={1}
                                                clearOnUnmount={true}
                                            />

                                            <p>{Trans('messages.p.please_upload_only_shipped_orders')}</p>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <div style={{ float: 'right', padding: '10px 0px' }}>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        size='small'
                                                        type='submit'
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress size={25} />
                                                        ) : (
                                                            Trans('messages.t.upload')
                                                        )}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}

                        {props.statusImport === 2 && (
                            <style.ContainerMessages>
                                <style.BodyMessages>
                                    <h4>{Trans('messages.p.we_are_processing_your_order')}</h4>
                                    <Loading />
                                </style.BodyMessages>
                            </style.ContainerMessages>
                        )}

                        {props.statusImport === 3 && (
                            <>
                                <style.ContainerMessages>
                                    <style.BodyMessages>
                                        <h4>{Trans('messages.p.your_order_have_been_successfully_created')}</h4>
                                        <style.ButtonCustom
                                            variant='contained'
                                            color='primary'
                                            type='button'
                                            onClick={() => props.setOpenModalShippingDetails(true)}
                                        >
                                            {Trans('messages.t.see_order_details')}
                                        </style.ButtonCustom>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            type='button'
                                            onClick={() => props.setOpenModalShippingEdit(true)}
                                        >
                                            {Trans('messages.t.edit_order')}
                                        </Button>
                                    </style.BodyMessages>
                                </style.ContainerMessages>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ float: 'right', padding: '10px 0px' }}>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            color='primary'
                                            type='button'
                                            onClick={() => props.uploadNewFile()}
                                        >
                                            {Trans('messages.t.upload_new_file')}
                                        </Button>
                                    </div>
                                </Grid>
                            </>
                        )}

                        {props.statusImport === 4 && (
                            <style.ContainerMessages>
                                <style.BodyMessages>
                                    <p>{Trans('messages.p.we_are_sorry_but_we_coudn_not_process_your_order')}</p>
                                    <p>
                                        <strong>{Trans('messages.p.the_reason')}:</strong> {props.notImportedReason}
                                    </p>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='button'
                                        onClick={() => props.setStatusImport(props.importStatus.SelectJSON)}
                                    >
                                        {Trans('messages.t.try_again')}
                                    </Button>
                                </style.BodyMessages>
                            </style.ContainerMessages>
                        )}

                        {props.statusImport === 5 && (
                            <style.ContainerMessages>
                                <style.BodyMessages>
                                    <p>{Trans('messages.p.the_shipping_order_already_exists')}</p>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='button'
                                        onClick={() => props.setStatusImport(props.importStatus.SelectJSON)}
                                    >
                                        {Trans('messages.t.try_again')}
                                    </Button>
                                </style.BodyMessages>
                            </style.ContainerMessages>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </style.ImportOrderContent>
    );
};
