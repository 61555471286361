/* eslint-disable max-len */
import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { ModelSkuPrice, SkuPriceResponse } from '../models/ApiSkuPrice.type';

export default class ApiSkuPrices extends PortalApi<ModelSkuPrice> {
    route = 'stock/sku-price';

    getAll = async (route, page, limit, order, filter, version = '/v1'): Promise<SkuPriceResponse> => {
        return await this.getTable<ModelSkuPrice>(
            route,
            version,
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };
}
