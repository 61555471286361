import PortalApi from '../helpers/api/portalApi';
import { CustomerWithPagnator, ModelCustomer, ModelCustomerItrack } from '../models/ApiCustomer.type';
import { formatParametersToGetPagination } from '../helpers/converter';

export default class ApiCustomer extends PortalApi<ModelCustomer> {
    route = 'stock/customer';

    getWithPagination = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<CustomerWithPagnator> => {
        return await this.getTable<ModelCustomerItrack>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };

    getCustomersCodeBook = async (params: any = {}): Promise<ModelCustomerItrack[]> => {
        return this.getCodeBook('/codebook', 'v1', params);
    };
}
