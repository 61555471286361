import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import * as type from './Device.type';
import * as view from './Device.view';
import ApiItems from '../../api/ApiItems';
import ApiDevice from '../../api/ApiDevice';
import ApiSku from '../../api/ApiSku';
import { Model } from '../../models/ApiDevice.type';
import { ColumnsTable, ModalType } from '../../types/common';
import { Devices } from '../../states/component/Device';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import Translator from '../../states/global/Translator';
import { Success } from '../Popup/Popup';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { Typography } from '@mui/material';
import { ListAlt } from '@mui/icons-material';
import { TextEllipsis } from '../../styled/global.style';
import { IStockLocation, IStockLocationRegion } from '../../models/ApiStockLocation.type';
import ShippingOrderDetails from '../ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import { DevicePassProps } from './Device.type';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { ItemsModel } from '../../models/ApiItems.type';
import { BoxData } from '../BoxItems/BoxItems.type';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
import ImagePopup from '../ImagePopup/ImagePopup';
// import module

const api = new ApiDevice();
const apiItems = new ApiItems();
const apiSku = new ApiSku();

const Device: React.FC<DevicePassProps> = (): JSX.Element => {
    const setdata = useSetRecoilState<ItemsModel[]>(Devices);
    const Trans = useRecoilValue(Translator);
    const getDateMask = useRecoilValue(GetDateMask);
    const [stockLocation, setStockLocation] = useState<IStockLocation | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const stockLocationState = useRecoilValue<IStockLocation | null>(StockLocation);
    const stockLocationRegionState = useRecoilValue<IStockLocationRegion | null>(StockLocationRegion);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [locationFilter, setLocationFilter] = React.useState<{ locationFilter: string }>({ locationFilter: '' });
    const [valuesInitForm, setvaluesInitForm] = useState({
        serialNumber: '',
        stockLocation: ''
    });
    const [dataModal, setdataModal] = useState<ModalType>({
        isAdd: true,
        title: Trans('messages.t.add_new') + ' ' + Trans('messages.t.device'),
        id: 0
    });
    const [openOrderDetails, setOpenOrderDetails] = useState<boolean>(false);
    const [shippingOrderId, setshippingOrderId] = useState<number | string | null>(null);

    const Formschema = Yup.object().shape({
        serialNumber: Yup.string()
            .min(3, 'Serial Number is Too Short.')
            .required(Trans('messages.p.this_field_is_required')),
        stockLocation: Yup.string().required(Trans('messages.p.this_field_is_required'))
    });

    const openModal = (): void => setOpen(true);

    async function getData(page, limit, order, filter, extraFiltering) {
        let filterByLocation = '';

        if (extraFiltering.locationFilter) {
            filterByLocation = `&${extraFiltering.locationFilter}`;
        }

        return await apiItems.getAll(
            `?type=hub&withSerialNumber=0&extend[useData]=1${filterByLocation}`,
            page,
            limit,
            order,
            filter,
            '/v2'
        );
    }

    const getAllDevice = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setdata([]);

            const { items, paginator } = await getData(page, limit, order, filter, extraFiltering);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 100, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const handleClose = (): void => {
        setvaluesInitForm({
            serialNumber: '',
            stockLocation: ''
        });
        setStockLocation(null);
        setdataModal({
            isAdd: true,
            title: Trans('messages.t.add_new') + ' ' + Trans('messages.t.device'),
            id: 0
        });
        setOpen(false);
        setOpenOrderDetails(false);
    };

    const handleSubmit = async (
        e: type.ISignUpForm,
        resetForm: (e) => void,
        setSubmitting: (e) => void
    ): Promise<void> => {
        try {
            delete e.serialNumber;
            const device: Model = dataModal.isAdd
                ? await api.create(e)
                : await api.patch(typeof dataModal.id === 'number' ? dataModal.id : 0, e);

            if (!device) {
                throw device;
            }

            setRefresh(true);
            resetForm({});
            Success({
                text: Trans(
                    dataModal.isAdd ? 'messages.t.hub_created_successfully' : 'messages.t.hub_successfully_updated'
                )
            });
            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.error('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const detailsShippingOrder = (shippingOrderId): void => {
        setshippingOrderId(shippingOrderId);
        setOpenOrderDetails(true);
    };

    const ActionsButtons = (original: BoxData): JSX.Element => {
        return (
            <>
                {original.shippedAt && original.shippingOrderId ? (
                    <Typography
                        className='dataTableActionsBtn'
                        onClick={() => detailsShippingOrder(original.shippingOrderId)}
                    >
                        <ListAlt />
                        <span>{Trans('messages.t.view_order')}</span>
                    </Typography>
                ) : (
                    ''
                )}
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: Trans('messages.t.image'),
            accessor: 'sku.image',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            exportType: 'image',
            Export: ({ row }: CellProps) =>
                row.original.sku?.itemImageS3Key ? apiSku.getImageUrl(row.original.sku) : '',
            Cell: ({ row }: CellProps) => (
                <ImagePopup
                    imageUrl={apiSku.getImageUrl(row.original.sku)}
                    thumbnailUrl={apiSku.getImageUrl(row.original.sku)}
                />
            ),
            minWidth: 85,
            maxWidth: 90
        },
        {
            Header: Trans('messages.t.device_id'),
            accessor: 'itrackDeviceId',
            disableGroupBy: true,
            disableSortBy: true,
            aggregate: 'count',
            canFilters: false,
            minWidth: 70,
            maxWidth: 110,
            Export: ({ row }: CellProps) => row.original.itrackDeviceId || row.original.itemId,
            Cell: ({ row }: CellProps) => row.original.itrackDeviceId || row.original.itemId
        },
        {
            Header: Trans('messages.t.sku'),
            accessor: 'skuName',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Export: ({ row }: CellProps) => (row.original.sku.name ? row.original.sku.name : ''),
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.sku.name}>
                    {row.original.sku ? row.original.sku.name : '---'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.revision'),
            accessor: 'version',
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            width: 70,
            Cell: ({ row }: CellProps) => row.original.version || '---'
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true
        },
        {
            Header: Trans('messages.t.stock_location'),
            accessor: 'locationName',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Export: ({ row }: CellProps) =>
                row.original.deviceInfo?.location ? row.original.deviceInfo.location.name : '',
            Cell: ({ row }: CellProps) =>
                row.original.deviceInfo?.location ? row.original.deviceInfo.location.name : '---'
        },
        {
            Header: Trans('messages.t.shipped_at'),
            accessor: 'shippedAt',
            disableGroupBy: true,
            aggregate: 'count',
            disableSortBy: false,
            canFilters: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.shippedAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_from')}
                            filterName='shippedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_to')}
                            filterName='shippedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false),
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_from')}
                            filterName='createdAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.created_at_to')}
                            filterName='createdAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: Trans('messages.t.invoice_number'),
            accessor: 'saleOrderId',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            disableSortBy: true,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.saleOrderId}>
                    {row.original.saleOrderId || '---'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.shipping_price_in_usd'),
            accessor: 'shippingOrderItemPriceUsd',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row: { original } }: CellProps) => original.shippingOrderItemPriceUsd ?? '---'
        },
        {
            Header: Trans('messages.t.use_customer'),
            accessor: 'useCustomer.name',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original?.useCustomer?.name}>
                    {row.original?.useCustomer?.name || '---'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.use_last_transmittion'),
            accessor: 'useLastTransmittion',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps<string>) => fromUTCToTimezone(row.original.useLastTransmittion || '', false),
            Export: ({ row }: CellProps<string>) => fromUTCToTimezone(row.original.useLastTransmittion || '', false)
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            Cell: ({ row: { original } }: CellProps) =>
                original.shippedAt ? <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} /> : ''
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'shippedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'shippedAtTo'
        }
    ];

    useEffect(() => {
        if (stockLocationState && !!Object.keys(stockLocationState).length) {
            setLocationFilter({ locationFilter: `locationId=${stockLocationState.id}` });
        } else if (stockLocationRegionState && !!Object.keys(stockLocationRegionState).length) {
            setLocationFilter({ locationFilter: `regionId=${stockLocationRegionState.id}` });
        } else {
            setLocationFilter({ locationFilter: '' });
        }
    }, [stockLocationState, stockLocationRegionState]);

    return (
        <>
            <view.DeviceContent
                data-testid={'Device-testid'}
                openModal={openModal}
                open={open}
                closeModal={handleClose}
                formschema={Formschema}
                handleSubmit={handleSubmit}
                columns={columns}
                valuesInitForm={valuesInitForm}
                dataModal={dataModal}
                fullScreenModal={getFullScreenModal()}
                stock_location={stockLocation}
                isLoading={loading}
                fetchData={getAllDevice}
                fetchDataExport={getForExport}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                extraFiltering={locationFilter}
            />
            {openOrderDetails && (
                <ShippingOrderDetails
                    open={openOrderDetails}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}
        </>
    );
};

export default Device;
