import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { Loading } from '../Loading';

const Wrapper =
    <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    (props: any) => {
        // eslint-disable-line
        return (
            <ErrorBoundary>
                <React.Suspense
                    fallback={
                        <div style={{ textAlign: 'center', width: '100%', height: '100vh' }}>
                            <Loading style={{ display: 'contents' }} />
                        </div>
                    }
                >
                    <WrappedComponent {...props} />
                </React.Suspense>
            </ErrorBoundary>
        );
    };

export default Wrapper;
