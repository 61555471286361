import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { Delete, Edit, ListAlt } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ISignUpForm, ShipperProps } from './Shipper.type';
import { ShipperContent } from './Shipper.view';
import { ColumnsTable, LocationModel, ModalType, ModelCodeBook } from '../../types/common';
import ApiShipper from '../../api/ApiShipper';
import ApiStockLocation from '../../api/ApiStockLocation';
import ApiShipperType from '../../api/ApiShipperType';
import { Success } from '../Popup/Popup';
import * as ShipperState from '../../states/component/Shipper';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ContainerSelectFilter, TextEllipsis } from '../../styled/global.style';
import { IStockLocation, LocationWithPagnator } from '../../models/ApiStockLocation.type';
import { ModelShipper, ShipperResponse } from '../../models/ApiShipper.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { OrderAlphabetically, removeSpaces } from '../../helpers/converter';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
// import module

const api = new ApiShipper();
const apiStockLocation = new ApiStockLocation();
const apiShipperType = new ApiShipperType();

const Shipper: React.FC<ShipperProps> = () => {
    const setdata = useSetRecoilState<ModelShipper[]>(ShipperState.Shippers);
    const Trans = useRecoilValue(Translator);
    const [open, setOpen] = useState<boolean>(false);
    const [dataStockLocations, setdataStockLocation] = useState<IStockLocation[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [shipperType, setShipperType] = useState<ModelCodeBook[]>([]);
    const [shipper, setShipper] = useState<ModelShipper>();
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [valuesInitForm, setvaluesInitForm] = useState<ISignUpForm>({
        name: '',
        shipperType: '',
        trackingUrl: '',
        shipperLocation: []
    });

    const [dataModal, setdataModal] = useState<ModalType>({ isAdd: true, title: '', id: null });
    const [shipperId, setShipperId] = useState<number | null>(null);

    const getAllShipper = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator }: ShipperResponse = await api.getAll(page, limit, order, filter);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator }: ShipperResponse = await api.getAll(i, 200, order, filter);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const getSelect = async () => {
        const shipperType: ModelCodeBook[] = await apiShipperType.getShipperTypeCodeBook();
        setShipperType(shipperType);
    };

    const loadMoreStockLocation = async (search, _, { page }) => {
        const { items }: LocationWithPagnator = await apiStockLocation.getWithPagination(
            page,
            10,
            '',
            '',
            search,
            [{ id: 'type', value: 'active' }],
            '?order=name:asc'
        );

        if (items && !!items.length) {
            setdataStockLocation(OrderAlphabetically<IStockLocation>(items, 'name'));
        }

        return {
            options: items,
            hasMore: items.length >= 1,
            additional: { page: page + 1 }
        };
    };

    const openModal = (ActionType = 'create', id: number | null = null): void => {
        setdataModal({
            isAdd: ActionType !== 'edit' ? true : false,
            title:
                ActionType !== 'edit'
                    ? Trans('messages.t.add_new_shipper')
                    : Trans('messages.t.shipper_update') + ' - #' + id,
            id: ActionType !== 'edit' ? null : id
        });
        setTimeout(() => setOpen(true), 100);
        getSelect();
    };

    const handleClose = (): void => {
        setvaluesInitForm({
            name: '',
            shipperType: '',
            trackingUrl: '',
            shipperLocation: []
        });
        setOpen(false);
    };

    const handleSubmit = async (e: ISignUpForm, resetForm: (e: Record<string, unknown>) => void, setSubmitting) => {
        if (e.shipperLocation.length)
            e.shipperLocation = e.shipperLocation.map((location) => ({ location: location.id }));

        e.name = removeSpaces(e.name);
        e.trackingUrl = removeSpaces(e.trackingUrl);

        try {
            const { data }: ModelShipper = dataModal.isAdd
                ? await api.create(e)
                : await api.patch(dataModal.id ?? 0, e);

            if (!data) {
                throw data;
            }
            resetForm({});
            Success({
                text: dataModal.isAdd
                    ? `${Trans('messages.t.shipper_created_successfully')} - ${data?.shipper && data?.shipper.name}`
                    : `${Trans('messages.t.shipper_successfully_updated')} - ${data?.shipper && data?.shipper.name}`
            });

            setRefresh(true);
            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const editShipper = async (row: ModelShipper) => {
        openModal('edit', row.id);

        const { shipper }: ModelShipper = await api.getById(row.id);

        if (!shipper) {
            throw shipper;
        }
        let stockShipperStockLocationEdit: LocationModel[] = [];
        if (shipper && !!shipper.shipperLocation.length) {
            stockShipperStockLocationEdit = shipper.shipperLocation.map((item) => ({
                id: item.location.id,
                name: item.location.name
            }));
        }

        setShipperId(shipper.id);
        setShipper(shipper);

        setvaluesInitForm({
            name: shipper.name,
            shipperType: shipper.shipperType.id,
            trackingUrl: shipper.trackingUrl,
            shipperLocation: stockShipperStockLocationEdit
        });
    };

    const confirmDeleteRow = (row) =>
        popUpConfirm(
            Trans('messages.t.shipper') + `: ${row.name}`,
            Trans('messages.p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );

    const deleteRow = async (onClose, row: ModelShipper): Promise<void> => {
        try {
            const shipper: ModelShipper = await api.remove(row.id);

            if (!shipper) {
                throw shipper;
            }
            onClose();
            Success({ text: `${Trans('messages.t.shipper_successfully_removed')} - ${row.name}` });
            setRefresh(true);
        } catch (error) {
            console.warn('Error to send data ', error);
        }
    };

    const typeSelectFilter = (column, setFilter): JSX.Element => {
        return (
            <ContainerSelectFilter>
                <Autocomplete
                    getOptionLabel={(option) => option.name}
                    id='skuTypeFilter'
                    onChange={(_, value) => {
                        const values = !value
                            ? undefined
                            : {
                                  name: column.Header,
                                  humanValue: value.name,
                                  value: value.name
                              };
                        setFilter(column.id, values);
                    }}
                    defaultValue={shipperType.find((type) => `${type.name}` === `${column.filterValue?.value}` ?? '')}
                    options={shipperType}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={column.Header}
                            value={column.filterValue?.value || null}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
            </ContainerSelectFilter>
        );
    };

    const ActionsButtons = (original: ModelShipper): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => editShipper(original)}>
                    <Edit />
                    <span>{Trans('messages.t.edit')}</span>
                </Typography>

                <Typography
                    className={`dataTableActionsBtn ${original.existItems ? 'disabled' : 'danger'}  `}
                    onClick={() => (original.existItems ? '' : confirmDeleteRow(original))}
                >
                    <Delete />
                    <span>{Trans('messages.t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: Trans('messages.t.name'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => <TextEllipsis title={row.original.name}> {row.original.name} </TextEllipsis>
        },
        {
            Header: Trans('messages.t.type'),
            accessor: 'shipperType',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 100,
            Export: ({ row }: CellProps<JSX.Element>) => {
                return row.original.shipperType ? row.original.shipperType.name.replace('_', ' ') : '';
            },
            Cell: ({ row }: CellProps<JSX.Element>) => {
                return (
                    <div style={{ textTransform: 'capitalize' }}>
                        {row.original.shipperType ? row.original.shipperType.name.replace('_', ' ') : ' '}
                    </div>
                );
            },
            Filter: ({ column, setFilter }: CellProps) => {
                return typeSelectFilter(column, setFilter);
            }
        },
        {
            Header: Trans('messages.t.tracking_url'),
            accessor: 'trackingUrl',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <TextEllipsis title={row.original.trackingUrl}> {row.original.trackingUrl} </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.stock_location'),
            accessor: 'shipperLocationName',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: true,
            aggregate: 'count',
            canFilters: false,
            width: 100,
            disableExport: true,
            Cell: ({ row }: CellProps<JSX.Element>) => (
                <IconButton
                    size='small'
                    style={{ minWidth: 'auto', color: '#039be5' }}
                    title={Trans('messages.t.stock_location')}
                    id={`btn_view_${row.original.id}`}
                    onClick={() => editShipper(row.original)}
                    color='primary'
                >
                    <ListAlt />
                </IconButton>
            )
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            width: 80,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            disableExport: true,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    useEffect(() => {
        getSelect();
    }, []);

    return (
        <ShipperContent
            data-testid={'Shipper-testid'}
            openModal={openModal}
            open={open}
            isLoading={loading}
            closeModal={handleClose}
            handleSubmit={handleSubmit}
            columns={columns}
            valuesInitForm={valuesInitForm}
            dataModal={dataModal}
            fullScreenModal={getFullScreenModal()}
            shipperType={shipperType}
            shipper={shipper}
            stockLocations={dataStockLocations}
            loadMoreStockLocation={loadMoreStockLocation}
            fetchData={getAllShipper}
            fetchDataExport={getForExport}
            total={total}
            pageCount={pageCount}
            refresh={refresh}
            setvaluesInitForm={setvaluesInitForm}
        />
    );
};

export default Shipper;
