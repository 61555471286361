import { Dialog, DialogTitle, Fab } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

export const BoxContent = styled.div``;

export const ButtonFab = styled(Fab)`
    &&& {
        width: 30px;
        height: 30px;
        min-height: 30px;
    }
`;

export const UiScrollbarsBoxCreateOrEdit = styled(Scrollbars)`
    height: auto !important;
    max-height: calc(100vh - 114px) !important;

    & > div:first-child {
        max-height: calc(100vh - 114px) !important;
    }

    border-bottom: thin solid #c0c0c0;
`;

export const UiDialog = styled(Dialog)`
    &&& .css-12ocbpc-MuiPaper-root-MuiDialog-paper {
        max-width: 1850px;
        width: calc(100% - 100px);
        height: auto;
        max-height: calc(100% - 64px);
    }
`;

export const UiDialogTitle = styled(DialogTitle)`
    &&& {
        background: ${({ theme }) => theme.colors.topBar.background};
        color: ${({ theme }) => theme.colors.primary.text};
        padding: 10px;
    }
`;

export const ButtonCloseDialog = styled.div`
    top: 4px;
    position: absolute;
    right: 10px;
`;
