import PortalApi from '../helpers/api/portalApi';
import { LocationType } from '../models/ApiLocationType.type';

export default class ApiLocationType extends PortalApi<LocationType> {
    route = 'stock/location-type';

    getLocationTypeCodeBook = async (): Promise<LocationType[]> => {
        return this.getCodeBook('');
    };
}
