import { atom } from 'recoil';

export const OrderHistory = atom({
    key: 'orderHistory_atom',
    default: []
});

export const OrderHistoryInbound = atom({
    key: 'orderHistoryInbound_atom',
    default: []
});

export const OrderHistoryOutbound = atom({
    key: 'orderHistoryOutbound_atom',
    default: []
});
