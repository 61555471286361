import { Button } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './Unauthorized.style';
import Error_401 from '../../assets/images/error-page401.jpg';
import FullLogo from '../../assets/images/logo_stockcontrol.png';
import Translator from '../../states/global/Translator';
import * as type from './Unauthorized.type';
// import module

export const UnauthorizedContent: React.FC<type.UnauthorizedViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <style.UnauthorizedContent data-testid='UnauthorizedContent'>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <div style={{ marginTop: '15px' }}>
                    <img src={FullLogo} alt=' Error 404' width='200px' />
                </div>

                <div className='error401'>
                    <img src={Error_401} alt=' Error 404' width='350px' />
                </div>

                <p style={{ color: '#444', fontSize: '18px', padding: '0px 10px' }}>
                    {Trans('messages.p.were_sorry_but_you_dont_have_permission_to_view_this_page')}
                    <br />
                    {Trans('messages.p.please_contact_your_administrator')}
                </p>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        props.logout();
                    }}
                >
                    {Trans('messages.t.logout')}
                </Button>
            </div>
            <style.ErrorPage401body />
        </style.UnauthorizedContent>
    );
};
