import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { MainMenuContent } from './MainMenu.view';
import { Menu } from '../../config/menu';
import { Permissions, User } from '../../states/global/User';
import { IMenu, MainMenuProps } from './MainMenu.type';

const MainMenu: React.FC<MainMenuProps> = (props): JSX.Element => {
    const [menuList, setMenuList] = React.useState<IMenu[]>([]);
    const userPermissions = useRecoilValue(Permissions);
    const dataUser = useRecoilValue(User);

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.setShowMainMenu();
    };

    const handleOpenManu = (key) => {
        if (menuList.length) {
            const newList: IMenu[] = menuList.map((item) => {
                if (item.key === key) {
                    item.open = !item.open;
                } else {
                    item.open = false;
                }
                return item;
            });
            setMenuList(newList);
        }
    };

    useEffect(() => {
        userPermissions.length > 0 && setMenuList(Menu(userPermissions, dataUser));
    }, [userPermissions]);

    return (
        <MainMenuContent
            data-testid={'MainMenu-testid'}
            pathname={props.location.pathname}
            showMainMenu={props.showMainMenu}
            setShowMainMenu={props.setShowMainMenu}
            toggleDrawer={toggleDrawer}
            menuList={menuList}
            handleOpenManu={handleOpenManu}
        />
    );
};

export default MainMenu;
