import Cookies, { CookieSetOptions } from 'universal-cookie';
const cookies = new Cookies();

export const setCookie = (name: string, value: string | object, options: CookieSetOptions) => {
    return cookies.set(name, value, options);
};

export const getCookie = (name: string) => {
    return cookies.get(name);
};

export const removeCookie = (name: string, options: CookieSetOptions) => {
    return cookies.remove(name, options);
};
