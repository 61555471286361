import styled, { createGlobalStyle } from 'styled-components';
import Error404White from '../../assets/images/error_404.gif';
import Error404Black from '../../assets/images/error_404_black.gif';
import { ThemeType } from '../../styled/theme.type';

export const ErrorPage404Content = styled.div``;

export const ErrorPage404body = createGlobalStyle``;

export const ContainerImage404 = styled.div`
    width: 500px;
    height: 450px;
    background-image: url(${({ theme }: ThemeType) => (theme.name === 'Dark' ? Error404White : Error404Black)});
    background-size: cover;
    background-repeat: round;
    display: inline-flex;
`;
