import React from 'react';
import OrderHistoryOutbound from '../../components/OrderHistoryOutbound/OrderHistoryOutbound';
import { OrderHistoryOutboundContainert } from './OrderHistoryOutbound.style';
// import module

export const OrderHistoryOutboundContent: React.FC = (): JSX.Element => (
    <OrderHistoryOutboundContainert data-testid='OrderHistoryOutboundContent'>
        <OrderHistoryOutbound />
    </OrderHistoryOutboundContainert>
);
