import styled, { createGlobalStyle } from 'styled-components';

export const GenericItemsContainer = styled.div``;

export const CreateBody = createGlobalStyle`
    .inputSm{
        border-radius: 5px;
        width: 70%;
        border: thin solid #757575;
    }
`;
