import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const StyledContainer = styled(ToastContainer).attrs({})`
    .Toastify__toast-container {
        top: 4em;
    }
    .Toastify__toast {
        padding: 0;
    }
    .Toastify__toast-body {
        margin: 0;
    }
    .Toastify__progress-bar {
        background: rgba(255, 255, 255, 0.5);
    }
    .Toastify__close-button {
        position: absolute;
        top: 0.3em;
        right: 0.3em;
    }
`;
const PopupContainer: React.FC = (): JSX.Element => (
    <StyledContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        style={{ width: '30%' }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
);

export default PopupContainer;
