import { AppBar, Hidden, IconButton, Menu, MenuItem, Switch, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './TopBar.style';
import * as type from './TopBar.type';
import avatar from '../../assets/images/not_avatar.png';
import ChangeLanguage from '../../components/ChangeLanguage/ChangeLanguage';
import ChangeStockLocation from '../../components/ChangeStockLocation/ChangeStockLocation';
import Translator from '../../states/global/Translator';

export const TopBarContent: React.FC<type.TopBarContentProps> = ({
    title,
    anchorEl,
    isMenuOpen,
    handleMenuClose,
    logout,
    handleProfileMenuOpen,
    handleShowMenu,
    openPageProfile,
    setTeheme,
    open
}) => {
    const classes = style.useStyles();
    const Trans = useRecoilValue(Translator);
    const menuId = 'primary-search-account-menu';

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => openPageProfile()}>{Trans('messages.t.profile')}</MenuItem>
            <MenuItem>
                Dark Theme
                <Switch
                    checked={open}
                    onChange={(e) => setTeheme(e)}
                    value={open}
                    color='primary'
                    inputProps={{ 'aria-label': 'secondary checkbox', color: '#ff1616' }}
                />
            </MenuItem>
            <MenuItem onClick={logout}>{Trans('messages.t.logout')}</MenuItem>
        </Menu>
    );

    return (
        <style.TopBar position='fixed' data-testid='TopBarContent'>
            <div className={classes.grow}>
                <AppBar position='static' className='appBarTopBar'>
                    <style.IUToolbar>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <IconButton
                                edge='start'
                                className={classes.menuButton}
                                color='inherit'
                                aria-label='open drawer'
                                onClick={() => handleShowMenu()}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>

                        <Typography className={classes.title} noWrap>
                            {Trans('messages.' + title)}
                        </Typography>

                        <div className={classes.grow} />
                        <div style={{ display: 'contents' }}>
                            <div style={{ paddingRight: '2px' }}>
                                <ChangeStockLocation />
                            </div>
                            <div>
                                <ChangeLanguage />
                            </div>
                            <IconButton
                                style={{ border: 'thin solid #fff', background: '#fafafa', padding: '2px' }}
                                edge='end'
                                aria-label='account of current user'
                                aria-controls={menuId}
                                aria-haspopup='true'
                                onClick={(e) => handleProfileMenuOpen(e, 'prof')}
                                color='inherit'
                            >
                                <img src={avatar} alt='avatar_default' width='25px' />
                            </IconButton>
                        </div>
                    </style.IUToolbar>
                </AppBar>
                {renderMenu}
            </div>
            <style.CustomMenu />
        </style.TopBar>
    );
};
