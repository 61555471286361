import styled, { createGlobalStyle } from 'styled-components';

export const ShipmentAcceptanceDoneContent = styled.div``;

export const ShippingOrderAcceptancebody = createGlobalStyle`
    body{
        background: #fff;
    }

    .text_thx{
        font-size: 22px;
        color: #8abf56;
    }
`;
