import React from 'react';
import { CurrentOrdersOutboundContent } from './CurrentOrdersOutbound.view';
import { CurrentOrdersOutboundProps } from './CurrentOrdersOutbound.type';
import Wrapper from '../../helpers/wrapper';
// import module

const CurrentOrdersOutbound: React.FC<CurrentOrdersOutboundProps> = () => {
    return <CurrentOrdersOutboundContent data-testid='CurrentOrdersOutbound-testid' />;
};

export default Wrapper(CurrentOrdersOutbound);
