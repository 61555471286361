import React, { Component } from 'react';
import * as type from './Popup.type';
import * as view from './Popup.view';

class ErrorPopup extends Component<type.ErrorProps, type.ErrorState> {
    constructor(props: type.ErrorProps) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    updateExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        return <view.ErrContent {...this.state} {...this.props} updateExpand={this.updateExpand} />;
    }
}

export default ErrorPopup;
