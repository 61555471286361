import React, { useState } from 'react';
import * as type from './ImagePopup.type';
import * as view from './ImagePopup.view';

const ImagePopup: React.FC<type.ImagePopupProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <view.ImagePopupContent
                open={isOpen}
                togglePopup={togglePopup}
                closeModal={togglePopup}
                handleSubmit={togglePopup}
                thumbnailUrl={props.thumbnailUrl}
                imageUrl={props.imageUrl}
                height={props.height}
            />
        </>
    );
};

export default ImagePopup;
