import React from 'react';
import User from '../../components/User/User';
import { UserContainer } from './User.style';
// import module

export const UserContent: React.FC = (): JSX.Element => (
    <UserContainer data-testid='UserContent'>
        <User />
    </UserContainer>
);
