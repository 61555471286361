import styled from 'styled-components';
import { createStyles, makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar/AppBar';
import { ThemeType } from '../../styled/theme.type';

export const CustomerAddressContainer = styled.div``;

export const useStyles = makeStyles(() =>
    createStyles({
        inputFilterColumn: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)'
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingTop: '11px !important',
                paddingBottom: '11px !important'
            }
        }
    })
);
export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }: ThemeType) => theme.colors.topBar.background} !important;
`;

export const ContainerBtnCloseModal = styled.div`
    width: 90%;
    text-align: right;
    display: inline-block;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 65%;
    }
`;

export const FilterFieldsContainer = styled.div`
    margin-top: 10px;
`;
