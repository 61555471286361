import PortalApi from '../helpers/api/portalApi';
import { Model } from '../models/ApiShipperType.type';
import { ModelCodeBook } from '../types/common';

export default class ApiShipperType extends PortalApi<Model> {
    route = 'stock/shipper-type';

    getShipperTypeCodeBook = async (): Promise<ModelCodeBook[]> => {
        return this.getCodeBook();
    };
}
