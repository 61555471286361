import React from 'react';
import * as view from './Unauthorized.view';
import Wrapper from '../../helpers/wrapper';
import { removeCookie } from '../../helpers/cookie';
// import module

const Unauthorized: React.FC = () => {
    const logout = (): void => {
        removeCookie('authToken', { path: '/' });
        window.localStorage.removeItem('user:Current');
        window.localStorage.removeItem('theme');
        window.location.href = '/';
    };

    return <view.UnauthorizedContent data-testid={'Unauthorized-testid'} logout={logout} />;
};

export default Wrapper(Unauthorized);
