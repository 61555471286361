import styled, { createGlobalStyle } from 'styled-components';
import PopupState from 'material-ui-popup-state';

export const ActionsButtonsDataTableContent = styled.div``;

export const PopupStateStyle = styled(PopupState)`
    .dataTableActionsBtn {
        &:hover {
            background-color: rgb(163 170 177 / 17%) !important;
            padding: 5px;
            border-radius: 5px;
        }
        svg {
            font-size: 20px !important;
        }
        span {
            font-size: 15px !important;
            padding: 0px 8px;
        }
        padding: 5px;
        display: flex;
        cursor: pointer;
    }
    .containerDataTableActionsBtn {
        .MuiPopover-paper {
            min-width: 180px !important;
            border-radius: 12px !important;
            box-shadow: ${({ theme }: any) =>
                theme.name === 'Dark'
                    ? 'rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px'
                    : 'rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 30%) -20px 20px 40px -4px'};
        }
    }
`;

export const GlobalStyleAcTionsButtons = createGlobalStyle`
    .dataTableActionsBtn{
        &:hover{
            background-color: rgb(163 170 177 / 17%) !important;
            padding: 5px;
            border-radius: 5px;
        }
        svg{
            font-size: 20px !important;
        }
        span{
            font-size: 15px !important;
            padding: 0px 8px;
        }
        padding: 5px;
        display:flex;
        cursor: pointer;
    }
    .containerDataTableActionsBtn{
        .MuiPopover-paper{
            min-width: 180px !important;
            border-radius: 12px !important;
            box-shadow: ${({ theme }: any) =>
                theme.name === 'Dark'
                    ? 'rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px'
                    : 'rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 30%) -20px 20px 40px -4px'};
        }
    }
`;
