import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Loading = ({ size = 20, padding = '0px' }: { size?: number; padding?: string }) => {
    return (
        <div style={{ textAlign: 'center', width: '100%', padding: padding }}>
            <CircularProgress size={size} />
        </div>
    );
};

export default Loading;
