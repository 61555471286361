import AppBar from '@mui/material/AppBar/AppBar';
import styled from 'styled-components';
import { ThemeType } from '../../styled/theme.type';

export const SkuPricesContent = styled.div``;

export const SkuPricesModelContent = styled.div`
    .table-actions-cover {
        padding-left: 15px !important;
        width: auto !important;
    }
`;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }: ThemeType) => theme.colors.topBar.background} !important;
`;
