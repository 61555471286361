import React from 'react';
import ItemWithSerialNumber from '../../components/ItemWithSerialNumber/ItemWithSerialNumber';
import { ItemWithSerialNumberContainer } from './ItemWithSerialNumber.style';
// import module

export const ItemWithSerialNumberContent: React.FC = (): JSX.Element => (
    <ItemWithSerialNumberContainer data-testid='ItemWithSerialNumberContent'>
        <ItemWithSerialNumber />
    </ItemWithSerialNumberContainer>
);
