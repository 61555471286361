import { atom } from 'recoil';
import * as themesAll from '../../styled/themes.json';
import { StructThemeType, ThemeRecoilsType } from '../../styled/theme.type';

const themes: ThemeRecoilsType = themesAll['default'];

export const Theme = atom<StructThemeType>({
    key: 'theme-atom',
    default: themes.data.light
});
