import {
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Link,
    TextField,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './Login.style';
import * as type from './Login.type';
import logo from '../../assets/images/logo_stockcontrol.png';
import Loading from '../../components/Loading';
import Translator from '../../states/global/Translator';
import { useStyles } from '../../styled/global.style';

export const LoginContent: React.FC<type.LoginViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const classes = useStyles();
    return (
        <style.LoginContent data-testid='LoginContent'>
            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent='space-around' direction='row'>
                <div className='login-containers'>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className='logo-stock'>
                            <img src={logo} className='logo-login' alt='logo' />
                            <Divider variant={'inset'} className='divider-logo' />
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Card className='card-login'>
                            <CardContent>
                                {!props.viewForgotPassword ? (
                                    <>
                                        <Typography
                                            align='center'
                                            style={{
                                                color: '#000',
                                                fontWeight: 700,
                                                marginLeft: '3%',
                                                marginBottom: '1%',
                                                textTransform: 'uppercase'
                                            }}
                                        >
                                            {Trans('messages.t.login_to_your_account')}
                                        </Typography>

                                        <Formik
                                            initialValues={props.valuesInitForm}
                                            onSubmit={(values: type.Form, actions) => {
                                                props.handleSubmit(values, actions.setSubmitting);
                                            }}
                                            validationSchema={props.formschema}
                                        >
                                            {(propsF: FormikProps<type.Form>) => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    handleBlur,
                                                    handleChange,
                                                    isSubmitting
                                                } = propsF;
                                                return (
                                                    <Form>
                                                        <Grid container justifyContent='space-around' direction='row'>
                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className={classes.textField}
                                                            >
                                                                <TextField
                                                                    name='username'
                                                                    id='username'
                                                                    label={Trans('messages.t.username')}
                                                                    value={values.username}
                                                                    type='username'
                                                                    helperText={
                                                                        errors.username && touched.username
                                                                            ? errors.username
                                                                            : ''
                                                                    }
                                                                    error={
                                                                        errors.username && touched.username
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    variant='outlined'
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className={classes.textField}
                                                            >
                                                                <TextField
                                                                    name='password'
                                                                    id='password'
                                                                    label={Trans('messages.t.password')}
                                                                    value={values.password}
                                                                    type='password'
                                                                    helperText={
                                                                        errors.password && touched.password
                                                                            ? errors.password
                                                                            : ''
                                                                    }
                                                                    error={
                                                                        errors.password && touched.password
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    variant='outlined'
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className={classes.textField}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={values.stay_signed_in}
                                                                            onChange={handleChange}
                                                                            name='stay_signed_in'
                                                                            color='primary'
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span style={{ color: '#737373' }}>
                                                                            {Trans('messages.p.stay_signed_in')}
                                                                        </span>
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                className={classes.textField}
                                                            >
                                                                <Button
                                                                    size='large'
                                                                    variant='contained'
                                                                    color='primary'
                                                                    type='submit'
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {isSubmitting ? (
                                                                        <CircularProgress size={25} />
                                                                    ) : (
                                                                        Trans('messages.t.continue')
                                                                    )}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>

                                        <Typography
                                            align='center'
                                            style={{ marginTop: '4%', fontWeight: 600, cursor: 'pointer' }}
                                            gutterBottom
                                        >
                                            <Link onClick={() => props.changePassLoad()} style={{ color: '#000' }}>
                                                ¿{Trans('messages.t.forgotten_password')}?
                                            </Link>
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        {props.loading ? (
                                            <Loading />
                                        ) : (
                                            <>
                                                <Typography
                                                    align='center'
                                                    style={{
                                                        color: '#000',
                                                        fontWeight: 700,
                                                        marginLeft: '3%',
                                                        marginBottom: '1%',
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    {Trans('messages.t.recover_password')}
                                                </Typography>

                                                <Formik
                                                    initialValues={props.valuesInitFormChangePassword}
                                                    onSubmit={(values: type.FormChangePass, actions) => {
                                                        props.handleChangePassword(
                                                            values,
                                                            actions.setSubmitting,
                                                            actions.resetForm
                                                        );
                                                    }}
                                                    validationSchema={props.FormChangePasswordschema}
                                                >
                                                    {(propsF: FormikProps<type.FormChangePass>) => {
                                                        const {
                                                            values,
                                                            touched,
                                                            errors,
                                                            handleBlur,
                                                            handleChange,
                                                            isSubmitting
                                                        } = propsF;
                                                        return (
                                                            <Form>
                                                                <Grid
                                                                    container
                                                                    justifyContent='space-around'
                                                                    direction='row'
                                                                >
                                                                    <Grid
                                                                        item
                                                                        lg={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        xs={12}
                                                                        className={classes.textField}
                                                                    >
                                                                        <TextField
                                                                            name='email'
                                                                            id='email'
                                                                            label={Trans('messages.t.email_address')}
                                                                            value={values.email}
                                                                            type='email'
                                                                            helperText={
                                                                                errors.email && touched.email
                                                                                    ? errors.email
                                                                                    : ''
                                                                            }
                                                                            error={
                                                                                errors.email && touched.email
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            variant='outlined'
                                                                        />
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        lg={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        xs={12}
                                                                        className={classes.textField}
                                                                    >
                                                                        <Button
                                                                            size='large'
                                                                            variant='contained'
                                                                            color='primary'
                                                                            type='submit'
                                                                            disabled={isSubmitting}
                                                                        >
                                                                            {isSubmitting ? (
                                                                                <CircularProgress size={25} />
                                                                            ) : (
                                                                                Trans('messages.t.send')
                                                                            )}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Form>
                                                        );
                                                    }}
                                                </Formik>

                                                <Typography
                                                    align='center'
                                                    style={{ marginTop: '4%', fontWeight: 600, cursor: 'pointer' }}
                                                    gutterBottom
                                                >
                                                    <Link
                                                        onClick={() => props.changePassLoad()}
                                                        style={{ color: '#000' }}
                                                    >
                                                        {Trans('messages.t.sign_in')}
                                                    </Link>
                                                </Typography>
                                            </>
                                        )}
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </div>
            </Grid>
        </style.LoginContent>
    );
};
