import styled, { createGlobalStyle } from 'styled-components';
export const DashboardContent = styled.div``;

export const Dashboardbody = createGlobalStyle`
    .dashboardContent{
        flex-grow: 1;
    }
    .dashboardContainer{
        color: rgba(0, 0, 0, 0.69) !important;
        width: 100%;
        text-align: center;
        padding: 20px 0px;
    }
`;
