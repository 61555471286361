import React from 'react';
import { ResetPasswordContent } from './ResetPassword.view';
import { ResetPasswordProps } from './ResetPassword.type';
import Wrapper from '../../helpers/wrapper';
// import module

const ResetPassword: React.FC<ResetPasswordProps> = () => {
    return <ResetPasswordContent data-testid='ResetPassword-testid' />;
};

export default Wrapper(ResetPassword);
