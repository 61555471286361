import React, { useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';
import * as type from './BoxItems.type';
import * as view from './BoxItems.view';
import ApiBox from '../../api/ApiBox';
import * as BoxModel from '../../models/ApiBox.type';
import ApiItems from '../../api/ApiItems';
import Wrapper from '../../helpers/wrapper';
import Translator from '../../states/global/Translator';
import { Success } from '../Popup/Popup';
import { ColumnsTable } from '../../types/common';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { TextEllipsis } from '../../styled/global.style';
import { ModelItemsLocation, StockItemsType } from '../../models/ApiItems.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { toCamelCaseAndRemove_ } from '../../helpers/converter';
// import module

const apiBox = new ApiBox();
const apiItems = new ApiItems();

const BoxItems: React.FC<type.BoxItemsProps> = (props) => {
    const [modalItemsLoading, setModalItemsLoading] = useState<boolean>(true);
    const [dataBoxItems, setdataBoxItems] = useState<StockItemsType[]>([]);
    const [boxData, setboxData] = useState<StockItemsType | null>(null);
    const [columnsBoxItems, setColumnsBoxItems] = useState<ColumnsTable[]>([]);
    const Trans = useRecoilValue(Translator);

    const getItems = async (id): Promise<void> => {
        props.setBoxId(id);
        setModalItemsLoading(true);

        try {
            if (props.hash) {
                const {
                    data: { item }
                }: BoxModel.ModelGeneral = await apiBox.getGeneral(`stock/public/item/${id}/${props.hash}`);

                if (!item) {
                    throw item;
                }

                setdataBoxItems(item?.children);
                setboxData(item);
            } else {
                const data: ModelItemsLocation = await apiItems.getItemsById(id);

                if (!data.item) {
                    throw data.item;
                }

                setdataBoxItems(data.item.children);
                setboxData(data.item);
            }
        } catch (error) {
            console.error('Error to sending data ', error);
        }
        setModalItemsLoading(false);
    };

    const confirmDeleteItemsBox = (row, boxData) => {
        row.boxData = boxData;
        return popUpConfirm(
            Trans('messages.p.remove_items_from_the_box') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_delete'),
            removeItemsBox,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );
    };

    const removeItemsBox = async (onClose, { id, sku, boxData }): Promise<void> => {
        const newListItems: StockItemsType[] = boxData.children?.filter((i) => i.id !== id);
        setdataBoxItems(newListItems);

        const urlBaseItemsBox: string = props.hash
            ? `public/stock/item/box/${props.BoxId}/${props.hash}`
            : `stock/item/box/${props.BoxId}`;

        const newDataToSend: type.stockItemSend = {
            items: newListItems.map((i) => ({ id: i.id, quantity: i.quantity })),
            sku: boxData.sku.id,
            location: boxData.location.id,
            attributes: boxData.itemAttribute.map((i) => ({ box_label: i.value }))
        };

        try {
            await apiItems.patchGeneralFree(urlBaseItemsBox, newDataToSend);
            const skuId: string = Object.keys(sku).length ? sku.id + ' ' : '';
            Success({
                text: Trans('messages.t.item') + ': ' + skuId + Trans('messages.p.was_removed_from_the_box')
            });
            onClose();
        } catch (error) {
            console.error('Error to send data ', error);
        }
    };

    const getColumns = async (): Promise<void> => {
        const columnsBoxItem: ColumnsTable[] = [
            {
                Header: Trans('messages.t.serial_number'),
                accessor: 'serialNumber',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 120,
                Cell: ({ row: { original } }: CellProps) =>
                    original.sku.skuType.type && original.sku.skuType.type !== 'box'
                        ? original.serialNumber
                        : original?.label
                        ? original.label
                        : '---'
            }
        ];

        if (props.public) {
            columnsBoxItem.push({
                Header: Trans('messages.t.name'),
                accessor: 'sku.customerLabel',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 100
            });
        } else {
            // push ID column to start columnsBoxItem array
            columnsBoxItem.unshift({
                Header: 'ID',
                accessor: 'id',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 50
            });

            columnsBoxItem.push({
                Header: Trans('messages.t.sku_code'),
                accessor: 'sku.skuCode',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                width: 100,
                Cell: ({ row: { original } }: CellProps) =>
                    original.sku.skuType.type && original.sku.skuType.type === 'box' ? '---' : original.sku.skuCode
            });

            columnsBoxItem.push({
                Header: Trans('messages.t.name'),
                accessor: 'sku.name',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                Cell: ({ row }: CellProps) => (
                    <TextEllipsis title={row.original.sku.name}> {row.original.sku.name} </TextEllipsis>
                )
            });
        }

        columnsBoxItem.push({
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        });

        columnsBoxItem.push({
            Header: Trans('messages.t.type'),
            accessor: 'sku.skuType.type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50,
            Cell: ({ row: { original } }: CellProps) => {
                if (original.sku?.skuType && !!Object.keys(original.sku.skuType).length) {
                    let name = toCamelCaseAndRemove_(original.sku.skuType.type);

                    if (original.sku.skuType.type === 'hub') {
                        name =
                            toCamelCaseAndRemove_(original.sku.name) +
                            ' [ID:' +
                            (original.itrackDeviceId || original.itemId) +
                            ']';
                    }

                    return name;
                }

                return '';
            }
        });

        if (!props.public) {
            columnsBoxItem.push({
                Header: Trans('messages.t.shipping_price_in_usd'),
                accessor: 'shippingOrderItemPriceUsd',
                disableGroupBy: true,
                disableSortBy: true,
                disableFilters: true,
                aggregate: 'count',
                canFilters: false,
                Cell: ({ row: { original } }: CellProps) => original.shippingOrderItemPriceUsd ?? '---'
            });
        }

        columnsBoxItem.push({
            Header: Trans('messages.t.action'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            width: 50,
            Cell: ({ row: { original } }: CellProps) => (
                <IconButton
                    disabled={!props.showAction}
                    size='small'
                    className='danger'
                    style={{ minWidth: 'auto' }}
                    title={Trans('messages.t.remove')}
                    id={`removeItems${original.id}`}
                    onClick={() => confirmDeleteItemsBox(original, boxData)}
                >
                    <Delete />
                </IconButton>
            )
        });

        props.hash && columnsBoxItem.splice(5);
        setColumnsBoxItems(columnsBoxItem);
    };

    const closeModalItems = () => {
        setdataBoxItems([]);
        setboxData(null);
        props.setModalItems(false);
        props.setBoxId(null);
    };

    useEffect(() => {
        getColumns();
    }, [boxData]);

    useEffect(() => {
        props.BoxId && getItems(props.BoxId);
    }, [props.BoxId]);

    return (
        <view.BoxItemsContent
            data-testid={'BoxItems-testid'}
            columnsBoxItems={columnsBoxItems}
            modalItems={props.modalItems}
            modalItemsLoading={modalItemsLoading}
            dataBoxItems={dataBoxItems}
            closeModalItems={closeModalItems}
            typeModal={props.typeModal ? props.typeModal : 'modal'}
            boxId={props.BoxId}
        />
    );
};

export default Wrapper(BoxItems);
