import React from 'react';
import * as Yup from 'yup';
import { IMarkAsShippedForm, MarkAsShippedFormik as MarkAsShippedFormikType } from './MarkAsShipped.type';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import { User } from '../../../states/global/User';
import { SelectAsyncPaginate } from '../../FormElements';
import Translator from '../../../states/global/Translator';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { IUAlert, GridFieldContainer } from '../../../styled/global.style';
import { InputElement } from '../../FormElements/index';
import { FullDataUser } from '../../User/User.type';
import { DropzoneArea } from 'material-ui-dropzone';

export const MarkAsShippedFormik: React.FC<MarkAsShippedFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const userState = useRecoilValue<FullDataUser | null>(User);

    const Formschema = Yup.object({
        trackingId: Yup.string()
            .min(5, 'Tracking url is Too Short.')
            .max(45, 'Value is Too large.')
            .required(Trans('messages.t.tracking_number_is_required')),
        shippedAt: Yup.string().required(Trans('messages.t.shipped_at_is_required')),
        shipperId: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        })
    });

    const getBase64 = function (file, cb) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
    };

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: IMarkAsShippedForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<IMarkAsShippedForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);

                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='inicial' direction='row'>
                                <Grid item lg={4} md={4} sm={12} xs={12} className='textFieldFormik'>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDateTimePicker
                                            label={Trans('messages.t.shipped_at')}
                                            value={values.shippedAt}
                                            disableFuture={true}
                                            onChange={(newValue) => {
                                                setFieldValue(
                                                    'shippedAt',
                                                    newValue ? props.fromUserTimezoneToTimezone(newValue) : ''
                                                );
                                            }}
                                            mask={props.getDateMask ?? '____-__-__ __:__'}
                                            inputFormat={
                                                userState
                                                    ? `${userState?.userSetting.dateFormat} ${userState?.userSetting.timeFormat}`
                                                    : 'YYYY-MM-DD HH:mm'
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name='shippedAt'
                                                    label={
                                                        <span className='fieldRequired'>
                                                            <i>*</i> {Trans('messages.t.shipped_at')}
                                                        </span>
                                                    }
                                                    size='small'
                                                    autoFocus
                                                    helperText={
                                                        params.error
                                                            ? errors.shippedAt
                                                                ? errors.shippedAt
                                                                : errors.shippedAt
                                                            : ''
                                                    }
                                                    error={!!errors.shippedAt}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <InputElement
                                    label={Trans('messages.t.tracking_number')}
                                    name='trackingId'
                                    value={values.trackingId}
                                    error={errors.trackingId}
                                    touched={touched.trackingId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    lg={4}
                                    md={4}
                                    sm={4}
                                />

                                <SelectAsyncPaginate
                                    name='shipperId'
                                    label={Trans('messages.t.shipper')}
                                    value={props.shippingOrder.shipper ? values.shipperId : values.shipperId[0]}
                                    error={!!errors.shipperId}
                                    options={props.shippers.length ? props.shippers : []}
                                    onChange={(value) => {
                                        setFieldValue('shipperId', value ? value : {});
                                    }}
                                    required={true}
                                    loadOptions={props.loadMoreShipper}
                                    textRequired={Trans('messages.p.this_field_is_required')}
                                    lg={4}
                                    md={4}
                                    sm={4}
                                />

                                <GridFieldContainer item lg={12} md={12} sm={12} xs={12}>
                                    <DropzoneArea
                                        dropzoneClass='textColor'
                                        dropzoneText={Trans('messages.p.drag_and_drop_file')}
                                        acceptedFiles={[
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                            'application/vnd.ms-excel',
                                            'application/msword',
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                            'application/application/msword',
                                            'application/vnd.oasis.opendocument.text',
                                            'application/vnd.oasis.opendocument.spreadsheet',
                                            'application/vnd.oasis.opendocument.presentation',
                                            'text/csv',
                                            'application/vnd.ms-excel',
                                            'image/png',
                                            'image/jpg',
                                            'image/jpeg',
                                            'application/pdf',
                                            'application/x-pdf',
                                            'application/x-bzpdf',
                                            'application-gzpdf'
                                        ]}
                                        onChange={(files) => {
                                            if (files && files.length > 0) {
                                                const uploadFiles: object[] = [];

                                                files.forEach((file) => {
                                                    getBase64(file, (fileInBase64) => {
                                                        uploadFiles.push({
                                                            base64: fileInBase64,
                                                            name: file.name
                                                        });

                                                        setFieldValue('files', uploadFiles);
                                                    });
                                                });
                                            }
                                        }}
                                        filesLimit={20}
                                        clearOnUnmount={true}
                                    />
                                </GridFieldContainer>
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
