import React from 'react';
import * as view from './Shipper.view';
import * as type from './Shipper.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Shipper: React.FC<type.ShipperProps> = () => {
    return <view.ShipperContent data-testid='Shipper-testid' />;
};

export default Wrapper(Shipper);
