import { ItemModel } from '../ShippingOrder/EditShippingOrder/EditShippingOrder.type';
import { ColumnsTable, CrudI, ModelCodeBook } from '../../types/common';
import { ItemsAvailablesFilter } from '../CreateOrder/CreateOrder.type';
import { LocationsItems } from '../../models/ApiItems.type';

export type BoxProps = Record<string, unknown>;

export type Box = {
    name: string;
    type: string;
    sku_code: number;
    item_type: number;
    quantity: number;
};

export type BoxData = Box & {
    subRows?: BoxData[];
};

export interface BoxViewProps extends CrudI {
    loadingItems?: boolean;
    columnsAvailableItems: ColumnsTable[];
    itemsFrom?: ItemModel[];
    columnsItemsToSend: ColumnsTable[];
    itemsToSend?: ItemModel[];
    loadingForm?: boolean;
    elementFilter?: JSX.Element;
    resetFilter: boolean;
    SKUs: ModelCodeBook[];
    fetchData: (
        page: number,
        limit: number,
        order: Record<string, unknown>,
        filter: Record<string, unknown>,
        extraFiltering: { shippedFilter: string; locationFilter: string }
    ) => void;
    fetchDataExport?: (
        order: Record<string, unknown>,
        filter: Record<string, unknown>,
        extraFiltering: { shippedFilter: string; locationFilter: string }
    ) => void;
    total?: number;
    pageCount?: number;
    refresh: boolean;
    setResetFilter: React.Dispatch<React.SetStateAction<boolean>>;
    setItemsToSend: React.Dispatch<React.SetStateAction<ItemModel[]>>;
    resetPageIndexTable: boolean;
    extraFiltering: { shippedFilter: string; locationFilter: string } | { shippedFilter: ''; locationFilter: '' };
    serialNumber: string;
    setSerialNumber: React.Dispatch<React.SetStateAction<string>>;
    setResetPageIndexTable: React.Dispatch<React.SetStateAction<boolean>>;
    fetchDataItems: (
        page: number,
        limit: number,
        order: Record<string, unknown>,
        filter: Record<string, unknown>,
        extraFiltering: { locationFilter: string },
        boxId?: number | null
    ) => void;
    totalItems?: number;
    pageCountItems?: number;
    refreshItems: boolean;
    extraFilteringItems: ItemsAvailablesFilter;
    setLocationFilter: React.Dispatch<React.SetStateAction<ItemsAvailablesFilter>>;
}

export interface IBoxForm {
    sku_id?: string;
    itemId?: string;
    quantity?: string;
    location?: Record<string, unknown>;
    locationSelect: LocationsItems;
    type: string;
    label: string;
    sku: number;
    stock_location_id?: number;
    itrackDeviceId?: number;
    itrackTabletId?: number;
}

export interface IItem {
    id: number;
    name: string;
    quantity: number;
    quantityItem?: number;
    type: string;
    serialNumber: string;
}

export enum CrudAction {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    DELETE = 'DELETE'
}

export enum ItemsType {
    GENERIC_ITEM = 'generic_item',
    BOX = 'box',
    TABLET = 'tablet',
    HUB = 'hub',
    SENSOR = 'sensor'
}

export type IItemvalue = {
    id: number;
    value: string | number | null;
};
