import React, { useEffect } from 'react';
import * as type from './UiLegend.type';
import * as view from './UiLegend.view';
import Wrapper from '../../../helpers/wrapper';
// import module

const UiLegend: React.FC<type.UiLegendProps> = (props): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.close && handleClose();
    }, [props.close]);

    return (
        <view.UiLegendContent
            openLegend={handleClick}
            closeLegend={handleClose}
            {...props}
            anchorEl={anchorEl}
            data-testid={'UiLegend-testid'}
        />
    );
};

export default Wrapper(UiLegend);
