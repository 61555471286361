import React from 'react';
import Box from '../../components/Box/Box';
import { BoxesContainer } from './Boxes.style';
// import module

export const BoxesContent: React.FC = (): JSX.Element => (
    <BoxesContainer data-testid='BoxesContent'>
        <Box />
    </BoxesContainer>
);
