import React from 'react';
import Roles from '../../components/Roles/Roles';
import { RolesContainer } from './Roles.style';
// import module

export const RolesContent: React.FC = (): JSX.Element => (
    <RolesContainer data-testid='RolesContent'>
        <Roles />
    </RolesContainer>
);
