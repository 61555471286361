import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { CustomerAddressWithPagnator, ICustomerAddress, Model } from '../models/ApiCustomerAddress.type';

export default class ApiCustomerAddress extends PortalApi<Model> {
    route = 'webtrack/customer-address';

    getAll = async (page, limit, order, filter, route = ''): Promise<CustomerAddressWithPagnator> => {
        return await this.getTable<ICustomerAddress>(
            route,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getWithPagination = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<CustomerAddressWithPagnator> => {
        return await this.getTable<ICustomerAddress>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };
}
