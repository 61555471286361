import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { Tooltip } from '@mui/material';

const ClickToCopy: React.FC<{ children: JSX.Element; text: string | number; enabled?: boolean }> = ({
    children,
    text,
    enabled = true
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const [titleTooltip, setTitleTooltip] = useState<string>(Trans('messages.t.click_to_copy'));

    const handleCopy = (textToCopy): void => {
        navigator.clipboard
            .writeText(textToCopy.toString() ?? ' ')
            .then(() => {
                setTitleTooltip(`${Trans('messages.t.copied')}!`);
            })
            .catch((error) => {
                console.error('Error when copy:', error);
            });
    };

    useEffect(() => {
        setTimeout(() => {
            setTitleTooltip(Trans('messages.t.click_to_copy'));
        }, 2000);
    }, [titleTooltip]);

    if (!enabled) {
        return children;
    }

    return (
        <Tooltip title={titleTooltip} aria-label={titleTooltip}>
            <div onClick={() => handleCopy(text)} className='textClickToCopy'>
                {children}
            </div>
        </Tooltip>
    );
};

export default ClickToCopy;
