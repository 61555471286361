import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { ModelShipper, ShipperResponse } from '../models/ApiShipper.type';

export default class ApiShipper extends PortalApi<ModelShipper> {
    route = 'stock/shipper';

    getAll = async (page, limit, order, filter): Promise<ShipperResponse> => {
        return await this.getTable<ModelShipper>(
            '',
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getWithPaginationDropDown = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<ShipperResponse> => {
        return await this.getTable<ModelShipper>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };
}
