import React from 'react';
import { TrentShipmentContainer } from './TrentShipment.style';
import TrentShipment from '../../components/TrentShipment/TrentShipment';
// import module

export const TrentShipmentContent: React.FC = (): JSX.Element => (
    <TrentShipmentContainer data-testid='TrentShipmentContent'>
        <TrentShipment />
    </TrentShipmentContainer>
);
