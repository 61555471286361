import { AppBar, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars-2';

export const UiModalContent = styled.div``;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }) => theme.colors.topBar.background} !important;
    position: relative !important;
    text-align: center !important;
`;

export const UiToolbar = styled(Toolbar)`
    min-height: 40px !important;
`;

export const TitlePage = styled(Typography)`
    width: 100%;
`;

export const UiScrollbarsModal = styled(Scrollbars)<{ $minHeight: number; $maxHeight: string }>`
    height: auto !important;
    border-bottom: thin solid #c0c0c0;
    max-height: ${({ $maxHeight }) => $maxHeight || 'calc(100vh - 75px)'} !important;

    ${({ $minHeight }) => $minHeight && ` min-height: ${$minHeight}px !important; `}

    & > div:first-child {
        max-height: ${({ $maxHeight }) => $maxHeight || 'calc(100vh - 75px)'} !important;

        ${({ $minHeight }) =>
            $minHeight &&
            `
            min-height: ${$minHeight + 17}px !important;
            margin-bottom: -17px !important;
        `}
    }
`;

export const ContainerBtnClose = styled.div`
    margin: 0 auto;
    position: absolute;
    right: 20px;
    padding: 0px;
}
`;
