import PortalApi from '../helpers/api/portalApi';
import { SkuTypeModel } from '../models/ApiSkuType.type';

export default class ApiSkuType extends PortalApi<SkuTypeModel> {
    route = 'stock/sku-type';

    getSkuTypeCodeBook = async (): Promise<SkuTypeModel[]> => {
        return this.getCodeBook();
    };
}
