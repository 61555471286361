import React from 'react';
import { useRecoilValue } from 'recoil';
import * as style from './BoxItems.style';
import * as type from './BoxItems.type';
import Translator from '../../states/global/Translator';
import UiTable from '../../components/Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import UiModal from '../Ui/UiModal/UiModal';
// import modul

export const BoxItemsContent: React.FC<type.BoxItemsViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <style.BoxItemsContent data-testid='BoxItemsContent'>
            <UiModal
                open={props.modalItems}
                closeModal={props.closeModalItems}
                title={Trans('messages.t.box_items') + ' - #' + props.boxId}
                maxWidthModal='xl'
                content={
                    props.columnsBoxItems.length && !props.modalItemsLoading ? (
                        <UiTable
                            classesTable='containerDataTable'
                            data={props.dataBoxItems}
                            loading={false}
                            hidePagination={true}
                            hideForceRefresh={true}
                            noPagination={true}
                            columns={props.columnsBoxItems}
                            hiddenColumns={[]}
                            globalFilter={true}
                        />
                    ) : (
                        <Loading />
                    )
                }
            />
        </style.BoxItemsContent>
    );
};
