import React from 'react';
import * as Yup from 'yup';
import { Grid, TextField } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import { IUAlert } from '../../../styled/global.style';
import { AutocompleteElement } from '../../FormElements/index';
import { IRelsolveDisputeForm, RelsolveDisputeFormik } from './ResolveDispute.type';
import { ShippingOrderStatusEnum } from '../../CurrentOrdersOutbound/CurrentOrdersOutbound.type';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FullDataUser } from '../../User/User.type';
import { User } from '../../../states/global/User';
import { GetDateMask } from '../../../helpers/Converters';

export const ResolveDisputeFormik: React.FC<RelsolveDisputeFormik> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const userState = useRecoilValue<FullDataUser | null>(User);
    const getDateMask = useRecoilValue(GetDateMask);
    const [status, setStatus] = React.useState();

    const Formschema = Yup.object({
        shippingOrderStatus: Yup.string().required(Trans('messages.t.status_is_required'))
    });

    let statusOptions: { value: string; label: string }[] = [
        { value: '3', label: Trans('messages.t.delivered') },
        { value: '4', label: Trans('messages.t.lost') },
        { value: '8', label: Trans('messages.t.cancelled') },
        { value: '9', label: Trans('messages.t.dispute_resolved') }
    ];

    let defaultValueOptions: { value: string; label: string } = {
        value: '9',
        label: Trans('messages.t.dispute_resolved')
    };

    if (props.shippingOrder) {
        if (props.shippingOrder.shippingOrderStatus.status === ShippingOrderStatusEnum.LOST) {
            props.valuesInitForm.shippingOrderStatus = '2';
            const options: { value: string; label: string } = { value: '2', label: Trans('messages.t.in_transit') };
            defaultValueOptions = options;
            statusOptions = [options];
        }
        if (props.shippingOrder.shippingOrderStatus.status === ShippingOrderStatusEnum.IN_TRANSIT) {
            props.valuesInitForm.shippingOrderStatus = '4';
            const options: { value: string; label: string } = { value: '4', label: Trans('messages.t.lost') };
            defaultValueOptions = options;
            statusOptions = [options];
        }
    }

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: IRelsolveDisputeForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<IRelsolveDisputeForm>) => {
                const { touched, errors, setFieldValue, handleBlur, handleChange, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='inicial' direction='row'>
                                <AutocompleteElement
                                    label={Trans('messages.t.status')}
                                    name='shippingOrderStatus'
                                    value={values.shippingOrderStatus}
                                    error={errors.shippingOrderStatus}
                                    touched={touched.shippingOrderStatus}
                                    size='small'
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    options={statusOptions}
                                    defaultValue={defaultValueOptions}
                                    onChange={(e, value) => {
                                        setFieldValue('shippingOrderStatus', value ? value.value : '');
                                        setStatus(value ? value.value : '');
                                    }}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                />
                            </Grid>
                            {status === '3' && (
                                <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className='datepikerFieldFormikAlert'
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label={Trans('messages.t.select_date')}
                                            value={values.statusAt}
                                            inputFormat={userState ? userState?.userSetting.dateFormat : 'YYYY-MM-DD'}
                                            mask={getDateMask}
                                            onChange={(value) => {
                                                setFieldValue('statusAt', value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    helperText={
                                                        params.error ? Trans('messages.t.wrong_date_format') : ''
                                                    }
                                                    size='small'
                                                    className='customStyleDatePickerFilter'
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='textFieldFormikAlert'>
                                <TextField
                                    id='comment'
                                    fullWidth
                                    label={Trans('messages.t.comment')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.comment || ''}
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                            <br />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
