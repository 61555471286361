import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import {
    ItemsModel,
    ItemsModelResponse,
    ItemsResponse,
    ModelItemsLocation,
    StockItemsType
} from '../models/ApiItems.type';

export default class ApiItems extends PortalApi<ModelItemsLocation> {
    route = 'stock/item';

    getAll = async (route, page, limit, order, filter, version = '/v2'): Promise<ItemsModelResponse> => {
        return await this.getTable<ItemsModel>(
            route,
            version,
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getAllBox = async (route, page, limit, order, filter): Promise<ItemsResponse> => {
        return await this.getTable<StockItemsType>(
            route,
            '/v2',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getItemsById = async (itemId: number): Promise<ModelItemsLocation> => {
        return await this.getById(itemId).then((data: ModelItemsLocation) => {
            return data;
        });
    };
}
