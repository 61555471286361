import { Build, InsertEmoticon, NavigateNext, Phone, SentimentVeryDissatisfied } from '@mui/icons-material';
import React from 'react';

type stateType = {
    hasError: boolean;
};

export default class ErrorBoundary extends React.Component<unknown, stateType> {
    constructor(props: unknown) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <span>
                        <SentimentVeryDissatisfied />
                        <NavigateNext />
                        <Phone />
                        <NavigateNext />
                        <Build />
                        <NavigateNext />
                        <InsertEmoticon />
                    </span>
                    <span style={{ display: 'block' }}>Something Is Broken in the Component of Webtrack</span>
                </div>
            );
        }

        return this.props.children;
    }
}
