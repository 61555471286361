import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as type from './Layout.type';
import * as view from './Layout.view';
import * as UserState from '../../states/global/User';
import Account from '../../api/Account';
import Wrapper from '../../helpers/wrapper';
import { FullDataUser } from '../User/User.type';

const apiAccount = new Account();

const Layout: React.FC<RouteComponentProps | type.LayoutProps> = (props): JSX.Element => {
    const [showMainMenu, set_ShowMainMenu] = useState(false);
    const setShowMainMenu = () => set_ShowMainMenu(!showMainMenu);
    const [user, setUser] = useRecoilState(UserState.User);
    const setPermissions = useSetRecoilState(UserState.Permissions);

    const getUser = useCallback(async () => {
        try {
            // const { data }: Usertype.Model = await api.getGeneral('account');
            const user: FullDataUser = await apiAccount.getAccountParsed();

            if (user) {
                setUser(user);
                user.userPermissions.length && setPermissions(user.userPermissions);
            }
        } catch (error) {
            console.error('Get user ', error);
        }
    }, [setUser, setPermissions]);

    useEffect(() => {
        !user && getUser();
    }, [getUser, user]);

    return (
        <view.LayoutContent
            {...props}
            data-testid={'Layout-testid'}
            showMainMenu={showMainMenu}
            setShowMainMenu={setShowMainMenu}
        >
            {' '}
        </view.LayoutContent>
    );
};

export default Wrapper(Layout);
