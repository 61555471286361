import React from 'react';
import Tablet from '../../components/Tablet/Tablet';
import { TabletContainer } from './Tablet.style';
// import module

export const TabletContent: React.FC = (): JSX.Element => (
    <TabletContainer data-testid='TabletContent'>
        <Tablet />
    </TabletContainer>
);
