import { atom, selector } from 'recoil';
import { FullDataUser } from '../../components/User/User.type';

export const User = atom<FullDataUser | null>({
    key: 'user-atom',
    default: null
});

export const Permissions = atom<string[] | []>({
    key: 'permissions-atom',
    default: []
});

export const UserInfo = selector({
    key: 'UserInfo',
    get: async ({ get }) => {
        const user = get(User);
        return { user };
    }
});
