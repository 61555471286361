import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import {
    IStockLocation,
    LocationAccesWithPagnator,
    LocationWithPagnator,
    Model
} from '../models/ApiStockLocation.type';
import { UserLocationAccess } from '../types/common';

export default class ApiStockLocation extends PortalApi<Model> {
    route = 'stock/location';

    getAll = async (page, limit, order, filter, route = ''): Promise<LocationWithPagnator> => {
        return await this.getTable<IStockLocation>(
            route,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getWithPagination = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<LocationWithPagnator> => {
        return await this.getTable<IStockLocation>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };
}

export class ApiStockLocationByIdUser extends PortalApi<UserLocationAccess> {
    route = 'stock/user-location-access/user';

    getWithPaginationByUser = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        userId
    ): Promise<LocationAccesWithPagnator> => {
        return await this.getTable<UserLocationAccess>(
            `/${userId}`,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };
}
