import React from 'react';
import { ProfileContent } from './Profile.view';
import { ProfileProps } from './Profile.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Profile: React.FC<ProfileProps> = () => {
    return <ProfileContent data-testid='Profile-testid' />;
};

export default Wrapper(Profile);
