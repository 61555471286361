import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import styled, { createGlobalStyle } from 'styled-components';
import Scrollbars from 'react-custom-scrollbars-2';

export const EditShippingOrderContent = styled.div``;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }) => theme.colors.topBar.background} !important;
`;

export const ContainerBtnBoxItems = styled.div`
    width: 90%;
    text-align: right;
    display: inline-block;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 65%;
    }
}
`;

export const TitlePage = styled(Typography)`
    width: 500px;
`;

export const ShippingOrderbody = createGlobalStyle`
    .makeStyles-tableBody-18{
        max-height: 470px;
        overflow-y: auto;
    }
    .makeStyles-tableHeadCell-10{
        font-size: 16px !important;
    }
    .containerDataTable{
        min-height: 350px;
    }
`;
export const ButtonSuccess = withStyles(() => ({
    root: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    }
}))(Button);

export const useStyles = makeStyles(() =>
    createStyles({
        inputFilterColumn: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)'
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingTop: '11px !important',
                paddingBottom: '11px !important'
            }
        }
    })
);

export const UiScrollbarsEditOrder = styled(Scrollbars)`
    height: auto !important;
    border-bottom: thin solid #c0c0c0;
    max-height: calc(100vh - 75px) !important;

    & > div:first-child {
        max-height: calc(100vh - 75px) !important;
    }
`;

export const UiToolbar = styled(Toolbar)`
    min-height: 40px !important;
`;

export const FieldsetLeftContainer = styled.fieldset`
    min-height: 578px;
    height: auto;
    border-radius: 5px;
    padding-left: 0px;
`;

export const FieldsetContainer = styled.fieldset`
    min-height: 578px;
    height: auto;
    border-radius: 5px;

    @media (max-width: 1915px) {
        min-height: 578px;
    }
`;
