import React from 'react';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { SelectAsyncPaginate } from '../FormElements';
import { LocationDropDownViewProps } from './LocationDropDown.type';
// import module

export const LocationDropDownContent: React.FC<LocationDropDownViewProps> = ({
    value,
    error,
    locationList,
    onChange,
    required,
    name,
    label,
    loadMoreLocation,
    lg,
    md,
    sm
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <SelectAsyncPaginate
            name={name}
            label={label}
            value={value}
            error={error}
            options={locationList}
            onChange={onChange}
            required={required}
            loadOptions={loadMoreLocation}
            textRequired={Trans('messages.p.this_field_is_required')}
            lg={lg}
            md={md}
            sm={sm}
        />
    );
};
