import React from 'react';
import { UserContent } from './User.view';
import { UserProps } from './User.type';
import Wrapper from '../../helpers/wrapper';
// import module

const User: React.FC<UserProps> = () => {
    return <UserContent data-testid='User-testid' />;
};

export default Wrapper(User);
