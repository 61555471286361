import { AppBar, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled, { createGlobalStyle } from 'styled-components';

export const ShipmentAcceptanceContent = styled.div``;

export const ContainerFormSendEmail = styled.div``;

export const ContainerBtnSendEmail = styled.div`
    margin-top: 14px;
`;

export const ShippingOrderAcceptancebody = createGlobalStyle`
.value_form_shippingorder_details{
    font-size: 16px;
    color: #7d7d7d;
    border-bottom: thin solid #7d7d7d;
    display: block;
  }
.dataHeaderAcceptance{
    display: flex;

}
@media (max-width: 768px) {
    .dataHeaderAcceptance{
        display: block;
    
    }
  }
`;

export const DrawerShowItems = styled(Drawer)`
    width: 500px;
    height: 400px;
`;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }: any) => theme.colors.topBar.background} !important;
`;

export const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    menuPaper: {
        maxHeight: 207,
        margin: 0
    }
}));

export const TitleItemWithIcon = styled.div`
    display: inline-block;
    margin-top: -5px;
`;
