import React from 'react';
import ImportOrder from '../../components/ImportOrder/ImportOrder';
import { ImportOrderContainer } from './ImportOrder.style';
// import module

export const ImportOrderContent: React.FC = (): JSX.Element => (
    <ImportOrderContainer data-testid='ImportOrderContent'>
        <ImportOrder />
    </ImportOrderContainer>
);
