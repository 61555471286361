import React from 'react';
import { SkuContent } from './Sku.view';
import { SkuProps } from './Sku.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Sku: React.FC<SkuProps> = () => {
    return <SkuContent data-testid='Sku-testid' />;
};

export default Wrapper(Sku);
