import React from 'react';
import * as type from './ErrorPage404.type';
import * as view from './ErrorPage404.view';
import Wrapper from '../../helpers/wrapper';
// import module

const ErrorPage404: React.FC<type.ErrorPage404Props> = (props): JSX.Element => {
    return <view.ErrorPage404Content location={props.location} data-testid={'ErrorPage404-testid'} />;
};

export default Wrapper(ErrorPage404);
