import React from 'react';
import CurrentOrdersInbound from '../../components/CurrentOrdersInbound/CurrentOrdersInbound';
import { CurrentOrdersInboundContainer } from './CurrentOrdersInbound.style';
// import module

export const CurrentOrdersInboundContent: React.FC = (): JSX.Element => (
    <CurrentOrdersInboundContainer data-testid='CurrentOrdersInboundContent'>
        <CurrentOrdersInbound />
    </CurrentOrdersInboundContainer>
);
