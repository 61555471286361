import React from 'react';
import Profile from '../../components/Profile/Profile';
import { ProfileContainer } from './Profile.style';
// import module

export const ProfileContent: React.FC = (): JSX.Element => (
    <ProfileContainer data-testid='ProfileContent'>
        <Profile />
    </ProfileContainer>
);
