import { IconButton, Typography } from '@mui/material';
import { Info, Close } from '@mui/icons-material';
import React from 'react';
import * as style from './UiLegend.style';
import * as type from './UiLegend.type';
// import module

export const UiLegendContent: React.FC<type.UiLegendViewProps> = (props): JSX.Element => {
    const RootElement: React.ReactElement = props.root || (
        <IconButton>
            <Info />
        </IconButton>
    );
    return (
        <style.UiLegendContent data-testid='UiLegendContent'>
            {React.cloneElement(RootElement, {
                onClick: props.openLegend
            })}
            <style.Legend
                open={Boolean(props.anchorEl)}
                anchorEl={props.anchorEl}
                onClose={() => {
                    props.closeLegend();
                    props.onClose && props.onClose();
                }}
                width={props.width}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Typography variant='body1' className='legend-header' style={{ display: 'flex', alignItems: 'center' }}>
                    {props?.icon}
                    {props?.title}
                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>{props?.headerElements}</span>
                    <IconButton
                        aria-label='close'
                        size='small'
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                            props.closeLegend();
                            props.onClose && props.onClose();
                        }}
                    >
                        <Close fontSize='inherit' />
                    </IconButton>
                </Typography>
                <div className='legend-body'>{props.content || props.children}</div>
            </style.Legend>
        </style.UiLegendContent>
    );
};
