import React from 'react';
import styled from 'styled-components';

export const UploadLoader = styled.div`
    min-height: 180px;
    width: 100%;
    display: table;
`;

export const LoadingSpinner = styled.div`
    height: 140px;
    margin-top: 40px;
`;

export const Loading = (props): JSX.Element => (
    <UploadLoader className='upload-loader' {...props}>
        <LoadingSpinner className='p-loading-spinner piano-spinner'></LoadingSpinner>
    </UploadLoader>
);
