import { Button } from '@mui/material';
import styled from 'styled-components';

export const ImportOrderContent = styled.div``;

export const ContainerMessages = styled.div`
    display: table;
    height: 340px;
    width: 100%;
`;

export const textColor = styled.div`
    color: #f06292;
`;

export const BodyMessages = styled.div`
    text-align: center;
    vertical-align: middle;
    display: table-cell;
`;

export const ButtonCustom = styled(Button)`
    margin-right: 10px !important;
`;
