import { createStyles, makeStyles } from '@mui/styles';
import styled, { createGlobalStyle } from 'styled-components';
import { ThemeType } from '../../styled/theme.type';

export const RolesBody = createGlobalStyle`
    .active{
        background: ${({ theme }: ThemeType) => theme.colors.menu.subMenu.background} !important;
    }
    .menuListPermissions{
        background: ${({ theme }: ThemeType) => theme.colors.menu.background} !important;
        margin: 2px;
    }
`;

export const RolesContent = styled.div``;
export const LisPageContent = styled.div`
    background-color: ${({ theme }: ThemeType) => theme.colors.menu.subMenu.background} !important;
    padding: 0px 15px;
    margin-top: -3px;
    margin-left: 3px;
`;

export const useStyles = makeStyles(() =>
    createStyles({
        table: {
            minWidth: 750,
            tableLayout: 'fixed',
            '& .MuiTableCell-head': {
                textTransform: 'capitalize',
                fontWeight: 'bold'
            },
            '& .MuiTableCell-root': {
                padding: '7px',
                borderBottom: 'none'
            }
        }
    })
);
