import React, { useCallback, useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import * as type from './ShippingOrderFiles.type';
import * as view from './ShippingOrderFiles.view';
import * as ShippingOrderState from '../../../states/component/ShippingOrder';
import Translator from '../../../states/global/Translator';
import { Success } from '../../Popup/Popup';
import ApiShippingOrderFiles from '../../../api/ApiShippingOrderFiles';
import { FromUTCToTimezone } from '../../../helpers/Converters';
import ActionsButtonsDataTable from '../../ActionsButtonsDataTable/ActionsButtonsDataTable';
import ButtonDownloadFile from '../../ButtonDownloadFile/ButtonDownloadFile';
import { popUpConfirm } from '../../../helpers/PopUpConfirm';
// import module

const api = new ApiShippingOrderFiles();

const ShippingOrderFiles: React.FC<type.ShippingOrderFilesProps> = (props) => {
    const Trans = useRecoilValue(Translator);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const [loading, setLoading] = useState<boolean>(true);
    const [shippingOrderFiles, setShippingOrderFiles] = useRecoilState(ShippingOrderState.ShippingOrderFiles);
    const formschemaNewStatus = Yup.object().shape({});

    const getDatas = useCallback(async () => {
        setLoading(true);
        const { data }: any = await api.getGeneral(`stock/shipping-order/${props.shippingOrderId}/files`);

        setShippingOrderFiles(data.length ? data : []);
        setLoading(false);
    }, [setShippingOrderFiles, props.shippingOrderId]);

    const confirmDeleteRow = (row) => {
        const filename = (row?.s3Key || '').split('/').pop();

        popUpConfirm(
            Trans('messages.t.file') + `: ${filename}`,
            Trans('messages.p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );
    };

    const deleteRow = async (onClose, row: CellProps): Promise<void> => {
        try {
            await api.remove(row.id);
            getDatas();
            onClose();
            Success({ text: `${Trans('messages.t.file_successfully_removed')} [ID ${row.id}]` });
        } catch (error) {
            console.warn('Error to sending data', error);
        }
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSubmitUploadFiles = async (event, files: any[], setIsSubmitting): Promise<void> => {
        setIsSubmitting(true);

        try {
            const uploadFiles: any[] = [];

            for (const file of files) {
                const base64 = await getBase64(file);

                uploadFiles.push({
                    name: file.name,
                    base64: base64
                });
            }

            await api.createGeneral(`stock/shipping-order/${props.shippingOrderId}/files`, {
                files: uploadFiles
            });

            getDatas();
            Success({ text: `${Trans('messages.t.file_successfully_uploaded')}` });
        } catch (error) {
            console.warn('Error to sending data', error);
        }

        setIsSubmitting(false);
    };

    const ActionsButtons = (original: CellProps): JSX.Element => {
        return (
            <>
                <ButtonDownloadFile
                    defaultFileName={original.s3Key.split('/').pop()}
                    api={() => api.download(`${original.id}`)}
                    buttonName={Trans('messages.t.download_file')}
                />
                <Typography className={'dataTableActionsBtn danger'} onClick={() => confirmDeleteRow(original)}>
                    <Delete />
                    <span>{Trans('messages.t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns = [
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            disableGroupBy: true,
            disableSortBy: true,
            width: 20,
            minWidth: 20,
            maxWidth: 20,
            Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        },
        {
            Header: Trans('messages.t.created_at'),
            accessor: 'createdAt',
            disableGroupBy: true,
            aggregate: 'count',
            width: 30,
            canFilters: true,
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false)
        },
        {
            Header: Trans('messages.t.name'),
            accessor: 's3Key',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row }: CellProps) => (row.original?.s3Key || '').split('/').pop()
        }
    ];

    useEffect(() => {
        !shippingOrderFiles.length && getDatas();
        return () => {
            setShippingOrderFiles([]);
        };
    }, [getDatas]);

    return (
        <>
            <view.ShippingOrderFilesContent
                data-testid={'ShippingOrderFiles-testid'}
                columns={columns}
                handleSubmit={() => false}
                handleSubmitUploadFiles={handleSubmitUploadFiles}
                formschema={formschemaNewStatus}
                shippingOrder={props.shippingOrder}
                shippingOrderFiles={shippingOrderFiles}
                {...props}
                loading={loading}
            />
        </>
    );
};

export default ShippingOrderFiles;
