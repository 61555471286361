import { makeStyles } from '@mui/styles';
import styled, { createGlobalStyle } from 'styled-components';

export const ShipmentAcceptancePublicContent = styled.div``;

export const TitleLocation = styled.div`
    font-size: 16px;
    @media (min-width: 960px) {
        text-align: right;
    }
`;

export const ContainerHeaderLocation = styled.div`
    padding: 10px;
    background-color: rgb(45, 134, 61);
    color: aliceblue;
    border-radius: 5px;
`;

export const TitleHeaderTable = styled.strong`
    padding-left: 230px;
`;

export const ShippingOrderAcceptanPubliccebody = createGlobalStyle`
.value_form_shippingorder_details{
    font-size: 16px;
    color: #7d7d7d;
    border-bottom: thin solid #7d7d7d;
    display: block;
  }
.dataHeaderAcceptance{
    display: flex;

}
@media (max-width: 768px) {
    .dataHeaderAcceptance{
        display: block;
    
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    menuPaper: {
        maxHeight: 207,
        margin: 0
    }
}));
