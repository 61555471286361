import Grid from '@mui/material/Grid';
import React, { Suspense } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as style from './Layout.style';
import * as type from './Layout.type';
import routes from '../../config/routes';
import * as UserState from '../../states/global/User';
import ErrorPage404 from '../ErrorPage404/ErrorPage404';
import MainMenu from '../MainMenu';
import TopBar from '../TopBar';
import { Loading } from '../../helpers/Loading';
import { TripOriginRounded } from '@mui/icons-material';

export const LayoutContent: React.FC<type.LayoutContent> = (props): JSX.Element => {
    const userPermissions = useRecoilValue(UserState.Permissions);
    const dataUser = useRecoilValue(UserState.User);

    return dataUser === null ? (
        <Loading style={{ height: '100vh' }} />
    ) : (
        <style.LayoutContent data-testid='LayoutContent'>
            <TopBar title='t.stock_control' setShowMainMenu={props.setShowMainMenu}>
                a
            </TopBar>
            <MainMenu
                location={props.location}
                showMainMenu={props.showMainMenu}
                setShowMainMenu={props.setShowMainMenu}
            ></MainMenu>
            <style.mainBodyApp>
                <Grid container justifyContent='space-around' direction='row'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Suspense fallback={Loading}>
                            <Switch>
                                {!userPermissions || !userPermissions.length || !dataUser.userLocationAccess.length ? (
                                    <Redirect to='/unauthorized' />
                                ) : (
                                    routes.map((route, idx) => {
                                        let isAccess = !route.hasOwnProperty('allowedroles') ? true : false;
                                        let checkPath = true;

                                        if (props.location)
                                            checkPath = routes.find((ro) => ro.path === props.location?.pathname)
                                                ? true
                                                : false;

                                        if (!checkPath) return <Redirect to='/error-page404' />;

                                        if (route.allowedroles)
                                            isAccess = route.allowedroles.find(
                                                (role) =>
                                                    role === userPermissions?.find((r) => r === role) ||
                                                    'ID_' + dataUser?.stockRole?.id == role
                                            )
                                                ? true
                                                : false;

                                        if (isAccess && route.component)
                                            return (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    render={(props: RouteComponentProps) => (
                                                        <route.component
                                                            name={route.name}
                                                            {...props}
                                                            {...route.props}
                                                        />
                                                    )}
                                                />
                                            );
                                        else
                                            return (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    render={(props: RouteComponentProps) => (
                                                        <ErrorPage404
                                                            name='Error Page 404'
                                                            {...props}
                                                            {...route.props}
                                                        />
                                                    )}
                                                />
                                            );
                                    })
                                )}
                            </Switch>
                        </Suspense>
                    </Grid>
                </Grid>
            </style.mainBodyApp>
        </style.LayoutContent>
    );
};
