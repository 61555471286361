import React, { useState } from 'react';
import { LocationAccesWithPagnator } from '../../models/ApiStockLocation.type';
import { SelectAsyncPaginate } from '../FormElements';
import { LoadMoreLocation, StockLocationDropdownProps } from './StockLocationDropdown.type';
import { ApiStockLocationByIdUser } from '../../api/ApiStockLocation';
import { Location } from '../../types/common';
import { User } from '../../states/global/User';
import { useRecoilValue } from 'recoil';
import { FullDataUser } from '../User/User.type';
import { OrderAlphabetically } from '../../helpers/converter';
// import module

const StockLocationDropdown: React.FC<StockLocationDropdownProps> = (props): JSX.Element => {
    const apiStockLocationByIdUser = new ApiStockLocationByIdUser();
    const [stockLocations, setStockLocations] = useState<Location[]>([]);
    const userState = useRecoilValue<FullDataUser | null>(User);

    const loadMoreStockLocation = async (search, _, { page }): Promise<LoadMoreLocation> => {
        const extraFiltering = [
            { id: 'active', value: '1' },
            { id: 'locationName', value: search }
        ];

        if (props.params) {
            for (const key in props.params) {
                extraFiltering.push({
                    id: key,
                    value: props.params[key]
                });
            }
        }

        const { items }: LocationAccesWithPagnator = await apiStockLocationByIdUser.getWithPaginationByUser(
            page,
            10,
            // [{ id: 'locationName', desc: false }], // await by BE
            '',
            '',
            '',
            extraFiltering,
            userState ? userState.id : 0
        );

        let selectAsyncStockLocation: Location[] = [];
        selectAsyncStockLocation = items.map((item) => {
            return {
                id: item.location.id,
                name: item.location.name,
                email: item.location.email
            };
        });

        if (items && !!items.length) {
            // cambiar order ASC
            setStockLocations(OrderAlphabetically<Location>(selectAsyncStockLocation, 'name'));
        }

        return {
            options: selectAsyncStockLocation,
            hasMore: selectAsyncStockLocation.length >= 1,
            additional: { page: page + 1 }
        };
    };

    return (
        <SelectAsyncPaginate
            name={props.name}
            label={props.label}
            value={props.value}
            error={props.error}
            options={stockLocations}
            onChange={props.onChange}
            required={props.required}
            loadOptions={loadMoreStockLocation}
            textRequired={props.textRequired}
            lg={props.lg ?? 6}
            md={props.md ?? 6}
            sm={props.sm ?? 6}
            xs={props.xs ?? 12}
        />
    );
};

export default StockLocationDropdown;
