import styled from 'styled-components';
import LoginPage from '../../assets/images/login_page_stock_control.png';

export const ResetPasswordContent = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    background-image: url('${LoginPage}');
    background-size: cover;
    background-repeat: no-repeat, repeat;
    background-position: center;

    .MuiCardContent-root {
        padding: 0 !important;
    }
    .card-login {
        width: 500px;
        // transform: translate(-50%, -50%);
        // margin-left: 2%;
        padding: 5% 3% 3% 2%;
        margin: 0 auto;
    }
    .divider-logo {
        width: 40%;
        margin: auto;
        height: 2px;
        background-color: #ec4425;
        border-radius: 10px;
    }
    .logo-stock {
        margin: 0 auto;
        text-align: center;
    }
    .logo-login {
        width: 60%;
        display: block;
        margin: 0 auto;
        margin-bottom: 1%;
    }
    .image-login {
        width: 100%;
        height: 100%;
    }
    .MuiPaper-elevation1 {
        box-shadow: 0px 3px 5px 10px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 4%),
            0px 1px 18px 0px rgb(0 0 0 / 4%) !important;
    }
    .login-containers {
        display: flex;
        align-items: center;
        margin-top: 5%;
        width: 100%;
    }
`;
