import React from 'react';
import { OrderListContent } from './OrderList.view';
import { OrderListProps } from './OrderList.type';
import Wrapper from '../../helpers/wrapper';
// import module

const OrderList: React.FC<OrderListProps> = () => {
    return <OrderListContent data-testid='OrderList-testid' />;
};

export default Wrapper(OrderList);
