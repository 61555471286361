import React from 'react';
import ItemsReport from '../../components/ItemsReport/ItemsReport';
import * as style from './ItemsReport.style';
import * as type from './ItemsReport.type';
// import module

export const ItemsReportContent: React.FC<type.ItemsReportProps> = () => (
    <style.ItemsReportContent data-testid='ItemsReportContent'>
        <ItemsReport />
    </style.ItemsReportContent>
);
