import PortalApi from '../helpers/api/portalApi';
import { Model } from '../models/ApiShippingOrderFiles.type';

export default class ApiShippingOrderFiles extends PortalApi<Model> {
    route = 'stock/shipping-order-file';

    download = async (route): Promise<{ response: Blob; fileName: string }> => {
        const params: { [key: string]: string | number } = {};
        params.responseType = 'blob';
        return await this.getDowloadFile(`/${route}/download`, params);
    };
}
