import React, { useState } from 'react';
import * as view from './LocationDropDown.view';
import * as type from './LocationDropDown.type';
import Wrapper from '../../helpers/wrapper';
import { IStockLocation, LocationWithPagnator } from '../../models/ApiStockLocation.type';
import ApiStockLocation from '../../api/ApiStockLocation';
import { LoadMore } from './LocationDropDown.type';
import { OrderAlphabetically } from '../../helpers/converter';
// import module

const apiStockLocation = new ApiStockLocation();

const LocationDropDown: React.FC<type.LocationDropDownProps> = (props) => {
    const [dataLocations, setDataLocations] = useState<IStockLocation[]>([]);

    const loadMoreLocation = async (search, _, { page }): Promise<LoadMore> => {
        const { items }: LocationWithPagnator = await apiStockLocation.getWithPagination(
            page,
            10,
            '',
            '',
            search,
            {},
            props.extendUrl ?? ''
        );

        let newItemsList: IStockLocation[] = items;
        if (newItemsList && !!newItemsList.length) {
            newItemsList = OrderAlphabetically<IStockLocation>(items, 'name');
            if (props.hideOption) {
                newItemsList = newItemsList.filter((locationFrom) => locationFrom.id !== props.hideOption);
            }

            for (let i = 0; i < newItemsList.length; i++) {
                if (newItemsList[i].locationType?.name) {
                    newItemsList[i].name = newItemsList[i].name + ' [' + newItemsList[i].locationType?.name + ']';
                }
            }

            setDataLocations(newItemsList);
        }

        return {
            options: newItemsList,
            hasMore: newItemsList.length >= 1,
            additional: { page: page + 1 }
        };
    };

    return (
        <view.LocationDropDownContent
            data-testid='LocationDropDown-testid'
            loadMoreLocation={loadMoreLocation}
            {...props}
            locationList={
                props.hideOption
                    ? dataLocations.filter((locationFrom) => locationFrom.id !== props.hideOption)
                    : dataLocations
            }
        />
    );
};

export default Wrapper(LocationDropDown);
