import React from 'react';
import { SkuContainer } from './Sku.style';
import Sku from '../../components/Sku/Sku';
// import module

export const SkuContent: React.FC = (): JSX.Element => (
    <SkuContainer data-testid='SkuContent'>
        <Sku />
    </SkuContainer>
);
