import React from 'react';
import { GenericItemsContent } from './GenericItems.view';
import { GenericItemsProps } from './GenericItems.type';
import Wrapper from '../../helpers/wrapper';
// import module

const GenericItems: React.FC<GenericItemsProps> = () => {
    return <GenericItemsContent data-testid='GenericItems-testid' />;
};

export default Wrapper(GenericItems);
