import React, { useState } from 'react';
import { ResolveDisputeContent } from './ResolveDispute.style';
import { ResolveDisputeViewProps } from './ResolveDispute.type';
import { Button, CircularProgress } from '@mui/material';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import UiModal from '../../Ui/UiModal/UiModal';
import { Loading } from '../../../helpers/Loading';
import { ResolveDisputeFormik } from './ResolveDispute.formik';
// import module

export const ResolveDisputeView: React.FC<ResolveDisputeViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <ResolveDisputeContent data-testid='ResolveDisputeContent'>
            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                title={`
                    ${Trans('messages.t.set_shipment_status')} - ${Trans('messages.t.order_id')} 
                    #${props.shippingOrderId}`}
                content={
                    !props.shippingOrderData ? (
                        <Loading />
                    ) : (
                        <ResolveDisputeFormik
                            valuesInitForm={props.valuesInitForm}
                            handleSubmit={props.handleSubmit}
                            bindSubmitForm={bindSubmitForm}
                            setIsSubmitting={setIsSubmitting}
                            shippingOrder={props.shippingOrder}
                        />
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {Trans('messages.t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : Trans('messages.t.save')}
                        </Button>
                    </>
                }
            />
        </ResolveDisputeContent>
    );
};
