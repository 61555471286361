import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './ImportOrder.type';
import * as view from './ImportOrder.view';
import ApiImportOrder from '../../api/ApiImportOrder';
import { Errors, Success, Warning } from '../../components/Popup/Popup';
import ShippingOrderDetails from '../../components/ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import Wrapper from '../../helpers/wrapper';
import Translator from '../../states/global/Translator';
import EditShippingOrder from '../ShippingOrder/EditShippingOrder/EditShippingOrder';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
// import module

const api = new ApiImportOrder();

const ImportOrder: React.FC<type.ImportOrderProps> = (): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const [openModalShippingDetails, setOpenModalShippingDetails] = useState<boolean>(false);
    const [openModalShippingEdit, setOpenModalShippingEdit] = useState<boolean>(false);
    const [shippingOrderId, setshippingOrderId] = useState<null | number>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [notImportedReason, setnotImportedReason] = useState<string>('');
    const importStatus = {
        SelectJSON: 1,
        ProcessingJSON: 2,
        SuccessfullyOrder: 3,
        ErrorCreatingOrder: 4,
        ShippingOrderExist: 5
    };
    const [statusImport, setStatusImport] = useState<number>(importStatus.SelectJSON);

    const handleClose = (): void => {
        setOpenModalShippingDetails(false);
        setOpenModalShippingEdit(false);
    };

    const uploadNewFile = () => {
        setStatusImport(importStatus.SelectJSON);
        setshippingOrderId(null);
    };

    const reuploadNewFile = async (onClose, props: type.ReimportProps): Promise<void> => {
        props.formData.append('reassign', 'true');

        upload(props.formData, props.resetForm, props.setSubmitting);
        onClose();
    };

    const handleSubmit = async (e: any, resetForm: any, setSubmitting) => {
        if (!e.order || !e.order.length) {
            Warning({ text: Trans('messages.t.you_must_select_the_json_file') });
            throw false;
        }

        const formData = new FormData();
        formData.append('sensors', e.order[0]);

        upload(formData, resetForm, setSubmitting);
    };

    const upload = async (formData, resetForm: any, setSubmitting): Promise<void> => {
        try {
            setStatusImport(importStatus.ProcessingJSON);

            const {
                data: { shipping_orders, sensors }
            }: any = await api.createGeneral('stock/import', formData);

            if (!shipping_orders) {
                throw shipping_orders;
            }

            /*this should setup opening modal window after order where user can decide if he want to edit order or see detail or import another order, and it is not working at 25.03.2024
    
    also I'm seeeing ISSUE there if the order contains existing sensor we will ask user what to do with that  'Do you want to move sensors to this shipment?',
    and after submitting you are not setinng order  status to : setStatusImport(importStatus.SuccessfullyOrder); */
            if (shipping_orders.created.length) {
                Success({ text: Trans('messages.t.order_imported_successfully') });
                setStatusImport(importStatus.SuccessfullyOrder);
                setshippingOrderId(shipping_orders.created[0].id);
            }

            if (shipping_orders.existing.length) {
                Errors({ text: Trans('messages.t.shipping_order_exist') });
                setStatusImport(importStatus.ShippingOrderExist);
            }

            if (shipping_orders.not_imported.length) {
                Errors({ text: Trans('messages.t.error_importing_order') });
                setStatusImport(importStatus.ErrorCreatingOrder);
                setnotImportedReason(shipping_orders.not_imported[0].message);
            }

            if (sensors?.validation?.length) {
                resetForm({});
                setSubmitting(false);
                setStatusImport(importStatus.SelectJSON);

                popUpConfirm(
                    'Do you want to move sensors to this shipment?',
                    sensors.validation.join(', '),
                    reuploadNewFile,
                    {
                        formData,
                        setSubmitting,
                        resetForm
                    }
                );

                return;
            }

            resetForm({});
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
            resetForm({});
            setStatusImport(importStatus.SelectJSON);
        }
    };

    return (
        <>
            {openModalShippingDetails && (
                <ShippingOrderDetails
                    open={openModalShippingDetails}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}
            {openModalShippingEdit && (
                <EditShippingOrder
                    open={openModalShippingEdit}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                    setRefresh={setRefresh}
                />
            )}
            <view.ImportOrderContent
                data-testid={'ImportOrder-testid'}
                valuesInitForm={{ order: [] }}
                handleSubmit={handleSubmit}
                statusImport={statusImport}
                setStatusImport={setStatusImport}
                setOpenModalShippingDetails={setOpenModalShippingDetails}
                setOpenModalShippingEdit={setOpenModalShippingEdit}
                uploadNewFile={uploadNewFile}
                importStatus={importStatus}
                notImportedReason={notImportedReason}
            />
        </>
    );
};

export default Wrapper(ImportOrder);
