import React from 'react';
import CurrentOrdersOutbound from '../../components/CurrentOrdersOutbound/CurrentOrdersOutbound';
import { CurrentOrdersOutboundContainer } from './CurrentOrdersOutbound.style';
// import module

export const CurrentOrdersOutboundContent: React.FC = (): JSX.Element => (
    <CurrentOrdersOutboundContainer data-testid='CurrentOrdersOutboundContent'>
        <CurrentOrdersOutbound />
    </CurrentOrdersOutboundContainer>
);
