import React, { useState } from 'react';
import { LoadMoreShipper, ShipperDropDownProps } from './ShipperDropDown.type';
import { SelectAsyncPaginate } from '../FormElements/index';
import ApiShipper from '../../api/ApiShipper';
import { ModelShipper, ShipperResponse } from '../../models/ApiShipper.type';
import { OrderAlphabetically } from '../../helpers/converter';
// import module

const ShipperDropDown: React.FC<ShipperDropDownProps> = (props): JSX.Element => {
    const apiShipper = new ApiShipper();
    const [shippers, setShippers] = useState<ModelShipper[]>([]);

    const getShippers = async (search, _, { page }): Promise<LoadMoreShipper> => {
        const { items }: ShipperResponse = await apiShipper.getWithPaginationDropDown(
            page,
            10,
            '',
            '',
            search,
            props.paramsUrl,
            ''
        );

        if (items && !!items.length) {
            setShippers(OrderAlphabetically<ModelShipper>(items, 'name'));
        }

        return {
            options: items,
            hasMore: items.length >= 1,
            additional: { page: page + 1 }
        };
    };

    return (
        <SelectAsyncPaginate
            name={props.name}
            label={props.label}
            value={props.value}
            error={props.error}
            options={shippers}
            onChange={props.onChange}
            required={props.required}
            loadOptions={getShippers}
            textRequired={props.textRequired}
            lg={props.lg ?? 6}
            md={props.md ?? 6}
            sm={props.sm ?? 6}
            xs={props.xs ?? 12}
        />
    );
};

export default ShipperDropDown;
