import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const getFullScreenModal = (screen = 'md'): boolean => {
    const theme = useTheme();
    let sc = 'md';
    switch (screen) {
        case 'md':
            sc = theme.breakpoints.down('md');
            break;
        case 'sm':
            sc = theme.breakpoints.down('sm');
            break;
        case 'xs':
            sc = theme.breakpoints.down('xs');
            break;
        case 'lg':
            sc = theme.breakpoints.down('lg');
            break;
        default:
            sc = theme.breakpoints.down('md');
            break;
    }
    return useMediaQuery(sc);
};
