import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { ModelSku, SkuResponse } from '../models/ApiSku.type';

export default class ApiSku extends PortalApi<ModelSku> {
    route = 'stock/sku';

    getAll = async (page, limit, order, filter): Promise<SkuResponse> => {
        return await this.getTable<ModelSku>('', '/v1', formatParametersToGetPagination(page, limit, order, filter));
    };

    getByLocation = async (prefix, page, limit, order, filter): Promise<SkuResponse> => {
        return await this.getTable<ModelSku>(
            '/location' + prefix,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getWithPaginationDropDown = async (
        page,
        limit,
        order,
        filter,
        search,
        extraFilter,
        extendUrl = ''
    ): Promise<SkuResponse> => {
        return await this.getTable<ModelSku>(
            extendUrl,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter, search, extraFilter)
        );
    };

    getImageUrl = (original) => {
        return this.buildAbsoluteUrl('/v1/stock/public/sku/' + original.id + '/image');
    };

    getSpecificationUrl = (original) => {
        return this.buildAbsoluteUrl('/v1/stock/public/sku/' + original.id + '/specification');
    };
}
