import { atom } from 'recoil';

export const CurrentOrders = atom({
    key: 'current_orders_atom',
    default: []
});

export const OrderList = atom({
    key: 'orders_atom',
    default: []
});

export const CurrentOrdersInbound = atom({
    key: 'current_ordersInbound_atom',
    default: []
});

export const CurrentOrdersOutbound = atom({
    key: 'current_ordersOutbound_atom',
    default: []
});
