import React from 'react';
// import CustomerAddress from '../../components/CustomerAddress/CustomerAddress';
import CustomerAddress from '../../components/CustomerAddress/CustomerAddress';
import { CustomerAddressContainer } from './CustomerAddress.style';
// import module

export const CustomerAddressContent: React.FC = (): JSX.Element => (
    <CustomerAddressContainer data-testid='CustomerAddressContent'>
        <CustomerAddress />
    </CustomerAddressContainer>
);
