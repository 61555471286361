import { useCallback, useState } from 'react';

export const setToLSUser = (path, value): void | boolean => {
    const user = window.localStorage.getItem('userId');
    let userId = 0;

    if (user) {
        userId = JSON.parse(user);
    } else {
        return false;
    }
    const dataFromLocalStorage: string | null = window.localStorage.getItem(`${userId}`);
    const userSettings = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : {};

    let schema = userSettings;
    const pathList: string[] = path.split('.');
    const pathListLength: number = pathList.length;
    for (let i = 0; i < pathListLength - 1; i++) {
        const elem: string = pathList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pathList[pathListLength - 1]] = value;

    window.localStorage.setItem(`${userId}`, JSON.stringify(userSettings));
};

export function getLSUser<T>(key: string): T | undefined {
    const user = window.localStorage.getItem('userId');
    let userId = '';
    if (user) {
        userId = user;
    } else {
        return undefined;
    }

    if (userId) {
        const getValue: string | null = window.localStorage.getItem(userId);
        let value = {};

        if (getValue) {
            value = JSON.parse(getValue);
        }

        let customSettings: undefined | T = undefined;
        const settings = key.split('.');
        const settingsToSave = settings.reduce((previousValue: object, currentValue: string, index: number) => {
            if (!previousValue) {
                return;
            }
            if (previousValue[currentValue]) {
                if (index === settings.length - 1) {
                    return [currentValue, previousValue[currentValue]];
                }
                return previousValue[currentValue];
            }
            return [currentValue, null];
        }, value);
        if (settingsToSave) {
            customSettings = settingsToSave[1];
        }
        return customSettings;
    }
}

export const setToLocalStorage = (key, value): void => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export function getFromLocalStorage<T>(key: string): T | undefined {
    const value: string | null = window.localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn(error);
            return initialValue;
        }
    });

    const setValue: (value: T) => void = useCallback(
        (value: T) => {
            try {
                setStoredValue(value);
                window.localStorage.setItem(key, JSON.stringify(value));
            } catch (error) {
                console.warn(error);
            }
        },
        [key]
    );

    return [storedValue, setValue];
}
