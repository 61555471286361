import React, { useEffect, useState } from 'react';
import { ResolveDisputeView } from './ResolveDispute.view';
import { IRelsolveDisputeForm, ResolveDisputeProps } from './ResolveDispute.type';
import Wrapper from '../../../helpers/wrapper';
import { Success } from '../../Popup/Popup';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import ApiShippingOrders from '../../../api/ApiShippingOrders';
import { Model } from '../../../models/ApiShippingOrders.type';
import { ShippingStatusHistory } from '../../../models/ApiShippingStatusHistory.type';
// import module

const ResolveDispute: React.FC<ResolveDisputeProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const api = new ApiShippingOrders();
    const [shippingOrder, setShippingOrder] = useState<ShippingStatusHistory[] | null>(null);

    const [valuesInitForm, setvaluesInitForm] = useState<IRelsolveDisputeForm>({
        shippingOrderStatus: '9',
        comment: ''
    });

    const getOrderStatusHistory = async () => {
        try {
            const { data }: { data: ShippingStatusHistory[] } = await api.getGeneral(
                `stock/shipping-order-status-history/shipping-order/${props.shippingOrderId}`
            );

            if (!data) {
                throw data;
            }
            if (data && !!data.length) {
                const comment: ShippingStatusHistory | undefined = data.pop();
                setvaluesInitForm({
                    shippingOrderStatus: '9',
                    comment: comment?.comment || ''
                });
            }
            setShippingOrder(data);
        } catch (error) {
            console.warn(error);
        }
    };

    const handleSubmit = async (
        e: IRelsolveDisputeForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting: (e: boolean) => void
    ) => {
        try {
            // e
            if (e.shippingOrderStatus == '3' && !e.statusAt) {
                e.statusAt = new Date().toISOString();
            }

            const { data }: Model = await api.patchGeneralFree(`stock/shipping-order/${props.shippingOrderId}`, e);
            if (!data?.shippingOrder) {
                throw data?.shippingOrder;
            }

            setvaluesInitForm({ shippingOrderStatus: '', comment: '' });

            Success({
                text:
                    Trans('messages.t.shipping_order_status_updated_successfully') + ` [ID ${data?.shippingOrder?.id}]`
            });

            if (data?.shippingOrder?.shippingOrderStatus?.id !== 3) {
                props.setRefresh(true);
            }

            resetForm({});
            setSubmitting(false);
            props.closeModal();
        } catch (error) {
            setSubmitting(false);
            console.warn('Error ', error);
        }
    };

    useEffect(() => {
        props.shippingOrderId && getOrderStatusHistory();
    }, [props.shippingOrderId]);

    return (
        <ResolveDisputeView
            data-testid='ResolveDispute-testid'
            open={props.open}
            closeModal={props.closeModal}
            shippingOrder={props.shippingOrder}
            handleSubmit={handleSubmit}
            valuesInitForm={valuesInitForm}
            shippingOrderId={props.shippingOrderId}
            shippingOrderData={shippingOrder}
        />
    );
};

export default Wrapper(ResolveDispute);
