import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import * as Yup from 'yup';
import { IRoleAssigningForm, RoleAssigningFormik as RoleAssigningFormikType } from './Roles.type';

import {
    Collapse,
    List,
    ListItem,
    ListItemText,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import * as style from './Roles.style';
import { toCamelCaseAndRemove_ } from '../../helpers/converter';

export const RoleAssigningFormik: React.FC<RoleAssigningFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const classes = style.useStyles();

    const Formschema = Yup.object({
        stock_permission: Yup.string()
    });

    return (
        <Formik
            initialValues={{ stock_permission: 0, role: 0 }}
            onSubmit={(values: IRoleAssigningForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<IRoleAssigningForm>) => {
                const { isSubmitting, submitForm } = propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <Grid container justifyContent='space-around' direction='row'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className='text-center'>
                                    <h3>
                                        {Trans('messages.t.role')}:
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                color: '#7b7b7b',
                                                fontSize: '16px'
                                            }}
                                        >
                                            {props.role ? props.role.name : ''}
                                        </span>
                                    </h3>
                                </div>
                            </Grid>

                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <List className='nav_bar' component='nav' aria-labelledby='nested-list-subheader'>
                                    {!!props.stock_permissions.length &&
                                        props.stock_permissions.map((item, index) => {
                                            return (
                                                <>
                                                    <ListItem
                                                        style={{
                                                            borderRadius: '5px',
                                                            marginBottom: '2px'
                                                        }}
                                                        key={`${index}_ListItem`}
                                                        className={
                                                            index === props.selectedIndex
                                                                ? 'active menuListPermissions'
                                                                : 'menuListPermissions'
                                                        }
                                                        button
                                                        onClick={() => props.openManuPermission(index)}
                                                    >
                                                        <ListItemText primary={toCamelCaseAndRemove_(item.name)} />
                                                        {index === props.selectedIndex ? (
                                                            <ExpandLess />
                                                        ) : (
                                                            <ExpandMore />
                                                        )}
                                                    </ListItem>
                                                    <Collapse
                                                        in={index === props.selectedIndex}
                                                        timeout='auto'
                                                        unmountOnExit
                                                    >
                                                        <style.LisPageContent>
                                                            <Table aria-label='simple table' className={classes.table}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align='center'>
                                                                            {Trans('messages.t.permissions')}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {Trans('messages.t.enabled')}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {Trans('messages.t.description')}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {item.userPermission.map((row) => (
                                                                        <TableRow key={row.name}>
                                                                            <TableCell align='center'>
                                                                                {row.name}
                                                                            </TableCell>
                                                                            <TableCell align='center'>
                                                                                <Switch
                                                                                    checked={row.enabled}
                                                                                    onChange={(e) => {
                                                                                        props.enabledPermission(
                                                                                            item,
                                                                                            row,
                                                                                            e
                                                                                        );
                                                                                    }}
                                                                                    value={row.enabled}
                                                                                    color='primary'
                                                                                    inputProps={{
                                                                                        'aria-label':
                                                                                            'secondary checkbox',
                                                                                        color: '#2acb18'
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align='center'>
                                                                                {item.description}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </style.LisPageContent>
                                                    </Collapse>
                                                </>
                                            );
                                        })}
                                </List>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
