import React from 'react';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, SkuPriceFormik as SkuPriceFormikType } from './SkuPrices.type';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import { InputElement } from '../FormElements';
import Translator from '../../states/global/Translator';

export const SkuPriceFormik: React.FC<SkuPriceFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const Formschema = Yup.object({
        shippingPriceUsd: Yup.number()
            .typeError(Trans('messages.t.price_must_be_number'))
            .required(Trans('messages.p.this_field_is_required'))
            .moreThan(0, Trans('messages.p.price_must_be_greater_than_0'))
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, handleChange, handleBlur, values, isSubmitting, submitForm } = propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='inicial' direction='row'>
                                <InputElement
                                    label={Trans('messages.t.price_in_usd')}
                                    name='shippingPriceUsd'
                                    value={values.shippingPriceUsd}
                                    error={errors.shippingPriceUsd}
                                    touched={touched.shippingPriceUsd}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
