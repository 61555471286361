import { atom, selector } from 'recoil';
import Translator from '../../api/Translator';
import { getCookie } from '../../helpers/cookie';

export const Lang = atom({
    key: 'StateGlobalTranslator-language',
    default: getCookie('language') && getCookie('language') !== 'null' ? getCookie('language') : 'en_GB'
});

const TranslatorData = selector({
    key: 'StateGlobalTranslator-translator',
    get: async ({ get }) => {
        const lang = get(Lang);
        const Trans = new Translator(lang);
        const response = await Trans.get(lang);
        return response;
    }
});

const Translate = selector({
    key: 'StateGlobalTranslator-translate',
    get: ({ get }) => {
        const data = get(TranslatorData);

        return (key: string, params?: Array<string>) => {
            const path = key.split('.');
            let value = path.reduce((transObj: string, currentKey: string) => {
                if (transObj[currentKey]) {
                    return transObj[currentKey];
                }
                return path[1] + '.' + currentKey;
            }, data);

            for (const param in params) {
                value = value.replace(`%${param}%`, params[param]);
            }

            return value;
        };
    }
});

export default Translate;
