import React from 'react';
import { UiModalView } from './UiModal.view';
import { UiModalProps } from './UiModal.type';
import Wrapper from '../../../helpers/wrapper';
// import module

const UiModal: React.FC<UiModalProps> = ({
    open,
    closeModal,
    title,
    content,
    buttons,
    minHeight,
    maxHeight,
    maxWidthModal
}): JSX.Element => {
    return (
        <UiModalView
            data-testid='UiModal-testid'
            open={open}
            closeModal={closeModal}
            title={title}
            content={content}
            buttons={buttons}
            minHeight={minHeight}
            maxHeight={maxHeight}
            maxWidthModal={maxWidthModal}
        />
    );
};

export default Wrapper(UiModal);
