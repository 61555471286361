/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    CollapseCover,
    IconChangeLocation,
    LoadingContent,
    LoadingSearchContent,
    LocationLabelCover,
    LocationMenu,
    LocationMenuItem,
    RegionMenuItem,
    SearchMenuItem,
    SearchTextField,
    TitleChangeLocation
} from './ChangeStockLocation.style';
import * as UserStockLocationAccessModel from '../../models/ApiUserStockLocationAccess.type';
import ApiUser from '../../api/ApiUser';
import Pin from '../../assets/images/pin.png';
import Wrapper from '../../helpers/wrapper';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import Translator from '../../states/global/Translator';
import * as UserState from '../../states/global/User';
import { Button, Hidden, Typography, debounce } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    IStockLocation,
    IStockLocationRegion,
    LocationAccesWithDataPagnator
} from '../../models/ApiStockLocation.type';
import { toCamelCaseAndRemove_ } from '../../helpers/converter';
import { useLocalStorage } from '../../helpers/localStorage';
import { FullDataUser } from '../User/User.type';
import CircularProgress from '@mui/material/CircularProgress';
import { LocationParams } from './ChangeStockLocation.type';
import InfiniteScroll from 'react-infinite-scroll-component';
// import module

const api = new ApiUser();

const ChangeStockLocation: React.FC<unknown> = () => {
    const Trans = useRecoilValue(Translator);
    const [stockLocationRegionState, setStockLocationRegionState] = useRecoilState<IStockLocationRegion | null>(
        StockLocationRegion
    );
    const [stockLocationState, setStockLocationState] = useRecoilState<IStockLocation | null>(StockLocation);
    const [currentStockLocation, setCurrentStockLocation] = useState<string | undefined>(
        Trans('messages.t.all_stock_location')
    );
    const [regions, setRegions] = useState<IStockLocation[]>([]);
    const [locations, setLocations] = useState<IStockLocation[]>([]);
    const [searchLocation, setSearchLocation] = useState<string | undefined>();
    const [isLoadingLocations, setIsLoadingLocations] = useState<boolean>(false);
    const [isLoadingBySearch, setIsLoadingBySearch] = useState<boolean>(false);
    const [anchorStockLocation, setAnchorStockLocation] = useState<null | HTMLElement>(null);
    const [selectedRegion, setSelectedRegion] = useState<number | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const user = useRecoilValue<FullDataUser | null>(UserState.User);
    const [changeStockLocation, setChangeStockLocation] = useLocalStorage<IStockLocation>(
        'changeStockLocation:StockLocation',
        {} as IStockLocation
    );
    const [changeStockLocationRegion, setChangeStockLocationRegion] = useLocalStorage<IStockLocationRegion>(
        'changeStockLocation:StockLocationRegion',
        {} as IStockLocationRegion
    );

    const handleMenuClose = () => {
        setAnchorStockLocation(null);
        closeRegions();
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorStockLocation(event.currentTarget);
        setSearchLocation('');
    };

    const openRegion = (regionId: number): void => {
        const updatedRegions = regions.map((region) => {
            if (region.id === regionId) {
                region.open = !region.open;
            } else {
                region.open = false;
            }
            return region;
        });
        setRegions(updatedRegions);
    };

    const closeRegions = (): void => {
        const updatedRegions = regions.map((region) => {
            region.open = false;
            return region;
        });
        setRegions(updatedRegions);
    };

    const fetchDebounced = useCallback(
        debounce((request: string) => {
            if (request.length) {
                getLocations({ name: request }, 10, undefined, true);
            }
        }, 400),
        []
    );

    useEffect(() => {
        if (searchLocation?.length) {
            resetNewRequest();
            setIsLoadingBySearch(true);
            fetchDebounced(searchLocation);
        } else {
            closeRegions();
        }
    }, [searchLocation]);

    const onInputChange = (e) => {
        setSearchLocation(e.target.value);
    };

    const setCurrentStockLocations = (item) => setStockLocationState(item);
    const setCurrentStockLocationRegions = (item) => setStockLocationRegionState(item);

    const fetchMoreData = () => {
        selectedRegion && getLocations({ regionId: selectedRegion }, 5);
    };

    const fetchMoreDataForSearch = () => {
        searchLocation && getLocations({ name: searchLocation }, 10, undefined, true);
    };

    const resetNewRequest = () => {
        setLocations([]);
        setHasMore(false);
        setPage(1);
    };

    const cleanLocation = () => {
        setStockLocationState(null);
        setCurrentStockLocation(Trans('messages.t.all_stock_location'));
        setChangeStockLocation({} as IStockLocation);
    };

    const cleanRegion = () => {
        setStockLocationRegionState(null);
        setCurrentStockLocationRegions('');
        setChangeStockLocationRegion({} as IStockLocation);
    };

    const renderRegionDropdown = useCallback(() => {
        const locationLength = (locations.length || 0) + 1;
        const regionDropdownHeight = locationLength >= 5 ? 130 : (locationLength || 1) * 32 + 2;
        return (
            <LocationMenu
                id='region-dropdown'
                anchorEl={anchorStockLocation}
                keepMounted
                open={Boolean(anchorStockLocation)}
                onClose={handleMenuClose}
            >
                <SearchMenuItem disableRipple={true} disableGutters={true}>
                    <SearchTextField
                        label={Trans('messages.t.location')}
                        value={searchLocation}
                        onChange={onInputChange}
                        variant='outlined'
                        size='small'
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </SearchMenuItem>
                <RegionMenuItem
                    onClick={() => {
                        handleMenuClose();

                        cleanRegion();
                        cleanLocation();
                    }}
                >
                    {Trans('messages.t.all_stock_location')}
                </RegionMenuItem>

                {!searchLocation?.length ? (
                    regions.map((region, index) => {
                        return [
                            <RegionMenuItem
                                key={index}
                                onClick={() => {
                                    openRegion(region.id);
                                    resetNewRequest();
                                    setSelectedRegion(region.id);
                                    region.open && getLocations({ regionId: region.id }, 5, 1);
                                }}
                            >
                                {region.translationString && toCamelCaseAndRemove_(region.translationString)}
                                <span style={{ position: 'absolute', right: '10px' }}>
                                    {region.open ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </RegionMenuItem>,
                            <CollapseCover
                                in={region.open}
                                timeout='auto'
                                height={regionDropdownHeight}
                                key={`collapse-${index}`}
                            >
                                {
                                    <InfiniteScroll
                                        dataLength={locations.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={
                                            <LoadingContent>
                                                <CircularProgress size='1rem' />
                                            </LoadingContent>
                                        }
                                        height={regionDropdownHeight}
                                    >
                                        {!locations.length && (
                                            <LoadingContent>
                                                {isLoadingLocations ? (
                                                    <CircularProgress size='1rem' />
                                                ) : (
                                                    ''
                                                    //<Typography>{Trans('messages.t.there_no_data')}</Typography>
                                                )}
                                            </LoadingContent>
                                        )}
                                        <LocationMenuItem
                                            key={0}
                                            className='subContentMenu'
                                            onClick={() => {
                                                cleanLocation();
                                                handleMenuClose();

                                                const cloneRegion = Object.assign({}, region);
                                                setCurrentStockLocationRegions(cloneRegion);
                                                setChangeStockLocationRegion(cloneRegion);
                                            }}
                                        >
                                            {Trans('messages.t.all_from') + ' '}
                                            {region.translationString &&
                                                toCamelCaseAndRemove_(region.translationString)}
                                        </LocationMenuItem>
                                        {locations.map((location, locationIndex) => {
                                            return (
                                                <LocationMenuItem
                                                    key={locationIndex + 1}
                                                    className='subContentMenu'
                                                    onClick={() => {
                                                        handleMenuClose();
                                                        setCurrentStockLocations(location);
                                                        location && setChangeStockLocation(location);

                                                        cleanRegion();
                                                    }}
                                                >
                                                    {location.name}
                                                </LocationMenuItem>
                                            );
                                        })}
                                    </InfiniteScroll>
                                }
                            </CollapseCover>
                        ];
                    })
                ) : (
                    <InfiniteScroll
                        dataLength={locations.length}
                        next={fetchMoreDataForSearch}
                        hasMore={hasMore}
                        loader={
                            <LoadingContent>
                                <CircularProgress size='1rem' />
                            </LoadingContent>
                        }
                        height={192}
                    >
                        {!locations.length && (
                            <LoadingSearchContent>
                                {isLoadingBySearch ? (
                                    <CircularProgress size='1rem' />
                                ) : (
                                    <Typography>{Trans('messages.t.there_no_data')}</Typography>
                                )}
                            </LoadingSearchContent>
                        )}
                        {locations.map((location, locationIndex) => {
                            return (
                                <LocationMenuItem
                                    key={locationIndex}
                                    className='subContentMenu'
                                    onClick={() => {
                                        handleMenuClose();
                                        setCurrentStockLocations(location);
                                        location && setChangeStockLocation(location);
                                    }}
                                >
                                    {location.name}
                                </LocationMenuItem>
                            );
                        })}
                    </InfiniteScroll>
                )}
            </LocationMenu>
        );
    }, [regions, anchorStockLocation, isLoadingLocations, searchLocation, isLoadingBySearch, locations, hasMore, page]);

    const getRegions = async (): Promise<void> => {
        const { data }: UserStockLocationAccessModel.ModelRegions = await api.getGeneral('region?limit=10');
        setRegions(data.items || []);
    };

    const getLocations = async (
        props: LocationParams,
        limit: number,
        requestedPage?: number,
        isSearch?: boolean
    ): Promise<void> => {
        isSearch ? setIsLoadingBySearch(true) : setIsLoadingLocations(true);
        props.limit = limit;
        props.page = requestedPage || page;
        const { data }: LocationAccesWithDataPagnator = await api.getGeneral(
            `stock/location?type=dropdown`,
            props,
            'v2'
        );
        if (page === 1) {
            setLocations(data.items || []);
        } else {
            setLocations((current) => {
                return current.concat(data.items || []);
            });
        }

        const hasMoreData = data.paginator.page < data.paginator.pageCount;
        if (hasMoreData) {
            setPage((current) => current + 1);
        }
        setHasMore(hasMoreData);

        isSearch ? setIsLoadingBySearch(false) : setIsLoadingLocations(false);
    };

    React.useEffect(() => {
        if (stockLocationState && Object.keys(stockLocationState).length) {
            stockLocationState && setCurrentStockLocation(stockLocationState?.name);
        } else if (stockLocationRegionState && Object.keys(stockLocationRegionState).length) {
            const name =
                Trans('messages.t.all_from') +
                ' ' +
                toCamelCaseAndRemove_(stockLocationRegionState?.translationString || '');

            stockLocationRegionState && setCurrentStockLocation(name);
        } else {
            setCurrentStockLocation(Trans('messages.t.all_stock_location'));
        }
    }, [stockLocationState, stockLocationRegionState, setCurrentStockLocation, setCurrentStockLocationRegions]);

    React.useEffect(() => {
        if (!!Object.keys(changeStockLocation).length && !stockLocationState)
            setStockLocationState(changeStockLocation);
    }, [setStockLocationState]);

    React.useEffect(() => {
        if (!!Object.keys(changeStockLocationRegion).length && !stockLocationRegionState)
            setStockLocationRegionState(changeStockLocationRegion);
    }, [setStockLocationRegionState]);

    useEffect(() => {
        getRegions();
    }, []);

    return (
        <>
            {!((user?.userLocationAccess.length || 0) === 1) || user?.stockRole.id === 5 ? (
                <Button
                    style={{ padding: '7px' }}
                    aria-controls='region-dropdown'
                    color='inherit'
                    startIcon={
                        <TitleChangeLocation title={Trans('messages.t.stock_location')}>
                            <IconChangeLocation>
                                <img src={Pin} width='20px' alt='Pin Location' />
                            </IconChangeLocation>
                            <Hidden only={['sm', 'xs']}>{currentStockLocation}</Hidden>
                        </TitleChangeLocation>
                    }
                    onClick={(e) => {
                        handleProfileMenuOpen(e);
                    }}
                >
                    <ExpandMore />
                </Button>
            ) : (
                <LocationLabelCover>
                    <TitleChangeLocation title={Trans('messages.t.stock_location')}>
                        <IconChangeLocation>
                            <img src={Pin} width='20px' alt='Pin Location' />
                        </IconChangeLocation>
                        <Hidden only={['sm', 'xs']}>{user?.userLocationAccess[0].location.name}</Hidden>
                    </TitleChangeLocation>
                </LocationLabelCover>
            )}
            {renderRegionDropdown()}
        </>
    );
};

export default Wrapper(ChangeStockLocation);
