import React from 'react';
import { DeviceContent } from './Device.view';
import { DeviceProps } from './Device.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Device: React.FC<DeviceProps> = () => {
    return <DeviceContent data-testid='Device-testid' />;
};

export default Wrapper(Device);
