import React, { useCallback, useEffect, useState } from 'react';
import * as view from './CustomerAddress.view';
import { CustomerAddressProps, DataModal, ISignUpForm } from './CustomerAddress.type';
import Wrapper from '../../helpers/wrapper';
import { MenuItem, TextField, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ApiCustomerAddress from '../../api/ApiCustomerAddress';
import ApiCustomer from '../../api/ApiCustomer';
import { Success } from '../Popup/Popup';
import { CustomerAddresses } from '../../states/component/CustomerAddress';
import { ColumnsTable } from '../../types/common';
import Translator from '../../states/global/Translator';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ModelCustomerItrack } from '../../models/ApiCustomer.type';
import { ICustomerAddress } from '../../models/ApiCustomerAddress.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { useStyles } from './CustomerAddress.style';
// import module

const apiCustomerAddress = new ApiCustomerAddress();
const apiCustomers = new ApiCustomer();

const CustomerAddress: React.FC<CustomerAddressProps> = () => {
    const setdata = useSetRecoilState<ICustomerAddress[]>(CustomerAddresses);
    const Trans = useRecoilValue(Translator);
    const [customers, setCustomers] = useState<ModelCustomerItrack[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingForm, setLoadingForm] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const classes = useStyles();
    const [isValid, setIsValid] = useState<string | null>(null);

    const [valuesInitForm, setvaluesInitForm] = useState<ISignUpForm>({
        customerId: '',
        primaryAddress: false,
        disabled: false,
        address1: '',
        address2: '',
        postcode: '',
        province: '',
        country: '',
        email: '',
        phoneNumber: ''
    });

    const [dataModal, setdataModal] = useState<DataModal>({
        isAdd: true,
        title: '',
        id: null
    });

    const openModal = async (type_form = 'create', id: number | null = null): Promise<void> => {
        setdataModal({
            isAdd: type_form !== 'edit' ? true : false,
            title:
                type_form !== 'edit'
                    ? Trans('messages.t.add_new_customer_address')
                    : Trans('messages.t.customer_address_update') + ' - #' + id,
            id: type_form !== 'edit' ? null : id
        });

        setTimeout(() => {
            setOpen(true);
            setLoadingForm(false);
        }, 100);
    };

    const handleClose = (): void => {
        setvaluesInitForm({
            customerId: '',
            primaryAddress: false,
            disabled: false,
            address1: '',
            address2: '',
            postcode: '',
            province: '',
            country: '',
            email: '',
            phoneNumber: ''
        });
        setdataModal({ isAdd: true, title: '', id: null });
        setOpen(false);
    };

    const getCustomers = async (): Promise<void> => {
        const customes: ModelCustomerItrack[] = await apiCustomers.getCustomersCodeBook();
        setCustomers(customes);
    };

    const getAll = useCallback(
        async (page, limit, order, filter): Promise<void> => {
            setLoading(true);
            setdata([]);
            const data = await apiCustomerAddress.getAll(page, limit, order, filter);
            setdata(data.items);
            setPageCount(data.paginator.pageCount);
            setTotal(data.paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter) => {
        let pages = 1;
        let items: ICustomerAddress[] = [];

        for (let i = 1; i <= pages; i++) {
            const data = await apiCustomerAddress.getAll(i, 200, order, filter);
            pages = data.paginator.pageCount;
            items = items.concat(data.items);
        }

        return items;
    };

    const handleSubmit = async (e: ISignUpForm, resetForm: (values: object) => void, setSubmitting): Promise<void> => {
        try {
            e.customer = e.customerId;

            const { data }: ICustomerAddress = dataModal.isAdd
                ? await apiCustomerAddress.create(e)
                : await apiCustomerAddress.patch(dataModal.id ?? 0, e);

            if (!data) {
                throw data;
            }

            const id = data?.customerAddress?.id;

            setRefresh(true);
            resetForm({});

            Success({
                text: dataModal.isAdd
                    ? `${Trans('messages.t.customer_address_created_successfully')} [ID ${id}]`
                    : `${Trans('messages.t.customer_address_successfully_updated')} [ID ${id}]`
            });
            handleClose();
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const confirmDeleteRow = (row) =>
        popUpConfirm(
            Trans('messages.t.customer_address') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_delete'),
            deleteRow,
            row,
            {
                yes: Trans('messages.t.yes'),
                no: Trans('messages.t.no')
            }
        );

    const deleteRow = async (onClose, row: ICustomerAddress): Promise<void> => {
        try {
            const customerAddress: ICustomerAddress = await apiCustomerAddress.remove(row.id);
            if (!customerAddress) {
                throw location;
            }

            setRefresh(true);
            onClose();
            Success({ text: `${Trans('messages.t.customer_address_successfully_removed')} [ID ${row.id}]` });
        } catch (error) {
            console.warn('Error to sending data', error);
        }
    };

    const editCustomerAddress = async (row: ICustomerAddress): Promise<void> => {
        setLoadingForm(true);
        setOpen(true);
        const { customerAddress }: ICustomerAddress = await apiCustomerAddress.getById(row.id);

        if (customerAddress) {
            setvaluesInitForm({
                customerId: customerAddress.customer.id,
                primaryAddress: customerAddress.primaryAddress,
                disabled: customerAddress.disabled,
                address1: customerAddress.address1,
                address2: customerAddress.address2,
                postcode: customerAddress.postcode,
                province: customerAddress.province,
                country: customerAddress.country
            });
        }

        openModal('edit', row.id);
    };

    const ActionsButtons = (original: ICustomerAddress): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => editCustomerAddress(original)}>
                    <Edit />
                    <span>{Trans('messages.t.edit')}</span>
                </Typography>

                {/* exist_items removed by BE */}
                <Typography className={'dataTableActionsBtn danger'} onClick={() => confirmDeleteRow(original)}>
                    <Delete />
                    <span>{Trans('messages.t.remove')}</span>
                </Typography>
            </>
        );
    };

    const columns: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 60
        },
        {
            Header: Trans('messages.t.webTrack_customer'),
            accessor: 'customer.name',
            disableGroupBy: true,
            disableSortBy: true,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: Trans('messages.t.address1'),
            accessor: 'address1',
            disableGroupBy: true,
            disableSortBy: false,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: Trans('messages.t.postcode'),
            accessor: 'postcode',
            disableGroupBy: true,
            disableSortBy: false,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: Trans('messages.t.province'),
            accessor: 'province',
            disableGroupBy: true,
            disableSortBy: false,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: Trans('messages.t.primaryAddress'),
            accessor: 'primaryAddress',
            disableGroupBy: true,
            disableSortBy: false,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false,
            Export: ({ row }: CellProps) =>
                row.original.primaryAddress ? Trans('messages.t.yes') : Trans('messages.t.no'),
            Cell: ({ row }: CellProps) =>
                row.original.primaryAddress ? Trans('messages.t.yes') : Trans('messages.t.no'),
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <TextField
                        className={classes.inputFilterColumn}
                        select
                        variant='outlined'
                        label={column.Header}
                        value={column.filterValue?.value || '0'}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            const value = !e.target.value
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: e.target.value
                                  };
                            setFilter(column.id, value);
                        }}
                    >
                        <MenuItem value='1'>{Trans('messages.t.yes')}</MenuItem>
                        <MenuItem value='0'>{Trans('messages.t.no')}</MenuItem>
                    </TextField>
                );
            }
        },
        {
            Header: Trans('messages.t.country'),
            accessor: 'country',
            disableGroupBy: true,
            disableSortBy: false,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false
        },
        {
            Header: Trans('messages.t.disabled'),
            accessor: 'disabled',
            disableGroupBy: true,
            disableSortBy: false,
            disableFilters: false,
            aggregate: 'count',
            canFilters: false,
            Export: ({ row }: CellProps) => (row.original.disabled ? Trans('messages.t.yes') : Trans('messages.t.no')),
            Cell: ({ row }: CellProps) => (row.original.disabled ? Trans('messages.t.yes') : Trans('messages.t.no')),
            Filter: ({ column, setFilter }: CellProps) => {
                return (
                    <TextField
                        className={classes.inputFilterColumn}
                        select
                        variant='outlined'
                        label={column.Header}
                        value={column.filterValue?.value || '0'}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            const value = !e.target.value
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: e.target.value
                                  };
                            setFilter(column.id, value);
                        }}
                    >
                        <MenuItem value='0'>{Trans('messages.t.yes')}</MenuItem>
                        <MenuItem value='1'>{Trans('messages.t.no')}</MenuItem>
                    </TextField>
                );
            }
        },
        {
            Header: Trans('messages.t.actions'),
            accessor: 'action',
            align: 'center',
            width: 60,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row: { original } }: CellProps) => (
                <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
            )
        }
    ];

    useEffect(() => {
        getCustomers();
    }, []);

    return (
        <view.CustomerAddressContent
            data-testid='CustomerAddress-testid'
            openModal={openModal}
            open={open}
            isLoading={loading}
            closeModal={handleClose}
            handleSubmit={handleSubmit}
            columns={columns}
            valuesInitForm={valuesInitForm}
            fullScreenModal={getFullScreenModal()}
            fetchData={getAll}
            fetchDataExport={getForExport}
            total={total}
            pageCount={pageCount}
            refresh={refresh}
            isValid={isValid}
            setIsValid={setIsValid}
            customers={customers}
            loadingForm={loadingForm}
        />
    );
};

export default Wrapper(CustomerAddress);
