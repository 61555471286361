import { Button } from '@mui/material';
import styled from 'styled-components';

export const FilterColumnsContent = styled.div`
    display: inline-block;
`;

export const FilterColumnCover = styled.div`
    display: grid;
    grid-column-gap: 10px;
`;

export const ApplySaveButton = styled(Button)`
    padding: 1px 6px !important;
`;
