import React from 'react';
import { ShipperContainer } from './Shipper.style';
import Shipper from '../../components/Shipper/Shipper';
// import module

export const ShipperContent: React.FC = (): JSX.Element => (
    <ShipperContainer data-testid='ShipperContent'>
        <Shipper />
    </ShipperContainer>
);
