import React from 'react';
import { OrderHistoryOutboundContent } from './OrderHistoryOutbound.view';
import { OrderHistoryOutboundProps } from './OrderHistoryOutbound.type';
import Wrapper from '../../helpers/wrapper';
// import module

const OrderHistoryOutbound: React.FC<OrderHistoryOutboundProps> = () => {
    return <OrderHistoryOutboundContent data-testid='OrderHistoryOutbound-testid' />;
};

export default Wrapper(OrderHistoryOutbound);
