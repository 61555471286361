import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    IconButton,
    InputAdornment,
    Paper
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Close } from '@mui/icons-material';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useRecoilValue } from 'recoil';
import * as type from './Box.type';
import { AutocompleteElement, InputElement, LoadingElement, OutlinedInputElement } from '../FormElements';
import { Boxes } from '../../states/component/Box';
import Translator from '../../states/global/Translator';
import UiTable from '../Ui/UiTable/UiTable';
import { Loading } from '../../helpers/Loading';
import { LocationsItems, StockItemsType } from '../../models/ApiItems.type';
import { ModelCodeBook } from '../../types/common';
import StockLocationDropdown from '../StockLocationDropdown/StockLocationDropdown';
import { IUAlert } from '../../styled/global.style';
import { ButtonCloseDialog, ButtonFab, UiDialog, UiDialogTitle, UiScrollbarsBoxCreateOrEdit } from './Box.style';
// import module

export const BoxContent: React.FC<type.BoxViewProps> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);
    const boxes: StockItemsType[] = useRecoilValue(Boxes);

    return (
        <>
            {props.columns.length ? (
                <Paper className='cardPageContainer' elevation={1}>
                    <UiTable
                        title='boxes'
                        data={boxes}
                        loading={props.isLoading}
                        columns={props.columns}
                        onAdd={() => props.openModal}
                        total={props.total}
                        pageCount={props.pageCount}
                        extraFiltering={props.extraFiltering}
                        fetchData={({ page, limit, order, filter, extraFiltering }) => {
                            props.fetchData(page + 1, limit, order, filter, extraFiltering);
                        }}
                        fetchDataExport={props.fetchDataExport}
                        defaultSortBy={{
                            column: 'createdAt',
                            desc: true
                        }}
                        containerSubRows='children'
                        headerSubRows={[
                            'ID',
                            Trans('messages.t.serial_number'),
                            Trans('messages.t.sku_code'),
                            Trans('messages.t.name'),
                            Trans('messages.t.quantity'),
                            Trans('messages.t.type'),
                            Trans('messages.t.shipping_price_in_usd')
                        ]}
                        itemsSubRows={[
                            'id',
                            'serialNumber',
                            'sku.skuCode',
                            'sku.name',
                            'quantity',
                            'sku.skuType.type',
                            'shippingOrderItemPriceUsd'
                        ]}
                        hiddenColumns={[]}
                        forceRefresh={props.refresh}
                        filterOutsideToolbarElement={props.elementFilter}
                    />
                </Paper>
            ) : (
                <Loading />
            )}

            <UiDialog
                fullScreen={props.fullScreenModal}
                fullWidth={true}
                maxWidth='xl'
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
                className='static_action_buttons'
            >
                <UiDialogTitle id='responsive-dialog-title' className='text-center'>
                    {props.dataModal?.title}
                    <ButtonCloseDialog>
                        <ButtonFab
                            size='small'
                            aria-label='add'
                            onClick={() => {
                                props.closeModal();
                            }}
                        >
                            <Close />
                        </ButtonFab>
                    </ButtonCloseDialog>
                </UiDialogTitle>

                {props.loadingForm ? (
                    <Loading />
                ) : (
                    <Formik
                        initialValues={props.valuesInitForm}
                        onSubmit={(values: type.IBoxForm, actions) =>
                            props.handleSubmit(values, actions.resetForm, actions.setSubmitting)
                        }
                        validationSchema={props.formschema}
                    >
                        {(propsF: FormikProps<type.IBoxForm>) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                setFieldValue,
                                isSubmitting,
                                submitForm
                            } = propsF;

                            return (
                                <>
                                    <UiScrollbarsBoxCreateOrEdit
                                        autoHeight
                                        autoHide
                                        // autoHeightMin={'calc(100vh - 180px)'}
                                        renderThumbVertical={({ style, ...props }) => (
                                            <div {...props} className='scrollYcustom' style={{ ...style }} />
                                        )}
                                    >
                                        <Form>
                                            <DialogContent>
                                                <div className='c_contentStep'>
                                                    <div className={'container_dialogoContent'}>
                                                        {Object.keys(errors).length > 0 ? (
                                                            <IUAlert variant='outlined' severity='warning'>
                                                                {Trans('messages.p.all_fields_marked_are_required')}
                                                            </IUAlert>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <Grid container justifyContent='flex-start' direction='row'>
                                                            <Grid container lg={12} md={12} sm={12} xs={12}>
                                                                <StockLocationDropdown
                                                                    name='locationSelect'
                                                                    label={Trans('messages.t.stock_location')}
                                                                    value={
                                                                        props.dataModal?.isAdd
                                                                            ? values.locationSelect[0]
                                                                            : values.locationSelect
                                                                    }
                                                                    error={!!errors.locationSelect}
                                                                    onChange={(value: LocationsItems) => {
                                                                        props.setLocationFilter({
                                                                            ...props.extraFilteringItems,
                                                                            location: value ? `${value?.id}` : ''
                                                                        });
                                                                        setFieldValue(
                                                                            'locationSelect',
                                                                            value ? value : ''
                                                                        );
                                                                        props.setResetFilter(true);
                                                                        props.setItemsToSend([]);
                                                                    }}
                                                                    required={true}
                                                                    textRequired={Trans(
                                                                        'messages.p.this_field_is_required'
                                                                    )}
                                                                    lg={4}
                                                                    md={6}
                                                                    sm={6}
                                                                />

                                                                <InputElement
                                                                    label={Trans('messages.t.label')}
                                                                    name='label'
                                                                    value={values.label}
                                                                    error={errors.label}
                                                                    touched={touched.label}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    lg={3}
                                                                    md={3}
                                                                    sm={4}
                                                                    required={true}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <fieldset
                                                                    style={{ minHeight: '300px', borderRadius: '5px' }}
                                                                >
                                                                    <legend className='modalLegend'>
                                                                        {Trans('messages.t.choose_items')}
                                                                    </legend>
                                                                    <Grid
                                                                        container
                                                                        justifyContent='flex-start'
                                                                        direction='row'
                                                                    >
                                                                        <AutocompleteElement
                                                                            label={Trans('messages.t.type')}
                                                                            name='type'
                                                                            value={values.type}
                                                                            error={errors.type}
                                                                            touched={touched.type}
                                                                            onBlur={handleBlur}
                                                                            setFieldValue={setFieldValue}
                                                                            options={[
                                                                                {
                                                                                    value: 'all',
                                                                                    label: Trans('messages.t.all')
                                                                                },
                                                                                {
                                                                                    value: 'generic_item',
                                                                                    label: Trans(
                                                                                        'messages.t.generic_item'
                                                                                    )
                                                                                },
                                                                                {
                                                                                    value: 'sensor',
                                                                                    label: Trans('messages.t.sensor')
                                                                                },
                                                                                {
                                                                                    value: 'hub',
                                                                                    label: Trans('messages.t.hub')
                                                                                },
                                                                                {
                                                                                    value: 'tablet',
                                                                                    label: Trans('messages.t.tablet')
                                                                                },
                                                                                {
                                                                                    value: 'box',
                                                                                    label: Trans('messages.t.box')
                                                                                }
                                                                            ]}
                                                                            defaultValue={null}
                                                                            onChange={(
                                                                                _,
                                                                                value: Record<string, string>
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'type',
                                                                                    value ? value.value : ''
                                                                                );
                                                                                setFieldValue('sku', '');
                                                                                props.setResetFilter(true);
                                                                                props.setSerialNumber('');
                                                                                props.setLocationFilter({
                                                                                    ...props.extraFilteringItems,
                                                                                    type: value ? value.value : '',
                                                                                    serialNumber: ''
                                                                                });
                                                                            }}
                                                                            lg={4}
                                                                            md={4}
                                                                            sm={6}
                                                                        />
                                                                        {props.resetFilter ? (
                                                                            <LoadingElement
                                                                                label={Trans('messages.t.loading')}
                                                                                lg={4}
                                                                                md={4}
                                                                                sm={12}
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                {values.type === 'all' ||
                                                                                values.type === '' ? (
                                                                                    ''
                                                                                ) : (
                                                                                    <AutocompleteElement
                                                                                        label={Trans('messages.t.sku')}
                                                                                        name='sku'
                                                                                        value={values.sku}
                                                                                        error={errors.sku}
                                                                                        touched={touched.sku}
                                                                                        onBlur={handleBlur}
                                                                                        setFieldValue={setFieldValue}
                                                                                        options={
                                                                                            props.SKUs &&
                                                                                            !!props.SKUs.length
                                                                                                ? props.SKUs
                                                                                                : []
                                                                                        }
                                                                                        defaultValue={null}
                                                                                        onChange={(
                                                                                            _,
                                                                                            value: ModelCodeBook
                                                                                        ) => {
                                                                                            setFieldValue(
                                                                                                'sku',
                                                                                                value ? value.id : ''
                                                                                            );
                                                                                            props.setLocationFilter({
                                                                                                ...props.extraFilteringItems,
                                                                                                skuId: value
                                                                                                    ? `${value.id}`
                                                                                                    : ''
                                                                                            });
                                                                                        }}
                                                                                        optionsPropertyLabel='name'
                                                                                        optionsPropertyValue='id'
                                                                                        lg={4}
                                                                                        md={4}
                                                                                        sm={12}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <OutlinedInputElement
                                                                            label={Trans('messages.t.serial_number')}
                                                                            name='serialNumber'
                                                                            value={props.serialNumber}
                                                                            onChange={(e) => {
                                                                                props.setSerialNumber(e.target.value);
                                                                                props.setResetPageIndexTable(
                                                                                    !props.resetPageIndexTable
                                                                                );
                                                                                props.setLocationFilter({
                                                                                    ...props.extraFilteringItems,
                                                                                    serialNumber: e.target.value
                                                                                });
                                                                            }}
                                                                            endAdornment={
                                                                                <InputAdornment
                                                                                    title={Trans('messages.t.reset')}
                                                                                    position='end'
                                                                                >
                                                                                    <IconButton
                                                                                        aria-label={Trans(
                                                                                            'messages.t.serial_number'
                                                                                        )}
                                                                                        onClick={() => {
                                                                                            props.setSerialNumber('');
                                                                                            props.setLocationFilter({
                                                                                                ...props.extraFilteringItems,
                                                                                                serialNumber: ''
                                                                                            });
                                                                                        }}
                                                                                        edge='end'
                                                                                    >
                                                                                        {props.serialNumber ? (
                                                                                            <Close />
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                            lg={4}
                                                                            md={4}
                                                                        />
                                                                    </Grid>
                                                                    <div
                                                                        className='bodyRightItems'
                                                                        style={{ display: 'flex' }}
                                                                    >
                                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                            <UiTable
                                                                                data={props.itemsFrom}
                                                                                loading={props.loadingItems}
                                                                                columns={props.columnsAvailableItems}
                                                                                customButtonAdd={
                                                                                    <b>
                                                                                        <span>
                                                                                            {Trans(
                                                                                                'messages.t.available_items'
                                                                                            )}
                                                                                        </span>
                                                                                    </b>
                                                                                }
                                                                                subtractSpace={210}
                                                                                total={props.totalItems}
                                                                                extraFiltering={
                                                                                    props.extraFilteringItems
                                                                                }
                                                                                pageCount={props.pageCountItems}
                                                                                fetchData={({
                                                                                    page,
                                                                                    limit,
                                                                                    order,
                                                                                    filter,
                                                                                    extraFiltering
                                                                                }) => {
                                                                                    props.fetchDataItems(
                                                                                        page + 1,
                                                                                        limit,
                                                                                        order,
                                                                                        filter,
                                                                                        extraFiltering,
                                                                                        props.dataModal?.id
                                                                                    );
                                                                                }}
                                                                                hiddenColumns={[]}
                                                                                forceRefresh={props.refreshItems}
                                                                            />
                                                                        </Grid>
                                                                        &nbsp;
                                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                            <UiTable
                                                                                classesTable='containerDataTable'
                                                                                data={props.itemsToSend}
                                                                                loading={false}
                                                                                hidePagination={true}
                                                                                hideForceRefresh={true}
                                                                                noPagination={true}
                                                                                columns={props.columnsItemsToSend}
                                                                                subtractSpace={210}
                                                                                hiddenColumns={[]}
                                                                                globalFilter={true}
                                                                                customButtonAdd={
                                                                                    <b>
                                                                                        <span>
                                                                                            {Trans(
                                                                                                'messages.t.item_to_send'
                                                                                            )}
                                                                                        </span>
                                                                                    </b>
                                                                                }
                                                                                resetPageIndex={
                                                                                    props.resetPageIndexTable
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                </fieldset>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </DialogContent>
                                        </Form>
                                    </UiScrollbarsBoxCreateOrEdit>
                                    <DialogActions>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            type='button'
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                submitForm();
                                            }}
                                        >
                                            {isSubmitting ? <CircularProgress size={25} /> : Trans('messages.t.save')}
                                        </Button>
                                    </DialogActions>
                                </>
                            );
                        }}
                    </Formik>
                )}
            </UiDialog>
        </>
    );
};
