import React, { ReactElement, useEffect, useState } from 'react';
import {
    useColumnOrder,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import { TableBody, TableContainer, TableHead, TableRow, Table as TableSub, Tooltip } from '@mui/material';
import * as type from './UiTable.type';
import * as style from './UiTable.style';
import * as view from './UiTable.view';
import Wrapper from '../../../helpers/wrapper';
import ColumnFilter from './Components/ColumnFilter';
import classnames from 'classnames';
import { Block } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import Translator from '../../../states/global/Translator';
import { getValueOfAnObject, toCamelCaseAndRemove_ } from '../../../helpers/converter';
import { getLSUser } from '../../../helpers/localStorage';
import { InitialStateType, TableConfigType } from './UiTable.type';

const UiTable: React.FC<type.UiTableProps> = (props): JSX.Element => {
    const [currentFilters, setCurrentFilters] = useState<Record<string, unknown>[]>([]);
    const [refresh, setRefresh] = useState<number>(0);
    const [freeze, setFreeze] = useState<boolean>(true);
    const Trans = useRecoilValue(Translator);
    let instanceTableState: InitialStateType | null = getLSUser<InitialStateType>(`table.${props?.title}Table`) || null;

    const prefix = props.configPrefix ? '_' + props.configPrefix : '';
    const tableConfig: TableConfigType = getLSUser('tableConfig' + prefix) || {
        pageSize: 10
    };

    const InstanceSmallIconActionButton = <T extends Record<string, unknown>>({
        instance,
        icon,
        onClick,
        label,
        enabled = () => true,
        variant
    }: type.InstanceActionButton<T>): ReactElement => {
        const classes = style.useStyles({});
        return (
            <Tooltip title={label} aria-label={label}>
                <span>
                    <style.UiFab
                        color='primary'
                        size='small'
                        className={classnames({
                            [classes.rightIcons]: variant === 'right',
                            [classes.leftIcons]: variant === 'left'
                        })}
                        onClick={onClick(instance)}
                        disabled={!enabled(instance)}
                    >
                        {icon}
                    </style.UiFab>
                </span>
            </Tooltip>
        );
    };

    const defaultColumn = React.useMemo(() => {
        return {
            Filter: ColumnFilter
        };
    }, []);

    const defaultSorting = props.defaultSortBy
        ? [
              {
                  id: props.defaultSortBy?.column ?? 'id',
                  desc: props.defaultSortBy?.desc ?? false
              }
          ]
        : [];

    if (instanceTableState && Object.keys(instanceTableState).length) {
        const filtersState: type.DefaultFilterType[] | [] = instanceTableState?.filters || [];
        const sortByState: type.ModelSort[] | [] =
            instanceTableState?.sortBy && !!instanceTableState?.sortBy.length
                ? instanceTableState?.sortBy
                : defaultSorting;
        instanceTableState = {
            ...instanceTableState,
            pageIndex: 0,
            pageSize: tableConfig.pageSize,
            sortBy: sortByState,
            filters: props.defaultFilter ? [props.defaultFilter, ...filtersState] : [...filtersState]
        };
    } else {
        instanceTableState = {
            sortBy: defaultSorting,
            filters: props.defaultFilter ? [props.defaultFilter] : [],
            pageIndex: 0,
            pageSize: tableConfig.pageSize,
            hiddenColumns: props.hiddenColumns
        };
    }

    const tableInstance = useTable(
        {
            title: props.title || '',
            columns: props.columns,
            data: props.data,
            initialState: instanceTableState,
            manualPagination: props.noPagination ? false : true,
            defaultColumn,
            pageCount: props.pageCount,
            autoResetPage: false,
            manualSortBy: props.noPagination ? false : true,
            manualFilters: props.noPagination ? false : true
        },
        useColumnOrder,
        useFilters,
        useGroupBy,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useFlexLayout,
        usePagination,
        useResizeColumns
    );

    const refreshTable = () => {
        setRefresh((current) => current + 1);
    };

    const CheckedData = (page) => props.containerSubRows && !!page.original[props.containerSubRows].length;

    useEffect(() => {
        setCurrentFilters(tableInstance.state.filters);
    }, []);

    const applyFilterExtraOutside = (): void => {
        if (props.filterOutsideValue && props.filterOutsideValue !== '') {
            tableInstance.setFilter(props.filterOutsideName, props.filterOutsideValue);
        } else {
            if (tableInstance && !!tableInstance.state.filters.length) {
                const newFilterList = tableInstance.state.filters.filter(
                    (filter) => filter.id !== props.filterOutsideName
                );
                tableInstance.setAllFilters(newFilterList);
            }
        }
    };

    useEffect(() => {
        applyFilterExtraOutside();
    }, [props.filterOutsideValue]);

    useEffect(() => {
        !freeze &&
            props.fetchData &&
            props.fetchData({
                page: tableInstance.state.pageIndex,
                limit: tableInstance.state.pageSize,
                order: tableInstance.state.sortBy,
                filter: currentFilters,
                extraFiltering: props.extraFiltering
            });
    }, [
        tableInstance.state.pageIndex,
        tableInstance.state.pageSize,
        tableInstance.state.sortBy,
        currentFilters,
        refresh,
        freeze,
        props.extraFiltering
    ]);

    useEffect(() => {
        if (props.filterOutsideValue) {
            applyFilterExtraOutside();
        }

        if (tableInstance.state.globalFilter) {
            tableInstance.setGlobalFilter(tableInstance.state.globalFilter);
        }
    }, [props.data]);

    useEffect(() => {
        tableInstance.gotoPage(0);
    }, [currentFilters]);

    useEffect(() => {
        tableInstance.gotoPage(0);
    }, [tableInstance.state.globalFilter]);

    useEffect(() => {
        tableInstance.gotoPage(0);
    }, [props.resetPageIndex]);

    useEffect(() => {
        if (props.forceRefresh) {
            refreshTable();
        }
    }, [props.forceRefresh]);

    useEffect(() => {
        if (props.defaultFilter && props.defaultSortBy) {
            setTimeout(() => {
                setFreeze((current) => !current);
            }, 300);
        } else {
            setFreeze((current) => !current);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            tableInstance.gotoPage(0);
        }, 700);
    }, [props.extraFiltering]);

    const renderRowSubComponent = React.useCallback(({ page }) => {
        let showNotData = false;
        return (
            <TableContainer component={style.StyledPaper}>
                <TableSub aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            {!!props.headerSubRows &&
                                props.headerSubRows.map((item, i) => (
                                    <style.StyledTableCellHeader key={`itemCellHeader-${i}`} align='center'>
                                        {item}
                                    </style.StyledTableCellHeader>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.containerSubRows
                            ? CheckedData(page)
                                ? page.original[props.containerSubRows].map((item) => (
                                      <style.StyledTableRow key={item.name}>
                                          {props.itemsSubRows &&
                                              !!props.itemsSubRows?.length &&
                                              props.itemsSubRows.map((rowItemBox) => {
                                                  const propValue = getValueOfAnObject(item, rowItemBox);

                                                  if (rowItemBox === 'sku.skuType.type') {
                                                      switch (item.sku.skuType.type) {
                                                          case 'generic_item':
                                                              return (
                                                                  <style.StyledTableCell align='center'>
                                                                      {getValueOfAnObject(item, rowItemBox)
                                                                          ? toCamelCaseAndRemove_(propValue)
                                                                          : '---'}{' '}
                                                                  </style.StyledTableCell>
                                                              );
                                                          case 'box':
                                                              return (
                                                                  <style.StyledTableCell align='center'>
                                                                      {' '}
                                                                      {getValueOfAnObject(item, rowItemBox)
                                                                          ? toCamelCaseAndRemove_(propValue)
                                                                          : '---'}
                                                                  </style.StyledTableCell>
                                                              );
                                                          case 'tablet':
                                                              return (
                                                                  <style.StyledTableCell align='center'>
                                                                      {getValueOfAnObject(item, rowItemBox)
                                                                          ? toCamelCaseAndRemove_(propValue)
                                                                          : '---'}
                                                                  </style.StyledTableCell>
                                                              );
                                                          case 'sensor':
                                                              return (
                                                                  <style.StyledTableCell align='center'>
                                                                      {' '}
                                                                      {getValueOfAnObject(item, rowItemBox)
                                                                          ? toCamelCaseAndRemove_(propValue)
                                                                          : '---'}
                                                                  </style.StyledTableCell>
                                                              );
                                                          default:
                                                              item.sku.skuType.type;
                                                      }
                                                  } else {
                                                      if (rowItemBox === 'serialNumber') {
                                                          if (item.sku.skuType.type === 'sensor') {
                                                              <style.StyledTableCell align='center'>
                                                                  {getValueOfAnObject(item, rowItemBox)
                                                                      ? toCamelCaseAndRemove_(propValue)
                                                                      : '---'}
                                                              </style.StyledTableCell>;
                                                          }
                                                      }
                                                      return (
                                                          <style.StyledTableCell align='center'>
                                                              {getValueOfAnObject(item, rowItemBox)
                                                                  ? getValueOfAnObject(item, rowItemBox)
                                                                  : '---'}
                                                          </style.StyledTableCell>
                                                      );
                                                  }
                                              })}
                                      </style.StyledTableRow>
                                  ))
                                : (showNotData = true)
                            : (showNotData = true)}
                    </TableBody>
                </TableSub>
                {showNotData && (
                    <div style={{ lineHeight: '150px', minHeight: '180px', textAlign: 'center' }}>
                        <div className='no_data_children'>
                            <span className='no_data_children_item'>
                                <Block fontSize='large' />
                            </span>
                            <h4 className='no_data_children_item'> {Trans('messages.t.there_no_data')} </h4>
                        </div>
                    </div>
                )}
            </TableContainer>
        );
    }, []);

    return (
        <view.UiTableContent
            table={tableInstance}
            {...tableInstance}
            {...props}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            refreshTable={refreshTable}
            fetchDataExport={props.fetchDataExport}
            data-testid={'UiTable-testid'}
            InstanceSmallIconActionButton={InstanceSmallIconActionButton}
            renderRowSubComponent={renderRowSubComponent}
            CheckedData={CheckedData}
            filterOutsideToolbarElement={props.filterOutsideToolbarElement}
            subtractSpace={props.subtractSpace}
            configPrefix={props.configPrefix}
        />
    );
};

export default Wrapper(UiTable);
