import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { IRoleForm, RolesFormik as RolesFormikType } from './Roles.type';
import { InputElement } from '../FormElements';

export const RolesFormik: React.FC<RolesFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const Formschema = Yup.object({
        name: Yup.string().trim().min(3, 'Name is Too Short.').required(Trans('messages.p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: IRoleForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<IRoleForm>) => {
                const { touched, errors, handleChange, handleBlur, values, isSubmitting, submitForm } = propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='space-around' direction='row'>
                                <InputElement
                                    autoFocus={true}
                                    label={Trans('messages.t.name')}
                                    name='name'
                                    value={values.name}
                                    error={errors.name}
                                    touched={touched.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    required={true}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
