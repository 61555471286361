import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as type from './Profile.type';
import * as view from './Profile.view';
import ApiRoles from '../../api/ApiUser';
import { Errors, Success } from '../Popup/Popup';
import Wrapper from '../../helpers/wrapper';
import Translator from '../../states/global/Translator';
import * as UserState from '../../states/global/User';
// import module

const api = new ApiRoles();

const Profile: React.FC<type.ProfileProps> = () => {
    const [userData, setUserData] = useRecoilState<any>(UserState.User);
    const Trans = useRecoilValue(Translator);
    const [valuesInitFormEdit, setvaluesInitFormEdit] = useState({
        username: '',
        email: '',
        role: '',
        phoneNumber: '',
        firstname: '',
        lastname: '',
        avatar: ''
    });
    const [valuesInitFormKey] = useState({ current_password: '', password: '', confirm_password: '' });
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [roles, setRoles] = useState<any>([]);

    const getUser = useCallback(async () => {
        try {
            const {
                data: { user }
            }: any = await api.getGeneral('stock/account');

            setUserData(user);
        } catch (error) {
            console.warn('Error to sending data ', error);
        }
    }, [setUserData, userData]);

    const handleSubmit = async (e: any, resetForm: any, setSubmitting) => {
        setReadyForm(false);
        delete e.avatar;
        e.stockRole = e.role;
        delete e.role;

        try {
            const {
                data: { user }
            }: any = await api.patchGeneralFree('account', e);
            if (user) {
                Success({ text: Trans('messages.t.user_modified_success') });
                setTimeout(() => {
                    window.location.replace('');
                }, 3000);
            } else {
                Errors({ text: Trans('messages.t.user_modifying_error') });
            }

            if (Object.keys(user).length > 0) {
                const newUserData = Object.assign({}, userData, user);

                setUserData(newUserData);
                setvaluesInitFormEdit({
                    username: newUserData.username,
                    email: newUserData.email,
                    role: newUserData.stockRole.id,
                    phoneNumber: newUserData.phoneNumber,
                    firstname: newUserData.firstname,
                    lastname: newUserData.lastname,
                    avatar: newUserData.lastname
                });
            }
            resetForm({});
            setSubmitting(false);
            setReadyForm(true);
        } catch (error) {
            setReadyForm(true);
            setSubmitting(false);
        }
    };

    const handleSubmitPassword = async (e: any, resetForm: any, setSubmitting) => {
        setReadyForm(false);

        e.newPassword = e.password;
        e.currentPassword = e.current_password;

        delete e.confirm_password;
        delete e.current_password;
        delete e.password;

        try {
            const {
                data: { user }
            }: any = await api.createGeneral('account/change-password', e);

            Success({
                text: Trans(user ? 'messages.t.password_changed_successfully' : 'messages.t.fail_to_change_password')
            });
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }

        setSubmitting(false);
        setReadyForm(true);
        resetForm({});
    };

    const getSelect = async () => {
        const {
            data: { items }
        }: any = await api.getGeneral('stock/role');
        setRoles(items.length ? items : []);
        setReadyForm(true);
    };

    useEffect(() => {
        if (Object.keys(userData).length > 0 && valuesInitFormEdit.username === '') {
            setvaluesInitFormEdit({
                username: userData.username,
                email: userData.email,
                role: userData.stockRole.id,
                phoneNumber: userData.phoneNumber,
                firstname: userData.firstname,
                lastname: userData.lastname,
                avatar: userData.lastname
            });
            getSelect();
            getUser();
        }
    }, [valuesInitFormEdit.username, userData]);

    return (
        <view.ProfileContent
            data-testid={'Profile-testid'}
            handleSubmit={handleSubmit}
            handleSubmitPassword={handleSubmitPassword}
            valuesInitFormEdit={valuesInitFormEdit}
            valuesInitFormKey={valuesInitFormKey}
            user={userData}
            roles={roles}
            readyForm={readyForm}
        />
    );
};

export default Wrapper(Profile);
