import React from 'react';
import { TabletContent } from './Tablet.view';
import { TabletProps } from './Tablet.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Tablet: React.FC<TabletProps> = () => {
    return <TabletContent data-testid='Tablet-testid' />;
};

export default Wrapper(Tablet);
