import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from './api';

const NEW_BACKEND_API = process.env.REACT_APP_API_URL || 'https://itrack-api.atms.dev/api';

const config: AxiosRequestConfig = {
    baseURL: NEW_BACKEND_API,
    headers: {
        Accept: 'application/json'
    }
};

const mergedConfigs = (config: AxiosRequestConfig, customHeader?: { 'Accept-Language'?: string }) => {
    config.headers = { ...config.headers, ...customHeader };
    return config;
};

export default class PortalApi<apiModel> extends Api {
    route = '';
    constructor(customHeader?: { 'Accept-Language'?: string }) {
        super(mergedConfigs(config, customHeader));
    }

    /* 
        T - `TYPE`: expected object
        B - `BODY`: body request object
        R - `RESPONSE`: expected object inside a axios response format
    */

    get = async (extendUrl = '', version = 'v1'): Promise<apiModel> => {
        return await this.GET<apiModel>(`${version}/${this.route}${extendUrl}`).then(this.SUCCESS);
    };

    buildAbsoluteUrl = (prefix) => {
        return NEW_BACKEND_API.replace(/\/$/, '') + prefix;
    };

    getTable = async <T, R = T>(
        extendUrl = '',
        version = 'v1',
        criteria?: { [key: string]: string | number }
    ): Promise<{
        items: R[];
        paginator: {
            limit: number;
            page: number;
            pageCount: number;
            totalCount: number;
        };
    }> => {
        return await this.GET<{
            items: R[];
            paginator: {
                limit: number;
                page: number;
                pageCount: number;
                totalCount: number;
            };
        }>(`${version}/${this.route}${extendUrl}`, criteria).then(this.SUCCESS);
    };

    getById = async (id: number, version = 'v1'): Promise<apiModel> => {
        return await this.GET<apiModel>(`${version}/${this.route}/${id}`).then(this.SUCCESS);
    };

    getByCriteria = async (criteria: { [key: string]: string | number }, version = 'v1'): Promise<apiModel> => {
        return await this.GET<apiModel>(`${version}/${this.route}`, criteria).then(this.SUCCESS);
    };

    getCodeBook = async (extendUrl = '', version = 'v1', params = {}): Promise<apiModel[]> => {
        return await this.GET<{ codeBook: apiModel[] }>(`${version}/${this.route}${extendUrl}`, params)
            .then(this.SUCCESS)
            .then((res) => res.codeBook);
    };

    getTemporal = async <T, R = AxiosResponse<T>>(
        extendUrl = '',
        version = 'v1',
        criteria?: { [key: string]: string | number }
    ): Promise<R> => {
        return await this.GET<R>(`${version}/${this.route}${extendUrl}`, criteria).then(this.SUCCESS);
    };

    create = async <T, B, R = AxiosResponse<T>>(data: B, version = 'v1'): Promise<R> => {
        return await this.CREATE(`${version}/${this.route}`, data);
    };

    patch = async <T, B, R = AxiosResponse<T>>(id: number, updates: B, version = 'v1'): Promise<R> => {
        return await this.PATCH(`${version}/${this.route}/${id}`, updates);
    };

    update = async <T, B, R = AxiosResponse<T>>(id: number, updates: B, version = 'v1'): Promise<R> => {
        return await this.UPDATE(`${version}/${this.route}/${id}`, updates);
    };

    remove = async <T, R = AxiosResponse<T>>(id: number, version = 'v1'): Promise<R> => {
        return await this.DELETE(`${version}/${this.route}/${id}`);
    };

    createGeneral = async <T, B, R = AxiosResponse<T>>(route: string, data: B, version = 'v1'): Promise<R> => {
        return await this.CREATE(`${version}/${route}`, data);
    };

    patchGeneral = async <T, B, R = AxiosResponse<T>>(
        route: string,
        id: number,
        updates: B,
        version = 'v1'
    ): Promise<R> => {
        return await this.PATCH(`${version}/${route}/${id}`, updates);
    };

    updateGeneral = async <T, B, R = AxiosResponse<T>>(
        route: string,
        id: number,
        updates: B,
        version = 'v1'
    ): Promise<R> => {
        return await this.UPDATE(`${version}/${route}/${id}`, updates);
    };

    removeGeneral = async <T, R = AxiosResponse<T>>(route: string, id: number | string, version = 'v1'): Promise<R> => {
        return await this.DELETE(`${version}/${route}${id ? '/' + id : ''}`);
    };

    getGeneral = async <T, R = AxiosResponse<T>>(
        route: string,
        params?: Record<string, unknown>,
        version = 'v1'
    ): Promise<R> => {
        return await this.GET(`${version}/${route}`, params);
    };

    getGeneralTable = async <T, R = T>(
        route: string,
        version = 'v1',
        criteria?: { [key: string]: string | number }
    ): Promise<{
        items: R[];
        paginator: {
            limit: number;
            page: number;
            pageCount: number;
            totalCount: number;
        };
    }> => {
        return await this.GET<{
            items: R[];
            paginator: {
                limit: number;
                page: number;
                pageCount: number;
                totalCount: number;
            };
        }>(`${version}/${route}`, criteria).then(this.SUCCESS);
    };

    getByIdGeneral = async <T, R = AxiosResponse<T>>(route: string, id: number, version = 'v1'): Promise<R> => {
        return await this.GET(`${version}/${route}/${id}`);
    };

    getDowloadFile = async (
        extendUrl = '',
        criteria?: { [key: string]: string | number },
        version = 'v1'
    ): Promise<{ response: Blob; fileName: string }> => {
        return await this.GET_FILE<{ data: AxiosResponse }>(`${version}/${this.route}${extendUrl}`, criteria)
            .then(this.SUCCESS_FILE)
            .then((response: AxiosResponse) => {
                let filename = '';
                if (response.headers['content-disposition']) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(response.headers['content-disposition']);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                return { response: response.data, fileName: filename };
            });
    };

    getByCriteriaGeneral = async <T, R = AxiosResponse<T>>(
        route: string,
        criteria?: { [key: string]: string | number },
        version = 'v1'
    ): Promise<R> => {
        return await this.GET(`${version}/${route}`, criteria);
    };

    patchGeneralFree = async <T, B, R = AxiosResponse<T>>(route: string, updates?: B, version = 'v1'): Promise<R> => {
        return await this.PATCH(`${version}/${route}`, updates);
    };
}
