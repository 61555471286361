import React from 'react';
import { BoxesContent } from './Boxes.view';
import * as type from './Boxes.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Boxes: React.FC<type.BoxesProps> = () => {
    return <BoxesContent data-testid='Boxes-testid' />;
};

export default Wrapper(Boxes);
