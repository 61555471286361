import { ColumnsTable } from '../../types/common';

export type OrderListProps = Record<string, unknown>;

export type OrderListViewProps = {
    columns: ColumnsTable[];
    isLoading?: boolean;
    detailsShippingOrder: any;
    fetchData: (
        page: number,
        limit: number,
        order: Record<string, unknown>,
        filter: Record<string, unknown>,
        extraFiltering: { locationFilter: string }
    ) => void;
    fetchDataExport?: (
        order: Record<string, unknown>,
        filter: Record<string, unknown>,
        extraFiltering: { locationFilter: string }
    ) => void;
    total?: number;
    pageCount?: number;
    refresh: boolean;
    extraFiltering: { locationFilter: string } | Record<string, unknown>;
};

export type ShippingOrder = {
    status: string;
    nameStockLocationF: string;
    nameStockLocationT: string;
    created_at: string;
    serial_number: string;
};

export type ShippingOrderData = ShippingOrder & {
    subRows?: ShippingOrderData[];
};

export enum ShippingOrderStatusEnum {
    NEW = 'New',
    IN_TRANSIT = 'In Transit',
    DELIVERED = 'Delivered',
    LOST = 'Lost',
    RETURNED = 'Returned',
    PARTIALLY_DELIVERED = 'Partially Delivered',
    IN_DISPUTE = 'In Dispute',
    CANCELED = 'Canceled',
    DISPUT_RESOLVED = 'Dispute resolved',
    WAITING_CUSTOMER_ACK = 'Waiting customer ACK'
}

export enum ShippingOrderStatusIdsEnum {
    NEW = 1,
    IN_TRANSIT = 2,
    DELIVERED = 3,
    LOST = 4,
    RETURNED = 5,
    PARTIALLY_DELIVERED = 6,
    IN_DISPUTE = 7,
    CANCELED = 8,
    DISPUT_RESOLVED = 9,
    WAITING_CUSTOMER_ACK = 10
}

export type LocationParams = {
    name?: string;
    limit?: number;
    page?: number;
};
