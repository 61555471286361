import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button } from '@mui/material';

export function popUpConfirm<T>(
    title: string,
    textbody: string,
    onEvent: (onClose: () => void, row: T) => void,
    row: T,
    textOpe = { yes: 'Yes', no: 'No' }
): void {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-confirm-alert'>
                    <h1 className='text-confirm-alert'>{title}</h1>
                    <p className='text-confirm-alert'>{textbody}</p>
                    <div className='body-btn-confirm-alert'>
                        <Button
                            className='btn btn-default btn-sm btn-confirm-alert'
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={() => onEvent(onClose, row)}
                        >
                            {textOpe.yes}
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            color='secondary'
                            className='btn btn-default btn-sm btn-confirm-alert-cencel'
                            onClick={onClose}
                        >
                            {textOpe.no}
                        </Button>
                    </div>
                </div>
            );
        }
    });
}
