import React from 'react';
import Reports from '../../components/Reports';
import { ReportsContainer } from './Reports.style';
// import module

export const ReportsContent: React.FC = (): JSX.Element => (
    <ReportsContainer data-testid='ReportsContent'>
        <Reports />
    </ReportsContainer>
);
