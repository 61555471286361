import React from 'react';
import { StockLocationContent } from './StockLocation.view';
import { StockLocationProps } from './StockLocation.type';
import Wrapper from '../../helpers/wrapper';
// import module

const StockLocation: React.FC<StockLocationProps> = () => {
    return <StockLocationContent data-testid='StockLocation-testid' />;
};

export default Wrapper(StockLocation);
