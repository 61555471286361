import { Alert, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecoilValue } from 'recoil';
import * as style from './ShipmentAcceptance.style';
import * as type from './ShipmentAcceptance.type';
import Translator from '../../../states/global/Translator';
import { useStyles } from '../../../styled/global.style';
import UiTable from '../../../components/Ui/UiTable/UiTable';
import { Loading } from '../../../helpers/Loading';
import UiModal from '../../Ui/UiModal/UiModal';
import { EmailAcceptanceFormik } from './EmailAcceptance.formik';
// import module

export const ShipmentAcceptanceContent: React.FC<type.ShipmentAccepTanceViewProps> = (props): any => {
    const classes = useStyles();
    const Trans = useRecoilValue(Translator);
    let submitForm: ((() => Promise<void>) & (() => Promise<unknown>)) | null = null;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const bindSubmitForm = (form: (() => Promise<void>) & (() => Promise<unknown>)): void => {
        submitForm = form;
    };

    const handleSubmit = (): void => {
        submitForm && submitForm();
    };

    return (
        <style.ShipmentAcceptanceContent data-testid='ShipmentAcceptanceContent'>
            <UiModal
                open={props.open}
                closeModal={props.closeModal}
                maxWidthModal='xl'
                maxHeight='calc(100vh - 90px)'
                title={`${Trans('messages.t.shipment_acceptance')} - ${Trans('messages.t.order_id')} #
                ${props.shippingOrderId}`}
                content={
                    props.loadingForm ? (
                        <Loading />
                    ) : (
                        <Grid container justifyContent='space-around' direction='row'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <EmailAcceptanceFormik
                                    valuesInitForm={props.valuesInitForm}
                                    handleSubmit={handleSubmit}
                                    bindSubmitForm={bindSubmitForm}
                                    setIsSubmitting={setIsSubmitting}
                                    shippingOrder={props.shippingOrder}
                                    confirmSendEmail={props.confirmSendEmail}
                                    sendEmail={props.sendEmail}
                                />
                            </Grid>
                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                {props.shippingOrder.emailSent && (
                                    <Alert severity='info' className='text-center'>
                                        {Trans('messages.t.info')}:{' '}
                                        {Trans('messages.p.an_invitation_via_email_was_already_sent')}
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className='dataHeaderAcceptance'>
                                <Grid lg={3} md={3} sm={6} xs={12} className={classes.textField}>
                                    <h3>{Trans('messages.t.from')}: </h3>
                                    <span className='value_form_shippingorder_details'>
                                        {props.shippingOrder.locationFrom?.name}
                                    </span>
                                </Grid>
                                <Grid lg={3} md={3} sm={6} xs={12} className={classes.textField}>
                                    <h3>{Trans('messages.t.to')}: </h3>
                                    <span className='value_form_shippingorder_details'>
                                        {props.shippingOrder?.locationTo?.name}
                                    </span>
                                </Grid>
                                {props.shippingOrder?.shipper ? (
                                    <>
                                        <Grid lg={3} md={3} sm={6} xs={12} className={classes.textField}>
                                            <h3>{Trans('messages.t.shipper')}: </h3>
                                            <span className='value_form_shippingorder_details'>
                                                {props.shippingOrder?.shipper?.name}
                                            </span>
                                        </Grid>
                                        <Grid lg={3} md={3} sm={6} xs={12} className={classes.textField}>
                                            <h3>{Trans('messages.t.tracking_number')}: </h3>
                                            <span className='value_form_shippingorder_details'>
                                                {props.shippingOrder?.trackingId
                                                    ? props.shippingOrder?.trackingId
                                                    : Trans('messages.t.there_no_data')}
                                            </span>
                                        </Grid>
                                    </>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: '10px' }}>
                                <div className='conttable'>
                                    <UiTable
                                        title='Shipping Acceptance Items List'
                                        data={props.itemsLists}
                                        loading={false}
                                        hidePagination={true}
                                        hideForceRefresh={true}
                                        noPagination={true}
                                        columns={props.columns}
                                        hiddenColumns={[]}
                                        globalFilter={true}
                                        customButtonAdd={
                                            <b>
                                                <span className='fo-3' style={{ fontSize: '1.17em' }}>
                                                    {Trans('messages.t.item_to_send')}{' '}
                                                </span>
                                            </b>
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    )
                }
                buttons={
                    <>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                props.closeModal();
                            }}
                            color='secondary'
                        >
                            {Trans('messages.t.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            type='submit'
                            disabled={isSubmitting}
                            onClick={() => {
                                props.handleSubmitForm();
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={25} /> : Trans('messages.t.save')}
                        </Button>
                    </>
                }
            />
            <style.ShippingOrderAcceptancebody />
        </style.ShipmentAcceptanceContent>
    );
};
