import styled from 'styled-components';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ThemeType } from '../../styled/theme.type';
import Scrollbars from 'react-custom-scrollbars-2';

export const UserStockLocationAccessContent = styled.div``;

export const AppBarContainer = styled(AppBar)`
    background: ${({ theme }: ThemeType) => theme.colors.topBar.background} !important;
`;

export const ContainerBtnBoxItems = styled.div`
    width: 90%;
    text-align: right;
    display: inline-block;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 65%;
    }
}
`;

export const TitlePage = styled(Typography)`
    width: 500px;
`;

export const ButtonSuccess = withStyles(() => ({
    root: {
        color: '#fff',
        padding: '1px 5px !important',
        textTransform: 'capitalize',
        backgroundColor: '#4caf50 !important',
        '&:hover': {
            backgroundColor: '#2f9533 !important'
        }
    }
}))(Button);

export const ButtonGrey = withStyles(() => ({
    root: {
        color: '#fff !important',
        padding: '1px 5px !important',
        textTransform: 'capitalize',
        backgroundColor: '#a1a1a1 !important'
    }
}))(Button);

export const ButtonDanger = withStyles(() => ({
    root: {
        color: '#fff',
        padding: '1px 5px !important',
        textTransform: 'capitalize',
        backgroundColor: '#f02c01 !important',
        '&:hover': {
            backgroundColor: '#d13716 !important'
        }
    }
}))(Button);

export const UiToolbar = styled(Toolbar)`
    min-height: 40px !important;
`;

export const UiScrollbarsUser = styled(Scrollbars)`
    height: auto !important;
    border-bottom: thin solid #c0c0c0;
    max-height: calc(100vh - 150px) !important;
    min-height: 400px !important;

    & > div:first-child {
        min-height: 400px !important;
        max-height: calc(100vh - 150px) !important;
    }
`;
