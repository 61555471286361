import React from 'react';
import Sensor from '../../components/Sensor/Sensor';
import { SensorContainer } from './Sensor.style';
// import module

export const SensorContent: React.FC = (): JSX.Element => (
    <SensorContainer data-testid='SensorContent'>
        <Sensor />
    </SensorContainer>
);
