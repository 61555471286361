import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import {
    ArrowForwardOutlined,
    Delete,
    Edit,
    KeyboardArrowDown,
    KeyboardArrowRight,
    ListAlt
} from '@mui/icons-material';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import * as type from './Box.type';
import * as view from './Box.view';
import { IBoxForm, IItemvalue, ItemsType } from './Box.type';
import ApiBox from '../../api/ApiBox';
import ApiItems from '../../api/ApiItems';
import { ApiStockLocationByIdUser } from '../../api/ApiStockLocation';
import BoxItems from '../BoxItems/BoxItems';
import { Success, Warning } from '../Popup/Popup';
import { Boxes } from '../../states/component/Box';
import { StockLocation, StockLocationRegion } from '../../states/global/StockLocation';
import Translator from '../../states/global/Translator';
import * as UserState from '../../states/global/User';
import ActionsButtonsDataTable from '../ActionsButtonsDataTable/ActionsButtonsDataTable';
import { ColumnsTable, ModalType, ModelSkuCodeBook, UserLocationAccess } from '../../types/common';
import * as SkuModel from '../../models/ApiSku.type';
import { removeSpaces } from '../../helpers/converter';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { TextEllipsis } from '../../styled/global.style';
import { IStockLocation, IStockLocationRegion } from '../../models/ApiStockLocation.type';
import { ItemModel } from '../ShippingOrder/EditShippingOrder/EditShippingOrder.type';
import { ItemsResponse, StockItemsGeneral, StockItemsType } from '../../models/ApiItems.type';
import ShippingOrderDetails from '../ShippingOrder/ShippingOrderDetails/ShippingOrderDetails';
import { ItemsAvailablesFilter } from '../CreateOrder/CreateOrder.type';
import { BoxData } from '../BoxItems/BoxItems.type';
import { popUpConfirm } from '../../helpers/PopUpConfirm';
import { getFullScreenModal } from '../../helpers/getFullScreenModal';
import { FullDataUser } from '../User/User.type';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
// import module

const api = new ApiBox();
const apiItems = new ApiItems();
const apiStockLocationByIdUser = new ApiStockLocationByIdUser();

const Box: React.FC<type.BoxProps> = () => {
    const setdata = useSetRecoilState<StockItemsType[]>(Boxes);
    const Trans = useRecoilValue(Translator);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingItems, setLoadingItems] = useState<boolean>(false);
    const [columnsBox, setColumnsBox] = useState<ColumnsTable[]>([]);
    const [itemsToSend, setItemsToSend] = useState<ItemModel[]>([]);
    const [itemsToSendValidation, setItemsToSendValidation] = useState<ItemModel[]>([]);
    const stockLocationState = useRecoilValue<IStockLocation | null>(StockLocation);
    const stockLocationRegionState = useRecoilValue<IStockLocationRegion | null>(StockLocationRegion);
    const [itemsFrom, setItemsFrom] = useState<ItemModel[]>([]);
    const [itemsFromValue, setItemsFromValue] = useState<IItemvalue[]>([]);
    const userState = useRecoilValue<FullDataUser | null>(UserState.User);
    const [loadingForm, setLoadingFrom] = useState<boolean>(true);
    const [boxId, setBoxId] = useState<number>();
    const [idBox, setIdBox] = useState<number | null>(null);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const getDateMask = useRecoilValue(GetDateMask);
    const [modalItems, setModalItems] = useState<boolean>(false);
    const [showBoxListAction, setShowBoxListAction] = useState<boolean>(false);
    const notData = '---';
    const [shipped, setShipped] = useState<boolean>(false);
    const [shippedFilter, setshippedFilter] = useState<{ shippedFilter: string; locationFilter: string }>({
        shippedFilter: '',
        locationFilter: ''
    });
    const [SKUs, setSKUs] = useState<ModelSkuCodeBook[]>([]);
    const [resetFilter, setResetFilter] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [resetPageIndexTable, setResetPageIndexTable] = useState<boolean>(false);
    const [valuesInitForm, setvaluesInitForm] = useState({ locationSelect: {}, label: '' });
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [openOrderDetails, setOpenOrderDetails] = useState<boolean>(false);
    const [shippingOrderId, setshippingOrderId] = useState<number | string | null>(null);
    const [pageCountItems, setPageCountItems] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const [refreshItems, setRefreshItems] = useState<boolean>(false);
    const [locationFilter, setLocationFilter] = useState<ItemsAvailablesFilter>({
        location: '',
        type: '',
        skuId: '',
        serialNumber: ''
    });

    const [dataModal, setdataModal] = useState<ModalType>({
        title: Trans('messages.t.box_items') + ' ' + Trans('messages.t.box'),
        id: null,
        isAdd: true
    });

    const Formschema = Yup.object({
        locationSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        }),
        label: Yup.string().trim().min(3, 'Label is Too Short.').required(Trans('messages.p.this_field_is_required'))
    });

    const handleChange = (event) => {
        event.target.checked
            ? setshippedFilter({ ...shippedFilter, shippedFilter: 'shipped=1' })
            : setshippedFilter({ ...shippedFilter, shippedFilter: '' });
        setShipped(event.target.checked);
    };

    const ElementFilter = (
        <FormControlLabel
            control={<Switch checked={shipped} onChange={handleChange} name='shipped' color='primary' />}
            label={Trans('messages.t.shipped')}
        />
    );

    const openModal = async (type_form: type.CrudAction = type.CrudAction.CREATE, id: number | null = null) => {
        setLoadingFrom(true);
        setdataModal({
            isAdd: type_form !== type.CrudAction.EDIT,
            title:
                type_form !== type.CrudAction.EDIT
                    ? Trans('messages.t.add_new') + ' ' + Trans('messages.t.box')
                    : Trans('messages.t.edit') + ' ' + Trans('messages.t.box') + ' - #' + id,
            id: type_form !== type.CrudAction.EDIT ? null : id
        });

        if (type_form !== type.CrudAction.EDIT) {
            setvaluesInitForm({ locationSelect: {}, label: '' });
            setLoadingFrom(false);
        }
        setTimeout(() => setOpen(true), 100);
        type_form !== type.CrudAction.EDIT && setLoadingFrom(false);
    };

    const handleClose = (): void => {
        setLoading(true);
        setvaluesInitForm({ locationSelect: {}, label: '' });
        setItemsFrom([]);
        setItemsToSend([]);
        setItemsToSendValidation([]);
        setItemsFromValue([]);
        setOpenOrderDetails(false);
        setLoadingFrom(false);
        setSKUs([]);
        setSerialNumber('');
        setOpen(false);
        setLocationFilter({ location: '', type: '', skuId: '', serialNumber: '' });
        setTimeout(() => {
            setLoading(false);
            setLoadingFrom(false);
        }, 500);
    };

    async function getData(page, limit, order, filter, extraFiltering) {
        let filterByLocation = '';

        if (extraFiltering.locationFilter) {
            filterByLocation = `?${extraFiltering.locationFilter}`;
        }

        if (extraFiltering.shippedFilter) {
            if (filterByLocation) {
                filterByLocation = `${filterByLocation}&${extraFiltering.shippedFilter}`;
            } else {
                filterByLocation = `${filterByLocation}?${extraFiltering.shippedFilter}`;
            }
        }

        return await apiItems.getAllBox(`/box${filterByLocation}`, page, limit, order, filter);
    }

    const getAllBox = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator }: ItemsResponse = await getData(page, limit, order, filter, extraFiltering);

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
            setRefresh(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: StockItemsType[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator }: ItemsResponse = await getData(i, 100, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const getItemsAvailables = useCallback(
        async (page, limit, order, filter, extraFiltering, boxId): Promise<void | boolean> => {
            setItemsFrom([]);
            setItemsFromValue([]);
            setLoadingItems(true);

            let pTypeTablet = '';
            let filterBySerialNumber = '';
            let paramTypeFilter = '';
            let paramTypeItemFilter = '';

            if (!extraFiltering.location) {
                setResetFilter(false);
                setLoadingItems(false);
                return true;
            }

            if (extraFiltering.type && extraFiltering.type !== 'all') {
                paramTypeFilter = `&type=${extraFiltering.type}`;
                paramTypeItemFilter = extraFiltering.type;
            }

            if (extraFiltering.skuId) pTypeTablet = `&skuId=${extraFiltering.skuId}`;

            if (extraFiltering.serialNumber) {
                filterBySerialNumber = `&serialNumber=${extraFiltering.serialNumber}`;
            }

            if (extraFiltering.location) {
                let notBoxId = '';
                if (boxId) {
                    notBoxId = '&notId=' + boxId;
                }

                const baseUrl = '?available=1&inBox=0&locationId=';
                const { items, paginator } = await apiItems.getAll(
                    `${baseUrl}${extraFiltering.location}${notBoxId}${paramTypeFilter}${pTypeTablet}${filterBySerialNumber}`,
                    page,
                    limit,
                    order,
                    filter,
                    '/v2'
                );

                if (paramTypeItemFilter) {
                    if (paramTypeItemFilter === 'all') {
                        paramTypeItemFilter = '';
                    } else {
                        paramTypeItemFilter = `/${paramTypeItemFilter}`;
                    }
                    if (paramTypeItemFilter) {
                        const {
                            data: { codeBook }
                        }: SkuModel.ApiModelSkuCodeBook = await api.getGeneral(
                            `stock/sku/type${paramTypeItemFilter}/assigned`,
                            undefined,
                            'v2'
                        );

                        if (codeBook) {
                            setSKUs(codeBook);
                        }
                    }
                }

                let newData: ItemModel[] = items.length
                    ? items.map((Item) => {
                          return {
                              id: Item.id,
                              item_type: Item.sku.skuType.type,
                              name: Item.sku.name,
                              itemId: Item.itemId,
                              itrackDeviceId: Item.itrackDeviceId,
                              sku_code: Item.sku.skuCode,
                              sku_id: Item.sku.id,
                              quantity: Item.quantity ? Item.quantity : 1,
                              quantityItem: Item.quantity ? Item.quantity : 1,
                              quantityOrigin: Item.quantity ? Item.quantity : 1,
                              serialNumber: Item.serialNumber
                          };
                      })
                    : [];

                if (!!itemsToSend.length && !!items.length) {
                    newData = newData.map((item) => {
                        itemsToSend.map((ItemTo) => {
                            if (
                                ItemTo.item_type === ItemsType.GENERIC_ITEM &&
                                item.id === ItemTo.id &&
                                ItemTo.isDB === 'no'
                            ) {
                                const exists: ItemModel | undefined = itemsToSendValidation.find(
                                    (item) => item.id === ItemTo.id
                                );

                                if (ItemTo.quantityOrigin !== ItemTo.quantity) {
                                    if (exists) {
                                        item.quantity =
                                            item.quantityOrigin -
                                            (exists.quantityOrigin > ItemTo.quantity
                                                ? exists.quantityOrigin - ItemTo.quantity
                                                : ItemTo.quantity - exists.quantityOrigin);
                                        item.quantityItem =
                                            item.quantityOrigin -
                                            (exists.quantityOrigin > ItemTo.quantity
                                                ? exists.quantityOrigin - ItemTo.quantity
                                                : ItemTo.quantity - exists.quantityOrigin);
                                    } else {
                                        item.quantity = item.quantityOrigin - ItemTo.quantity;
                                        item.quantityItem = item.quantityOrigin - ItemTo.quantity;
                                    }
                                } else {
                                    if (exists) {
                                        if (exists.quantityOrigin !== exists.quantity) {
                                            item.quantity = item.quantityOrigin - ItemTo.quantity;
                                            item.quantityItem = item.quantityOrigin - ItemTo.quantity;
                                        }
                                    } else {
                                        item.quantity = item.quantityOrigin - ItemTo.quantity;
                                        item.quantityItem = item.quantityOrigin - ItemTo.quantity;
                                    }
                                }
                            }
                        });
                        return item;
                    });
                }

                const newDatas: ItemModel[] = newData.length
                    ? newData.filter((addI) => {
                          if (addI.type === ItemsType.GENERIC_ITEM) {
                              return addI.quantity > 0 ? true : false;
                          } else {
                              return true;
                          }
                      })
                    : [];

                const listItemsFromValue: IItemvalue[] = newData.length
                    ? newData.map((ItemFrom) => ({ id: ItemFrom.id, value: null }))
                    : [];

                itemsFromValue.length
                    ? setItemsFromValue([...itemsFromValue, ...listItemsFromValue])
                    : setItemsFromValue(listItemsFromValue);

                const listItemsFrom: ItemModel[] = newDatas.filter((item) => item.quantity > 0);

                setItemsFrom(listItemsFrom);
                setPageCountItems(paginator.pageCount);
                setTotalItems(paginator.totalCount);
                setRefreshItems(false);
            }
            if (extraFiltering.typeTable) setResetPageIndexTable(true);

            setLoadingItems(false);
            setResetFilter(false);
            setResetPageIndexTable(false);
        },
        [setItemsFrom]
    );

    const getItems = async (original) => {
        setBoxId(original.id);
        setModalItems(true);
        setShowBoxListAction(original.shippingOrderId ? false : true);
    };

    const editQuantity = async (row: ItemModel): Promise<void> => {
        setLoadingFrom(true);
        openModal(type.CrudAction.EDIT, row.id);
        const {
            data: { item }
        }: StockItemsGeneral = await apiItems.getByIdGeneral('stock/item', row.id);

        setIdBox(item.id);

        const newList: ItemModel[] =
            item.children && item.children.length
                ? item.children.map((Item) => {
                      return {
                          id: Item.id,
                          item_type: Item.sku.skuType ? Item.sku.skuType.type : ItemsType.BOX,
                          name: Item.sku.name,
                          itemId: Item.itemId,
                          itrackDeviceId: Item.itrackDeviceId,
                          sku_code: Item.sku.skuCode,
                          sku_id: Item.sku.id,
                          quantity: Item.quantity,
                          quantityOrigin: Item.quantity,
                          isDB: 'yes',
                          serialNumber: `${Item.serialNumber}`
                      };
                  })
                : [];

        !!newList.length && setItemsToSend(newList);
        !!newList.length && setItemsToSendValidation(newList);
        const newDataValue = newList.length ? newList.map((ItemFrom) => ({ id: ItemFrom.id, value: null })) : [];
        setItemsFromValue(newDataValue);
        const checkLabelExists = !!item.itemAttribute.length;
        if (item.location) {
            setLocationFilter({ ...locationFilter, location: item.location.id.toString() });
        }

        setvaluesInitForm({
            locationSelect: item.location ? item.location : {},
            label: checkLabelExists ? item.itemAttribute[0].value : notData
        });
        setLoadingFrom(false);
    };

    const formatSerialNumber = (row: ItemModel): JSX.Element => {
        switch (row.item_type) {
            case ItemsType.GENERIC_ITEM:
                return (
                    <>
                        <span className='capitalize' style={{ marginRight: '4px' }}>
                            {notData}
                        </span>
                    </>
                );
            case ItemsType.BOX:
                return (
                    <>
                        <span>{notData}</span>
                    </>
                );
            case ItemsType.SENSOR:
                return (
                    <>
                        <span className='capitalize' style={{ marginRight: '4px' }}>
                            {row.serialNumber}
                        </span>
                    </>
                );
            case ItemsType.HUB:
                return (
                    <>
                        <span className='capitalize' style={{ marginRight: '4px' }}>
                            {row.serialNumber}
                        </span>
                    </>
                );
            case ItemsType.TABLET:
                return (
                    <>
                        <span className='capitalize' style={{ marginRight: '4px' }}>
                            {row.serialNumber}
                        </span>
                    </>
                );
            default:
                return <>{row.item_type}</>;
        }
    };

    const validateQuantitySku = (e: React.ChangeEvent<HTMLInputElement>, row: ItemModel): void => {
        const quantityinput: HTMLElement | null = document.getElementById('cant_' + row.id);

        if (quantityinput && row.quantityItem && parseInt(e.target.value) > parseInt(`${row.quantityItem}`)) {
            Warning({ text: Trans('messages.p.you_must_enter_an_amount_less_than') + ' ' + row.quantityItem });
            quantityinput['value'] = row.quantityItem;
            quantityinput.focus();
        }
    };

    const setItemsAvailable = (row: ItemModel, isAdd) => {
        let listTemp: ItemModel[] = isAdd ? itemsToSend : itemsFrom;
        const listTempReverse = !isAdd ? itemsToSend : itemsFrom;
        let newList: ItemModel[] = [...listTemp];

        const quantityInput: HTMLElement | null = document.getElementById('cant_' + row.id);
        const quantityInputValue: number = parseInt(quantityInput?.['value']);
        const generitItem: boolean = isAdd && row.item_type === ItemsType.GENERIC_ITEM;
        const isFullListFrom = !!listTemp.length;

        if (generitItem) {
            if (isNaN(quantityInputValue) || !quantityInputValue || quantityInputValue <= 0) {
                Warning({ text: Trans('messages.p.quantity_must_be_greater_than_0') });
                quantityInput?.focus();
                throw quantityInput;
            }

            if (quantityInputValue > parseInt(`${row.quantityItem}`)) {
                Warning({ text: Trans('messages.p.you_must_enter_an_amount_less_than') + ' ' + row.quantityItem });
                quantityInput?.focus();
                throw quantityInput;
            }

            const updateListFrom: type.IItemvalue[] = itemsFromValue.map((itemsFrom) => {
                if (itemsFrom.id === row.id) {
                    itemsFrom.value = null;
                }
                return itemsFrom;
            });
            setItemsFromValue(updateListFrom);
        }

        const exists = !!listTemp.filter((obj) => obj.id === row.id).length;
        const nameItems: string | undefined = row?.name ? row?.name : row.item_type;

        if (isFullListFrom) {
            const rowIsGenericItem: boolean =
                row.item_type === ItemsType.GENERIC_ITEM && !!listTemp.filter((key) => key.id === row.id).length;
            if (rowIsGenericItem) {
                if (isAdd) {
                    listTemp = listTemp.map((key) => {
                        if (key.id === row.id) {
                            key.quantity = key.quantity + quantityInputValue;
                        }
                        key.isDB = 'no';
                        return key;
                    });
                    row.isDB = 'no';
                    row.item_type === ItemsType.GENERIC_ITEM
                        ? (newList = [...listTemp])
                        : (newList = [...listTemp, row]);
                } else {
                    if (row.item_type === ItemsType.GENERIC_ITEM) {
                        newList = newList.map((key) => {
                            if (key.id === row.id && key.quantityItem) {
                                key.quantity = key.quantityItem + row.quantity;
                                key.quantityItem = row.quantityItem
                                    ? key.quantity === key.quantityItem
                                        ? row.quantity
                                        : key.quantity
                                    : key.quantityItem + row.quantity;
                            }
                            key.isDB = 'no';
                            return key;
                        });
                    } else {
                        newList = [row, ...newList];
                    }
                }
            } else {
                if (row.item_type === ItemsType.GENERIC_ITEM) {
                    if (isAdd) {
                        row.isDB = 'no';
                        row.quantity = quantityInputValue;
                    } else {
                        row.quantityItem = row.quantity;
                    }
                }

                if (isAdd) {
                    newList = [...listTemp, row];
                    if (exists) {
                        Warning({ text: nameItems + ' ' + Trans('messages.p.is_already_added') });
                        return false;
                    }
                } else {
                    newList = [row, ...listTemp];
                }
            }
        } else {
            if (row.item_type === ItemsType.GENERIC_ITEM) {
                row.isDB = 'no';
                if (isAdd) {
                    row.quantityItem = row.quantity;
                    row.quantity = quantityInputValue;
                } else {
                    row.quantityItem = row.quantity === 0 ? quantityInputValue : row.quantity;
                }
            }
            if (exists) {
                Warning({ text: nameItems + ' ' + Trans('messages.p.is_already_added') });
                return false;
            }
            newList = [row];
        }

        let newListItemsFilter: ItemModel[] = [];
        if (generitItem) {
            newListItemsFilter = listTempReverse.map((key) => {
                if (key.id === row.id && key.quantity > 0) {
                    if (key.quantityItem) {
                        key.quantityItem = key.quantityItem - quantityInputValue;
                    }
                }
                return key;
            });
            newListItemsFilter = newListItemsFilter.filter((obj) => obj.quantityItem !== 0);
        } else {
            newListItemsFilter = listTempReverse.filter((key) => key.id !== row.id);
        }
        setItemsFrom(!isAdd ? newList : newListItemsFilter);
        setItemsToSend(isAdd ? newList : newListItemsFilter);
    };

    const detailsShippingOrder = (shippingOrderId): void => {
        setshippingOrderId(shippingOrderId);
        setOpenOrderDetails(true);
    };

    const ActionsButtons = (original: ItemModel): JSX.Element => {
        return (
            <>
                <Typography className='dataTableActionsBtn' onClick={() => getItems(original)}>
                    <ListAlt />
                    <span>{Trans('messages.t.box_items')}</span>
                </Typography>

                {original.shippingOrderId ? (
                    <Typography
                        className='dataTableActionsBtn'
                        onClick={() => detailsShippingOrder(original.shippingOrderId)}
                    >
                        <ListAlt />
                        <span>{Trans('messages.t.view_order')}</span>
                    </Typography>
                ) : (
                    <>
                        <Typography className='dataTableActionsBtn' onClick={() => editQuantity(original)}>
                            <Edit />
                            <span>{Trans('messages.t.edit')}</span>
                        </Typography>
                        <Typography className='dataTableActionsBtn danger' onClick={() => confirmDeleteRow(original)}>
                            <Delete />
                            <span>{Trans('messages.t.remove')}</span>
                        </Typography>
                    </>
                )}
            </>
        );
    };

    const getColumns = async (): Promise<void> => {
        const { items }: UserLocationAccess = await apiStockLocationByIdUser.getById(
            userState ? parseInt(`${userState.id}`) : 0
        );

        const columns: ColumnsTable[] = [
            {
                Header: () => null,
                disableFilters: true,
                accessor: 'expander',
                disableGroupBy: true,
                width: 40,
                canFilters: false,
                Cell: ({ row, isLoading }: CellProps): CellProps<JSX.Element> => {
                    const toggleRowExpandedProps = row.getToggleRowExpandedProps();

                    const onClick = async (event) => {
                        if (!isLoading) {
                            toggleRowExpandedProps.onClick(event);
                        }
                    };

                    return (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    paddingLeft: `${row.depth}rem`
                                }
                            })}
                            onClick={onClick}
                        >
                            {row.isExpanded ? (
                                <IconButton
                                    size='small'
                                    style={{ minWidth: 'auto', color: '#039be5' }}
                                    title={Trans('messages.t.box_items')}
                                    id={`btn_view_${row.original.id}`}
                                    color='primary'
                                >
                                    <KeyboardArrowDown />
                                </IconButton>
                            ) : (
                                <IconButton
                                    size='small'
                                    style={{ minWidth: 'auto', color: '#039be5' }}
                                    title={Trans('messages.t.box_items')}
                                    id={`btn_view_${row.original.id}`}
                                    color='primary'
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            )}
                        </span>
                    );
                }
            },
            {
                Header: 'ID',
                accessor: 'id',
                disableGroupBy: true,
                width: 50,
                aggregate: 'count',
                canFilters: true
            },
            {
                Header: Trans('messages.t.label'),
                accessor: 'label',
                disableGroupBy: true,
                disableSortBy: true,
                aggregate: 'count',
                canFilters: false,
                Export: ({ row }: CellProps) =>
                    row.original.itemAttribute && !!row.original?.itemAttribute.length
                        ? row.original.itemAttribute[0].value
                        : '',
                Cell: ({ row }: CellProps<string>) =>
                    row.original.itemAttribute && !!row.original?.itemAttribute.length
                        ? row.original.itemAttribute[0].value
                        : notData
            },
            {
                Header: Trans('messages.t.items'),
                accessor: 'children',
                disableGroupBy: true,
                aggregate: 'count',
                disableFilters: true,
                disableSortBy: true,
                Export: ({ row }: CellProps) =>
                    row.original.children
                        ? row.original.children.reduce((a, child) => (child.quantity || 1) + a, 0)
                        : 0,
                Cell: ({ row }: CellProps<string>) =>
                    row.original.children ? row.original.children.reduce((a, child) => (child.quantity || 1) + a, 0) : 0
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                disableGroupBy: true,
                aggregate: 'count',
                width: 0,
                accessor: 'createdAtFrom'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                width: 0,
                disableGroupBy: true,
                aggregate: 'count',
                accessor: 'createdAtTo'
            },
            {
                Header: Trans('messages.t.created_at'),
                accessor: 'createdAt',
                disableGroupBy: true,
                aggregate: 'count',
                Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.createdAt || '', false),
                Filter: ({ setFilter, state }: CellProps) => {
                    return (
                        <>
                            <DatePickerFilterColumn
                                state={state}
                                setFilter={setFilter}
                                label={Trans('messages.t.created_at_from')}
                                filterName='createdAtFrom'
                                getDateMask={getDateMask}
                                fromTimezoneToUTC={fromTimezoneToUTC}
                            />
                            <DatePickerFilterColumn
                                state={state}
                                setFilter={setFilter}
                                label={Trans('messages.t.created_at_to')}
                                filterName='createdAtTo'
                                getDateMask={getDateMask}
                                fromTimezoneToUTC={fromTimezoneToUTC}
                            />
                        </>
                    );
                }
            },
            {
                Header: Trans('messages.t.actions'),
                accessor: 'action',
                disableGroupBy: true,
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<JSX.Element>) => (
                    <ActionsButtonsDataTable actionsButtons={ActionsButtons(original)} />
                )
            }
        ];

        if (items && items.length) {
            columns.splice(2, 0, {
                Header: Trans('messages.t.stock_location'),
                accessor: 'location.name',
                disableGroupBy: true,
                aggregate: 'count',
                canFilters: true,
                Export: ({ row }: CellProps) => row.original.location.name
            });
        }

        setColumnsBox(columns);
    };

    const handleSubmit = async (
        e: IBoxForm,
        resetForm: (e: Record<string, unknown>) => void,
        setSubmitting: (submitting: boolean) => void
    ) => {
        const newDataToSend: IBoxForm | Record<string, unknown> = {};

        if (!itemsToSend.length) {
            setSubmitting(false);
            return Warning({ text: Trans('messages.p.the_list_items_to_send_cant_be_empty') });
        }
        newDataToSend.items = itemsToSend.map((i) => ({ id: i.id, quantity: i.quantity }));
        newDataToSend.sku = 30;
        newDataToSend.location = e.locationSelect.id;
        newDataToSend.attributes = [{ box_label: removeSpaces(e.label) }];

        if (e.quantity === '') e.quantity = '1';

        delete e.stock_location_id;
        delete e.sku_id;

        try {
            const { data }: BoxData = dataModal.isAdd
                ? await api.createGeneral('stock/item/box', newDataToSend)
                : await api.patchGeneral('stock/item/box', dataModal?.id ?? 0, newDataToSend);

            if (!data) {
                throw data;
            }
            setRefresh(true);

            Success({
                text: dataModal.isAdd
                    ? `${Trans('messages.t.stock_created_successfully')} [ID ${data.item.id}]`
                    : `${Trans('messages.t.stock_successfully_updated')} [ID ${idBox}]`
            });

            handleClose();
            resetForm({});
            setSubmitting(false);
        } catch (error) {
            console.warn('Error to sending data ', error);
            setSubmitting(false);
        }
    };

    const deleteRow = async (onClose, row: ItemModel): Promise<void> => {
        const { data }: BoxData = await api.createGeneral(`stock/item/box/${row.id}/dispose`, {});
        if (data && data.item)
            Success({ text: `${Trans('messages.t.you_have_successfully_disposed_box')} [ID ${row.id}]` });

        setRefresh(true);
        onClose();
    };

    const confirmDeleteRow = (row: ItemModel): void =>
        popUpConfirm(
            Trans('messages.t.box') + ` #${row.id}`,
            Trans('messages.p.are_you_sure_to_dispose_box'),
            deleteRow,
            row
        );

    const setValueFrom = (row: ItemModel, resetValue = false) => {
        const quantityinput: HTMLElement | null = document.getElementById('cant_' + row.id);

        itemsFromValue.map((itemsFrom) => {
            if (quantityinput && itemsFrom.id === row.id) itemsFrom.value = resetValue ? null : quantityinput['value'];
            return itemsFrom;
        });
    };

    const currentValueFrom = (row: ItemModel): number | string | undefined => {
        const valueItems: type.IItemvalue | undefined | { value: null } = itemsFromValue
            ? itemsFromValue.find((item) => item.id === row.id)
            : { value: null };

        return valueItems && valueItems.value ? valueItems.value : undefined;
    };

    const elementSRowEdit = (row: ItemModel): JSX.Element => {
        if (row.item_type === ItemsType.GENERIC_ITEM) {
            return (
                <>
                    <input
                        type='number'
                        style={{ marginLeft: '3px', width: '40%' }}
                        max={row.quantityItem}
                        min={1}
                        title={Trans('messages.p.the_quantity_is_indicated_in_the_SKU_type_item')}
                        disabled={row.item_type != ItemsType.GENERIC_ITEM}
                        key={`quantity_${row.id}`}
                        id={`cant_${row.id}`}
                        defaultValue={currentValueFrom(row)}
                        onChange={(e) => {
                            validateQuantitySku(e, row);
                            setValueFrom(row);
                        }}
                    />
                    Max: {row.quantityItem}
                </>
            );
        } else {
            return <>{notData}</>;
        }
    };

    const ElementSRowEditMemo = React.memo<{ row: ItemModel }>(({ row }) => elementSRowEdit(row));
    ElementSRowEditMemo.displayName = 'ElementSRowEditMemo';

    const columnsAvailableItems: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40
        },
        {
            Header: Trans('messages.t.item'),
            accessor: 'name',
            disableGroupBy: true,
            aggregate: 'count',
            minWidth: 80,
            canFilters: true,
            Cell: ({ row: { original } }: CellProps) => (
                <Tooltip title={original.name} aria-label={original.name}>
                    <TextEllipsis>
                        {original.name}{' '}
                        {original.item_type == 'hub'
                            ? ' [ID:' + (original.itrackDeviceId || original.itemId) + ']'
                            : ''}
                    </TextEllipsis>
                </Tooltip>
            )
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            width: 70,
            minWidth: 70,
            canFilters: true,
            Cell: ({ row: { original } }: CellProps) => formatSerialNumber(original)
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            width: 80,
            minWidth: 80,
            canFilters: true,
            Cell: ({ row }: CellProps) => <ElementSRowEditMemo row={row.original} />
        },
        {
            Header: Trans('messages.t.actions'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'action',
            width: 50,
            minWidth: 50,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <IconButton
                    size='small'
                    className='success'
                    style={{ minWidth: 'auto' }}
                    title={Trans('messages.t.add')}
                    id={`btn_edit_${row.original.id}`}
                    onClick={() => setItemsAvailable(row.original, true)}
                >
                    <ArrowForwardOutlined />
                </IconButton>
            )
        }
    ];

    const columnsItemsToSend: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 40
        },
        {
            Header: Trans('messages.t.item'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'item_type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            Cell: ({ row: { original } }: CellProps) => (
                <Tooltip title={original.name} aria-label={original.name}>
                    <TextEllipsis>
                        {original.name}{' '}
                        {original.item_type == 'hub'
                            ? ' [ID:' + (original.itrackDeviceId || original.itemId) + ']'
                            : ''}
                    </TextEllipsis>
                </Tooltip>
            )
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 70,
            minWidth: 70,
            Cell: ({ row: { original } }: CellProps) => formatSerialNumber(original)
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50,
            minWidth: 50,
            Cell: ({ row }: CellProps) =>
                row.original.item_type === ItemsType.GENERIC_ITEM ? row.original.quantity : notData
        },
        {
            Header: Trans('messages.t.actions'),
            nameLabelFilter: Trans('messages.t.item'),
            accessor: 'action',
            width: 47,
            minWidth: 47,
            disableGroupBy: true,
            disableSortBy: true,
            canFilters: false,
            Cell: ({ row }: CellProps) => (
                <IconButton
                    size='small'
                    className='danger'
                    style={{ minWidth: 'auto' }}
                    title={Trans('messages.t.remove')}
                    id={`btn_edit_${row.original.id}`}
                    onClick={() => setItemsAvailable(row.original, false)}
                    color='secondary'
                >
                    <Delete />
                </IconButton>
            )
        }
    ];

    useEffect(() => {
        if (stockLocationState && !!Object.keys(stockLocationState).length) {
            setshippedFilter({ ...shippedFilter, locationFilter: `locationName=${stockLocationState.name}` });
        } else if (stockLocationRegionState && !!Object.keys(stockLocationRegionState).length) {
            setshippedFilter({ ...shippedFilter, locationFilter: `regionId=${stockLocationRegionState.id}` });
        } else {
            setshippedFilter({ ...shippedFilter, locationFilter: '' });
        }
    }, [stockLocationState, stockLocationRegionState]);

    useEffect(() => {
        getColumns();
    }, []);

    return (
        <>
            <BoxItems
                BoxId={boxId}
                modalItems={modalItems}
                setModalItems={setModalItems}
                setBoxId={setBoxId}
                typeModal='drawer'
                showAction={showBoxListAction}
            />
            <view.BoxContent
                data-testid='Box-testid'
                openModal={openModal}
                open={open}
                isLoading={loading}
                closeModal={handleClose}
                handleSubmit={handleSubmit}
                columns={columnsBox}
                dataModal={dataModal}
                fullScreenModal={getFullScreenModal('sm')}
                loadingItems={loadingItems}
                formschema={Formschema}
                columnsAvailableItems={columnsAvailableItems}
                itemsFrom={itemsFrom}
                columnsItemsToSend={columnsItemsToSend}
                valuesInitForm={valuesInitForm}
                itemsToSend={itemsToSend}
                loadingForm={loadingForm}
                elementFilter={ElementFilter}
                SKUs={SKUs}
                resetFilter={resetFilter}
                fetchData={getAllBox}
                fetchDataExport={getForExport}
                total={total}
                pageCount={pageCount}
                refresh={refresh}
                setResetFilter={setResetFilter}
                resetPageIndexTable={resetPageIndexTable}
                setItemsToSend={setItemsToSend}
                extraFiltering={shippedFilter}
                serialNumber={serialNumber}
                setSerialNumber={setSerialNumber}
                setResetPageIndexTable={setResetPageIndexTable}
                fetchDataItems={getItemsAvailables}
                totalItems={totalItems}
                pageCountItems={pageCountItems}
                refreshItems={refreshItems}
                extraFilteringItems={locationFilter}
                setLocationFilter={setLocationFilter}
            />

            {openOrderDetails && (
                <ShippingOrderDetails
                    open={openOrderDetails}
                    closeModal={handleClose}
                    shippingOrderId={shippingOrderId}
                />
            )}
        </>
    );
};

export default Box;
