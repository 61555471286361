import PortalApi from '../helpers/api/portalApi';
import { Model } from '../models/Account.type';

export default class Account extends PortalApi<Model> {
    route = 'stock/account';

    getAccountParsed = async () => {
        return this.getTemporal<{ user: Model }, { user: Model }>().then((res) => {
            const parsedResponse = parseDateTime(res);
            return parsedResponse.user;
        });
    };
}

export const parseDateTime = (res: { user: Model }) => {
    res.user.userSetting.dateFormat = res.user.userSetting.dateFormat
        .replace(/Y/, 'YYYY')
        .replace(/y/, 'yyyy')
        .replace(/m/, 'MM')
        .replace(/d/, 'DD');
    res.user.userSetting.timeFormat = res.user.userSetting.timeFormat
        .replace(/H/, 'HH')
        .replace(/h/, 'hh')
        .replace(/i/, 'mm');
    res.user.userSetting.timezone =
        res.user.userSetting.useMineTimezone && res.user.customer?.timezone
            ? res.user.customer.timezone
            : res.user.userSetting.timezone;
    return res;
};
