import React, { useState, useEffect } from 'react';
import * as style from './CreateOrder.style';
import * as view from './CreateOrder.view';
import * as type from './CreateOrder.type';
import Wrapper from '../../helpers/wrapper';
// import module

const CreateOrder: React.FC<type.CreateOrderProps> = ({}) => {
    return <view.CreateOrderContent data-testid={'CreateOrder-testid'} />;
};

export default Wrapper(CreateOrder);
