import React from 'react';
import { RolesContent } from './Roles.view';
import { RolesProps } from './Roles.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Roles: React.FC<RolesProps> = () => {
    return <RolesContent data-testid='Roles-testid' />;
};

export default Wrapper(Roles);
