import { atom } from 'recoil';

export const ShippingOrders = atom({
    key: 'shippingOrders-atom',
    default: []
});

export const ShippingOrderFiles = atom({
    key: 'shippingOrdersFiles-atom',
    default: []
});

export const StatusHistory = atom({
    key: 'statusHistory-atom',
    default: []
});

export const StatusFiltersTable = atom({
    key: 'statusFiltersTable-atom',
    default: false
});
