import React from 'react';
import Device from '../../components/Device/Device';
import { DeviceContainer } from './Device.style';
// import module

export const DeviceContent: React.FC = (): JSX.Element => (
    <DeviceContainer data-testid='DeviceContent'>
        <Device />
    </DeviceContainer>
);
