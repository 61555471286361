import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { ModelItemsLocation } from '../models/ApiItems.type';

export default class ApiSensor extends PortalApi<ModelItemsLocation> {
    route = 'stock/item';

    getAll = async (route, page, limit, order, filter): Promise<any> => {
        return await this.getGeneralTable<any>(
            route,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };
}
