import React from 'react';
import { removeSpaces } from '../../../../../helpers/converter';
import * as style from './ColumnFilter.style';
import * as type from './ColumnFilter.type';

const ColumnFilterContent: React.FC<type.ColumnFilterProps> = ({ column }): JSX.Element => {
    const validateValue = (value) => {
        if (!removeSpaces(value)) {
            column.setFilter(undefined);
        }
    };

    return (
        <style.ColumnFilterContent data-testid='ColumnFilterContent'>
            {column.id !== 'action' && (
                <style.FilterTextField
                    label={column.Header}
                    value={column.filterValue?.value ?? ''}
                    variant='outlined'
                    size='small'
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        const value: string = e.target.value;
                        if (value.length === 0) {
                            column.setFilter(undefined);
                        } else {
                            validateValue(value);
                            removeSpaces(value) && column.setFilter({ name: column.Header, value: value });
                        }
                    }}
                />
            )}
        </style.ColumnFilterContent>
    );
};

export default ColumnFilterContent;
