import React from 'react';
import { useHistory } from 'react-router-dom';
import * as type from './TopBar.type';
import * as view from './TopBar.view';
import ApiUser from '../../api/ApiUser';
import { getCookie, removeCookie } from '../../helpers/cookie';
import { StructThemeType } from '../../styled/theme.type';
import { useTheme } from '../../styled/useTheme';
import { getFromLocalStorage } from '../../helpers/localStorage';

const api = new ApiUser();

const TopBar: React.FC<type.TopBarProps> = ({ title, setShowMainMenu }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [open, setOpen] = React.useState<boolean>(false);
    const { setMode, themes } = useTheme();
    const history = useHistory();

    const setTeheme = async (e): Promise<void> => {
        setOpen(!open);
        const currentTheme: StructThemeType = e.target.checked ? themes.data.dark : themes.data.light;
        const currentLanguage: string = getCookie('language');

        setMode(currentTheme);

        await api.patchGeneralFree('account/setting', {
            theme: currentTheme.name,
            language: currentLanguage
        });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
    };

    const openPageProfile = () => {
        handleMenuClose();
        history.push('/app/profile');
    };

    const logout = () => {
        removeCookie('authToken', { path: '/' });
        window.localStorage.removeItem('user:Current');
        window.localStorage.removeItem('theme');
        window.location.href = '/';
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>, type: string) => {
        type === 'prof' && setAnchorEl(event.currentTarget);
        type === 'sl' && setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleShowMenu = (): void => setShowMainMenu();

    React.useEffect(() => {
        const preTheme: StructThemeType | undefined = getFromLocalStorage<StructThemeType>('theme');
        if (preTheme && preTheme.name === 'Dark') setOpen(true);
    }, []);

    return (
        <view.TopBarContent
            title={title}
            data-testid={'TopBar-testid'}
            handleProfileMenuOpen={handleProfileMenuOpen}
            handleMenuClose={handleMenuClose}
            logout={logout}
            isMenuOpen={isMenuOpen}
            mobileMoreAnchorEl={mobileMoreAnchorEl}
            anchorEl={anchorEl}
            isMobileMenuOpen={isMobileMenuOpen}
            handleShowMenu={handleShowMenu}
            openPageProfile={openPageProfile}
            setTeheme={setTeheme}
            open={open}
        />
    );
};

export default TopBar;
