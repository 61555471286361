import { Collapse, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as style from './MainMenu.style';
import * as type from './MainMenu.type';
import Translator from '../../states/global/Translator';

export const MainMenuContent: React.FC<type.MainMenuPropsView> = (props): JSX.Element => {
    const classes2 = style.useStyles();
    const Trans = useRecoilValue(Translator);
    const heightScreen = window.screen.availHeight - 180;

    const mainMenu = (drawer = false) => (
        <List className='nav_Bar' component='nav' aria-labelledby='nested-list-subheader'>
            <Paper>
                <div className='logoMainMenu'>
                    <div className='MainLogo'></div>
                </div>
            </Paper>
            <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: heightScreen }}
                renderThumbVertical={({ style, ...props }) => (
                    <div {...props} className='scrollYcustom' style={{ ...style }} />
                )}
            >
                {!!props.menuList.length &&
                    props.menuList.map((item, index) => {
                        return item.subMenu ? (
                            <div key={`${index}_Link`}>
                                <ListItem
                                    key={`${index}_ListItem`}
                                    className='link_general'
                                    button
                                    onClick={() => props.handleOpenManu(item.key)}
                                >
                                    <ListItemIcon className='iconMenuSidebar'>{item.icon}</ListItemIcon>

                                    <ListItemText primary={Trans(`messages.${item.title}`)} />
                                    {item.open ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={item.open} timeout='auto' unmountOnExit>
                                    <List component='div' disablePadding>
                                        {typeof item._children !== 'undefined' &&
                                            item._children.map((subitem, subIndex) => (
                                                <Link
                                                    to={subitem.path}
                                                    key={`${subIndex}_Link`}
                                                    onClick={() => {
                                                        drawer && props.setShowMainMenu();
                                                    }}
                                                    className={`link_general ${
                                                        props.pathname === subitem.path ? 'active_link' : ''
                                                    } 
                                                        `}
                                                >
                                                    <ListItem
                                                        button
                                                        style={{ paddingLeft: '32px' }}
                                                        className='link_general'
                                                    >
                                                        <ListItemIcon className='iconSubMenuSidebar'>
                                                            {subitem.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={Trans(`messages.${subitem.title}`)} />
                                                    </ListItem>
                                                </Link>
                                            ))}
                                    </List>
                                </Collapse>
                            </div>
                        ) : (
                            <Link
                                to={item.path}
                                key={`${index}_Link`}
                                onClick={() => {
                                    drawer && props.setShowMainMenu();
                                }}
                                className={`link_general ${props.pathname === item.path ? 'active_link' : ''} `}
                            >
                                <ListItem button>
                                    <ListItemIcon className='iconMenuSidebar'>{item.icon}</ListItemIcon>
                                    <ListItemText primary={Trans(`messages.${item.title}`)} />
                                </ListItem>
                            </Link>
                        );
                    })}
            </Scrollbars>
        </List>
    );

    const list = () => (
        <div style={{ maxWidth: '220px' }} className={classes2.list} role='presentation'>
            {mainMenu(true)}
        </div>
    );

    return (
        <>
            <Hidden only={['sm', 'xs']}>
                <style.MainMenuContent variant='persistent' anchor='left' open={true} data-testid='MainMenuContent'>
                    {mainMenu()}
                </style.MainMenuContent>
            </Hidden>

            <Drawer anchor={'left'} open={props.showMainMenu} onClose={props.toggleDrawer()}>
                {list()}
            </Drawer>
        </>
    );
};
