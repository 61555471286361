import { MenuItem, Collapse, TextField, Menu } from '@mui/material';
import styled from 'styled-components';
import { ThemeType } from '../../styled/theme.type';

export const ChangeStockLocationContent = styled.div``;
export const IconChangeLocation = styled.div`
    position: absolute;
    margin-left: -27px;

    @media (max-width: 768px) {
        margin-left: -8px;
        display: contents;
    }
`;

export const TitleChangeLocation = styled.div`
    font-size: 12px !important;
`;

export const RegionMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        font-weight: 600;
    }
`;

export const SearchMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        font-weight: 600;
    }
    & .MuiTextField-root {
        width: 100%;
    }
    &.MuiMenuItem-root:hover {
        background: transparent;
    }
`;

export const LocationMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        border-bottom: solid thin
            ${({ theme }: ThemeType) => (theme.name === 'Dark' ? 'rgba(255,255,255,0.2)' : 'rgb(255,255,255)')};
        width: 221px;
        word-break: break-all;
        overflow-wrap: anywhere;
        white-space: normal;
    }
`;

export const LoadingContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`;

export const LoadingSearchContent = styled(LoadingContent)`
    height: 182px;
`;

export const LocationLabelCover = styled.div`
    text-transform: uppercase;
    margin-bottom: 2px;
    margin-right: 5px;
    div {
        font-size: 14px !important;
    }
`;

export const CollapseCover = styled(Collapse)<{ height: number }>`
    & .MuiCollapse-wrapperInner {
        min-height: ${(props) => (props.height ? `${props.height}px !important;` : 'auto;')};
        border-bottom: solid 1px rgba(0, 0, 0, 0.075);
    }
`;

export const LocationMenu = styled(Menu)`
    & .infinite-scroll-component {
        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 3px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(84, 84, 84, 0.5) !important;
            width: 9px !important;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(84, 84, 84, 0.8) !important;
        }
    }
`;

export const SearchTextField = styled(TextField)`
    &.MuiTextField-root {
        && label {
            padding: 0 5px;
            background: ${({ theme }: ThemeType) => (theme.name === 'Dark' ? '#32383f' : '#fff')};
        }
        & .MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.9);
        }
    }
`;
