import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { ViewColumn } from '@mui/icons-material';
import React from 'react';
import * as style from './HideColumns.style';
import * as type from './HideColumns.type';
import Legend from '../../../UiLegend';

const HideColumnsContent: React.FC<type.HideColumnsProps> = (props): JSX.Element => {
    const hideableColumns = props.allColumns.filter((column) => column.id !== 'selection');
    const checkedCount = hideableColumns.reduce((acc, val) => acc + (val.isVisible ? 0 : 1), 0);
    const onlyOneOptionLeft: boolean = checkedCount + 1 >= hideableColumns.length;

    return (
        <style.HideColumnsContent data-testid='HideColumnsContent'>
            <Legend
                root={
                    <IconButton>
                        <ViewColumn />
                    </IconButton>
                }
                icon={<ViewColumn />}
                width={400}
            >
                <style.HideColumnCover>
                    {hideableColumns
                        .filter((column) => column.Header.length > 0)
                        .map((column) => (
                            <FormControlLabel
                                key={column.id}
                                control={
                                    <Checkbox
                                        value={`${column.id}`}
                                        disabled={column.isVisible && onlyOneOptionLeft}
                                        color='primary'
                                    />
                                }
                                label={column.Header}
                                checked={column.isVisible}
                                onChange={() => props.toggleHideColumn(column.id, column.isVisible)}
                            />
                        ))}
                </style.HideColumnCover>
            </Legend>
        </style.HideColumnsContent>
    );
};

export default HideColumnsContent;
