import { AppBar, Theme, Toolbar, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ThemeType } from '../../styled/theme.type';
import styled, { createGlobalStyle } from 'styled-components';

export const TopBar = styled(AppBar)`
    &.MuiAppBar-colorPrimary {
        background: ${({ theme }: ThemeType) => theme.colors.topBar.background} !important;
        width: calc(100% - 220px);

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    .MuiAppBar-colorPrimary {
        background: ${({ theme }: ThemeType) => theme.colors.topBar.background} !important;
        height: 55px;
    }
`;

export const CustomMenu = createGlobalStyle`
    .MuiPopover-paper{
        min-width: 230px !important;
    } 
`;

export const IUToolbar = styled(Toolbar)`
    @media (max-width: 600px) {
        .css-1t29gy6-MuiToolbar-root {
            min-height: 56px !important;
        }
    }
    min-height: 56px !important;
`;

export const Bar = styled(Toolbar)``;
export const Title = styled(Typography)``;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            }
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);
