import { Theme, ThemeProvider as ThemeMUI, createTheme } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { StructThemeType } from '../../styled/theme.type';
import * as style from './MainApp.style';
import { getCookie } from '../../helpers/cookie';
import Wrapper from '../../helpers/wrapper';
import Login from '../../pages/Login/Login';
import ShipmentAcceptanceDone from '../../pages/ShipmentAcceptanceDone/ShipmentAcceptanceDone';
import ShipmentAcceptancePublic from '../../pages/ShipmentAcceptancePublic/ShipmentAcceptancePublic';
import * as Settings from '../../states/global/Settings';
import { GlobalStyle } from '../../styled/global.style';
import ErrorPage404 from '../ErrorPage404/ErrorPage404';
import Layout from '../Layout/Layout';
import PopupContainer from '../Popup/PopupContainer';
import Unauthorized from '../Unauthorized/Unauthorized';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import moment from 'moment';
import { FullDataUser } from '../User/User.type';
import { User } from '../../states/global/User';
import UiTheme from '../Ui/UiTheme';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const App: React.FC = () => {
    const loggedIn = getCookie('authToken');

    const userState = useRecoilValue<FullDataUser | null>(User);
    const myTheme = useRecoilValue<StructThemeType>(Settings.Theme);

    moment.tz.setDefault(userState ? userState?.userSetting.timezone.timezoneName : 'Europe/Bratislava');

    const themeUI = createTheme({
        ...myTheme,
        ...UiTheme,
        palette: {
            primary: {
                main: myTheme.colors ? myTheme.colors.button.background : '#212121'
            },
            secondary: {
                main: '#e0e0e0'
            },
            mode: myTheme.name === 'Dark' ? 'dark' : 'light',
            background: {
                paper: myTheme.name === 'Dark' ? '#161c24' : '#ffffff'
            }
        }
    });

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <ThemeProvider theme={myTheme}>
                        <ThemeMUI theme={{ ...themeUI, ...myTheme }}>
                            <GlobalStyle />
                            <Route exact path='/'>
                                {loggedIn ? <Redirect to='/app/current-order/inbound' /> : <Redirect to='/login' />}
                            </Route>
                            <Route exact path='/shipment-acceptance' />
                            <Route
                                path='/app'
                                key='layout'
                                render={(props: RouteComponentProps) =>
                                    loggedIn ? <Layout {...props} /> : <Redirect to='/login' />
                                }
                            />
                            <Route
                                path='/login'
                                key='login'
                                exact
                                render={(props: RouteComponentProps) =>
                                    loggedIn ? <Redirect to='/app/current-order/inbound' /> : <Login {...props} />
                                }
                            />
                            <Route
                                path='/shipment-acceptance/:id_order/:hash'
                                key='shipment-acceptance'
                                render={(props: RouteComponentProps) => <ShipmentAcceptancePublic {...props} />}
                            />
                            <Route
                                path='/shipment-acceptance/done'
                                key='shipment-acceptance-done'
                                render={() => <ShipmentAcceptanceDone />}
                            />
                            <Route
                                path='/error-page404'
                                key='error-page404'
                                exact
                                render={(props: RouteComponentProps) => <ErrorPage404 {...props} />}
                            />
                            <Route
                                path='/unauthorized'
                                key='unauthorized'
                                exact
                                render={(props: RouteComponentProps) => <Unauthorized {...props} />}
                            />
                            <Route
                                path='/reset-password/:hash'
                                key='reset-password'
                                exact
                                render={(props: RouteComponentProps) => <ResetPassword {...props} />}
                            />
                        </ThemeMUI>
                    </ThemeProvider>
                </Switch>
            </BrowserRouter>
            <PopupContainer />
            <style.MainStyled />
        </>
    );
};

export default Wrapper(App);
