import PortalApi from '../helpers/api/portalApi';
import * as type from '../models/Translator.type';

const randomNumber = (max: number, min: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export default class Translator extends PortalApi<type.Model> {
    constructor(language: string) {
        super({ 'Accept-Language': language });
        this.language = language;
    }

    language = '';

    //The randon number will ensure to load new translations in case Release ID is not present
    releaseID = process.env.REACT_APP_RELEASE_ID || randomNumber(10000, 5000).toString();

    route = `public/translation?v=${this.releaseID}${this.language}`;
}
