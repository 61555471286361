import React from 'react';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import StockLocationDropdown from '../StockLocationDropdown/StockLocationDropdown';
import SkusDropDown from '../SkusDropDown/SkusDropDown';
import * as Yup from 'yup';
import { IUAlert } from '../../styled/global.style';
import { ISignUpForm, ItemWithSerialNumberFormik as ItemWithSerialNumberFormikType } from './ItemWithSerialNumber.type';
import { InputElement } from '../FormElements';

export const ItemWithSerialNumberFormik: React.FC<ItemWithSerialNumberFormikType> = (props): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    const Formschema = Yup.object({
        serialNumber: Yup.string()
            .trim()
            .min(3, 'Serial Number is Too Short.')
            .required(Trans('messages.p.this_field_is_required')),
        locationSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        }),
        skuSelect: Yup.object().shape({
            id: Yup.string().required('id required'),
            name: Yup.string().required('Name required')
        })
    });

    return (
        <Formik
            initialValues={props.valuesInitForm}
            onSubmit={(values: ISignUpForm, actions) => {
                props.handleSubmit(values, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={Formschema}
        >
            {(propsF: FormikProps<ISignUpForm>) => {
                const { touched, errors, setFieldValue, handleChange, handleBlur, values, isSubmitting, submitForm } =
                    propsF;
                props.bindSubmitForm(submitForm);
                props.setIsSubmitting(isSubmitting);
                return (
                    <Form>
                        <div className='containerFormik'>
                            {Object.keys(errors).length > 0 ? (
                                <IUAlert variant='outlined' severity='warning'>
                                    {Trans('messages.p.all_fields_marked_are_required')}
                                </IUAlert>
                            ) : (
                                ''
                            )}
                            <Grid container justifyContent='initial' direction='row'>
                                <SkusDropDown
                                    name='skuSelect'
                                    url=''
                                    paramsUrl={[
                                        { id: 'type', value: 'active' },
                                        { id: 'withSerialNumber', value: '1' }
                                    ]}
                                    label={Trans('messages.t.sku')}
                                    value={props.dataModal?.isAdd ? values.skuSelect[0] : values.skuSelect}
                                    error={!!errors.skuSelect}
                                    onChange={(value) => {
                                        setFieldValue('skuSelect', value ? value : '');
                                    }}
                                    required={true}
                                    textRequired={Trans('messages.p.this_field_is_required')}
                                />

                                <InputElement
                                    label={Trans('messages.t.serial_number')}
                                    name='serialNumber'
                                    value={values.serialNumber}
                                    error={errors.serialNumber}
                                    touched={touched.serialNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={!props.dataModal?.isAdd}
                                    required={true}
                                />

                                <StockLocationDropdown
                                    name='locationSelect'
                                    label={Trans('messages.t.stock_location')}
                                    value={props.dataModal?.isAdd ? values.locationSelect[0] : values.locationSelect}
                                    error={!!errors.locationSelect}
                                    onChange={(value) => {
                                        setFieldValue('locationSelect', value ? value : '');
                                    }}
                                    required={true}
                                    textRequired={Trans('messages.p.this_field_is_required')}
                                />
                            </Grid>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
